.form-page {
  .button {
    margin: 0 10px;
  }

  .secondary {
    @include button(#ffffff);

    color: $black;
    border: 1px solid $primary;
  }

  .bouncing-loader {
    margin-bottom: 50px;
  }

  .form-wrapper {
    max-width: 630px;
    width: 40%;
    margin: 30px auto 0;

    &__qr-code-btn-container {
      display: flex;
    }

    .md-dialog-footer--inline {
      padding: 0;
      display: flex;
      justify-content: space-between;
    }

    .md-grid {
      & > div {
        width: 100%;
      }

      .input-wrapper {
        display: flex;
        align-items: center;
      }

      #video-area {
        border: 1px solid gray;
        width: 100%;
      }

      input {
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
      }

      .md-divider {
        background-color: rgb(151, 151, 151);
      }

      .md-floating-label {
        color: rgb(63, 63, 63);
        font-size: 18px;
        font-weight: 400;
        padding-left: 10px;
      }

      .md-layover {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgb(151, 151, 151);
        margin-bottom: 20px;

        .md-select-field--btn {
          color: rgb(63, 63, 63);
          font-size: 18px;
          font-weight: 700;
          padding-left: 10px;
        }

        .md-icon-separator {
          padding-right: 0;
        }
      }

      .md-tile-content {
        .md-text {
          color: rgb(63, 63, 63);
          font-size: 18px;
          font-weight: 700;
        }
      }

      .md-list--menu-below {
        top: 80%;
      }
    }

    .p-center {
      text-align: center;
      margin-bottom: 40px;
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.5s ease-in;
      pointer-events: none;
      height: 0;

      img {
        max-height: 400px;
        min-height: 340px;

        @media (max-width: 500px) {
            min-height: unset;
            max-height: unset;
            width: 80%;
        }
      }

      &.fade-in {
        opacity: 1;
        height: auto;
        margin-bottom: 40px;

        @media (max-width: 500px) {
            padding: 0px;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .form-wrapper {
      width: 70%;
    }
  }

  @media (max-width: 500px) {
    .form-wrapper {
      width: 85%;
    }
  }
}
