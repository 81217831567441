.react-joyride__tooltip {
  h4 {
    color: $black;
    text-align: left;
    font-size: 28px !important;
    font-weight: 600;
    padding: 0 10px;
  }

  .tooltip-content {
    text-align: left;
    color: $black;
    font-weight: 300;
  }

  button[data-test-id="button-primary"] {
    @include button($secondary);
    width: 95px;
    height: 40px;
    outline: none;
    font-size: 19px !important;
    border-radius: 20px !important;
    color: $black !important;
  }
}

.tooltip-action {
  text-align: left;
  color: $black;
  font-weight: 600;
  @include font-size(16px);
}

button.react-joyride__beacon {
  outline: none !important;
}

.__floater {
  @include media-breakpoint-down(sm) {
    visibility: hidden !important;
  }
}

.__floater.__floater__open {
  transition: none !important;
}

.mobile-tooltip {
  display: none;

  @include media-breakpoint-down(sm) {
    z-index: 2000;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    cursor: pointer;

    background-color: $background;
    box-shadow: 0px -5px 13px -2px rgba(155, 155, 155, 0.5);
  }

  .tooltip-content {
    padding: 10px 10px 20px 17px;
    display: flex;
    flex-direction: column;
  }

  .tooltip-step {
    color: $primary;
    @include font-size(18px);
  }

  .tooltip-close {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 15px;
  }
}
