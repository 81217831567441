.disclaimer {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background;
  width: 100vw;
  padding: 25px;
  border-bottom: 1px solid #dddddd;

  .disclaimer-text {
    font-weight: 600;
    font-size: 18px;
    color: $black;
    white-space: normal;
    margin-right: 20px;

    @include media-breakpoint-down(xs) {
      font-size: 90% !important;
    }
  }

  .disclaimer-link, .disclaimer-link:hover, .disclaimer-link:visited {
    font-weight: 900;
    font-size: 18px;
    color: $primary;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(xs) {
      font-size: 90% !important;
    }
  }

  .button {
    @include button($primary);

    @include media-breakpoint-down(xs) {
      line-height: 37px;
      height: 40px;
      font-size: 15px;
      width: 140px;
    }
  }
}
