$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Bootstrap overrides
$primary: #28993F;
$secondary: #FDC47B;
$success: #28a745;
$danger: #dc3545;
$info: #17a2b8;
$warning: #ffc107;
$light: #f8f9fa;
$dark: #343434;
$black: #000000;
$white: #ffffff;
$teal: #20a49d;
$background: #f6f6f6;

$a-tags: 'a, a:active, a:hover, a:visited';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

:root {
  --black: #1c1e21;
  --blueberry: #{$primary};
  --body-copy: #696f76;
  --brown-grey: #8e8e8e;
  --gunmetal-grey: #{$secondary};
  --jade: #1e9a93;
  --light-grey: #c4c4c4;
  --sand-grey: #f3f2f1;
  --tealish: #{$teal};
}

// Bootstrap overrides
$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;


