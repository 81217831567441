////
/// @group collapsers
////

/// Creates the styles for a collapser icon
@mixin react-md-collapsers {
  .md-collapser {

    &.md-collapser {
      transition-duration: $md-transition-time;
      transition-property: transform;
    }

    .md-tooltip-container {
      transform: rotate3d(0, 0, 1, 0deg);
      transition-duration: $md-transition-time;
      transition-property: transform;
    }

    &--flipped {
      transform: rotate3d(0, 0, 1, 180deg);

      .md-tooltip-container {
        transform: rotate3d(0, 0, 1, -180deg);
      }
    }
  }
}
