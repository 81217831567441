.bouncing-loader, .bouncing-loader-text-wrapper {
  flex-direction: row !important;
  justify-content: center;
  display: flex;
}

.bouncing-loader {
  height: 95px;
  transform: translateY(50px);
}


@mixin bouncing-loader-mix {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: $primary;
  border-radius: 50%;

  animation: bouncing-loader 0.6s infinite alternate;

  @include media-breakpoint-down(xs) {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.bouncing-loader {
  &__item-1 {
    @include bouncing-loader-mix;
  }
  &__item-2 {
    @include bouncing-loader-mix;
    animation-delay: 0.2s;
  }
  &__item-3 {
    @include bouncing-loader-mix;
    animation-delay: 0.4s;
  }
}


@keyframes bouncing-loader {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
}
