////
/// @group text-fields
////

/// The box shadow to apply to text fields that have been autocompleted by the browser's
/// autocomplete ability. The default styles are to remove the yellow color and just display
/// as a normal text field. This value can be set to `null` to allow normal browser behavior
/// or any other valid box-shadow style.
///
/// @type String
$md-text-field-autocompleted-shadow: 0 0 0 50px $md-white-base inset !default;

/// The error color to use on text fields. This will be applied to the
/// any icons, text field error message, floating label, and the text
/// field divider when there is an error.
///
/// @type Color
/// @deprecated
$md-text-field-error-color: $md-error-color !default;

/// The text field divider's active height. This is the bar that
/// gets colored with the primary color or the error color.
/// @type Number
$md-text-field-divider-active-height: 2px !default;

/// A Map of custom sizes for text fields. Set this to null if you do not want any
/// custom sizes by default. This is a key value pair of String and List. The key will
/// be used to generate a `--suffix` class name to apply to floating labels and text
/// fields. The list must have a length of 4 where it is the:
///   - custom font size
///   - the transition duration modifier
///   - the y position of the label when inactive on mobile devices
///   - the y position of the label when inactive on desktop screens
///
/// @type Map
$md-text-field-custom-sizes: (
  title: ($md-display-1-font-size, .25s, 42px, 36px),
) !default;

/// Boolean if the multiline styling should be included by default.
/// @type Boolean
$md-text-field-include-multiline: true !default;

/// Boolean if the floating label styling should be included by default.
/// @type Boolean
$md-text-field-include-floating-label: true !default;

/// Boolean if the block styling should be included by default.
/// @type Boolean
$md-text-field-include-block: true !default;

/// Boolean if the icon styling for text fields should be included by default.
/// @type Boolean
$md-text-field-include-icon: true !default;

/// Boolean if the message styling for text fields should be included by default.
/// @type Boolean
$md-text-field-include-message: true !default;

/// Boolean if the password text field styling should be included by default.
/// @type Boolean
$md-text-field-include-password: true !default;

/// Boolean if the minimal styling for inline indicators for text fields should be included by default.
/// @type Boolean
$md-text-field-include-inline-indicator: true !default;



/// The font size for the text field messages.
/// @type Number
$md-text-field-message-font-size: 12px !default;

/// The font size for the floating label when it is floating above
/// the text field.
/// @type Number
$md-text-field-active-font-size: 12px !default;

/// The padding below the text field divider on mobile devices.
/// @type Number
$md-text-field-mobile-divider-bottom-padding: 8px !default;

/// The height for a single-line text field on mobile devices.
/// @type Number
$md-text-field-mobile-height: $md-height !default;

/// The height for a floating-label text field on mobile devices.
/// @type Number
$md-text-field-mobile-floating-height: 72px !default;

/// The font size for a text field on mobile devices.
/// @type Number
$md-text-field-mobile-font-size: 16px !default;

/// The padding above a floating label on mobile devices.
/// @type Number
$md-text-field-mobile-top-padding: $md-default-padding !default;

/// The padding between a _floating_ floating label and the text field
/// on mobile devices.
/// @type Number
$md-text-field-mobile-floating-label-padding: 8px !default;

/// The padding above the text field divider for mobile and desktop.
/// @type Number
$md-text-field-divider-top-padding: 7px !default;

// The padding above and below the block text field on mobile devices.
// @type Number
$md-text-field-mobile-block-padding: 20px !default;

/// The height for a single-line text field on desktop displays.
/// @type Number
$md-text-field-desktop-height: 40px !default;

/// The height for a floating-label text field on desktop displays.
/// @type Number
$md-text-field-desktop-floating-height: 60px !default;

/// The font size for text fields on desktop displays.
/// @type Number
$md-text-field-desktop-font-size: 13px !default;

/// The padding from the top of the text field container to the _floating_
/// floating label on desktop displays.
/// @type Number
$md-text-field-desktop-top-padding: 12px !default;

/// The padding between a _floating_ floating label and the text field on
/// desktop displays.
/// @type Number
$md-text-field-desktop-floating-label-padding: 4px !default;

/// The padding between the text field container's bottom and the text field
/// divider on desktop displays.
/// @type Number
$md-text-field-desktop-divider-bottom-padding: 4px !default;

// The padding above and below the block text field on desktop screens.
// @type Number
$md-text-field-desktop-block-padding: $md-default-padding !default;

/// This is the position from the top of the text field's container that the floating
/// label should appear.
/// @type Number
$md-text-field-mobile-floating-label-margin: 37px !default;

/// This is the position from the top of the text field's container that the floating
/// label should appear for desktop screens.
/// @type Number
$md-text-field-desktop-floating-label-margin: 33px !default;

/// The line-height to use for the text field's input. This will not be applied to multiline
/// text fields. This value should be more than 1 to support Firefox clipping letters.
/// @type Number
$md-text-field-line-height: 1.15 !default;

/// The line-height to apply to the text field message. This is really only required in case the
/// text field appears as a title in a toolbar.
/// @type Number
$md-text-field-message-line-height: $md-line-height !default;


/// Creates the styles for text fields on mobile devices. The floating labels, blocks,
/// icons, or password styles can be omitted by disabling the defaults or passing
/// false here. This should normally be used within a media query for mobile devices.
///
/// > Note: If the password is included, icons will automatically be included since they
/// > share styles.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-fields-mobile;
///   }
///
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the multiline
///     styling of text fields should be included.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the `block` styling
///     should be included.
/// @param {Boolean} include-icon [$md-text-field-include-icon] - Boolean if the minimal styling for
///     using icons should be included.
/// @param {Boolean} include-password [$md-text-field-include-password] - Boolean if the styling for password
///     text fields should be included.
/// @param {Boolean} include-inline-indicator [$md-text-field-include-inline-indicator] - Boolean if the styling
///     for inline indicators should be included. If the `$include-password` param is `true`, this will automatically
///     be included as well.
/// @param {Map} custom-sizes [$md-text-field-custom-sizes] - A map of custom sizes to apply. If you do not
///     want any custom sizes, set the global or this param to null.
/// @group text-fields, media-queries
@mixin react-md-text-fields-mobile(
  $include-floating-label: $md-text-field-include-floating-label,
  $include-block: $md-text-field-include-block,
  $include-icon: $md-text-field-include-icon,
  $include-password: $md-text-field-include-password,
  $include-inline-indicator: $md-text-field-include-inline-indicator,
  $custom-sizes: $md-text-field-custom-sizes
) {
  @include react-md-text-field-container-mobile($include-block);
  @include react-md-text-field-mobile($include-floating-label);
  @include react-md-text-field-divider-mobile;

  @if $include-floating-label {
    @include react-md-text-field-floating-label-mobile;
  }

  @if $include-password or $include-icon {
    @include react-md-text-field-icon-mobile;
  }

  @if $include-password or $include-inline-indicator {
    @include react-md-text-field-inline-indicator-mobile($include-floating-label, $include-block);
  }

  @include react-md-text-field-custom-sizes-mobile($custom-sizes, $include-floating-label);
}

/// Creates the styles for text fields on desktop screens. The floating labels, blocks,
/// icons, or password styles can be omitted by disabling the defaults or passing
/// false here. This should normally be used within a media query for desktop screens.
///
/// > Note: If the password is included, icons will automatically be included since they
/// > share styles.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-fields-desktop;
///   }
///
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the multiline
///     styling of text fields should be included.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the `block` styling
///     should be included.
/// @param {Boolean} include-icon [$md-text-field-include-icon] - Boolean if the minimal styling for
///     using icons should be included.
/// @param {Boolean} include-message [$md-text-field-include-message] - Boolean if the text field message
///     styling should be included. This is the CSS for the counter and/or help/error text.
/// @param {Boolean} include-password [$md-text-field-include-password] - Boolean if the styling for password
///     text fields should be included.
/// @param {Boolean} include-inline-indicator [$md-text-field-include-inline-indicator] - Boolean if the styling
///     for inline indicators should be included. If the `$include-password` param is `true`, this will automatically
///     be included as well.
/// @param {Boolean} include-dense-icons [$md-font-icon-include-dense] - Boolean if the dense font icon
///     styles should be included for text fields.
/// @param {Map} custom-sizes [$md-text-field-custom-sizes] - A map of custom sizes to apply. If you do not
///     want any custom sizes, set the global or this param to null.
/// @group text-fields, media-queries
@mixin react-md-text-fields-desktop(
  $include-floating-label: $md-text-field-include-floating-label,
  $include-block: $md-text-field-include-block,
  $include-icon: $md-text-field-include-icon,
  $include-message: $md-text-field-include-message,
  $include-password: $md-text-field-include-password,
  $include-inline-indicator: $md-text-field-include-inline-indicator,
  $include-dense-icons: $md-font-icon-include-dense,
  $custom-sizes: $md-text-field-custom-sizes
) {
  @include react-md-text-field-container-desktop($include-block);
  @include react-md-text-field-desktop($include-floating-label);
  @include react-md-text-field-divider-desktop;

  @if $include-floating-label {
    @include react-md-text-field-floating-label-desktop;
  }

  @if $include-password or $include-icon {
    @include react-md-text-field-icon-desktop($include-password, $include-dense-icons);
  }

  @if $include-message and ($include-dense-icons and $include-icon) {
    .md-text-field-message-container {
      $margin: if($md-font-icon-include-dense, $md-font-icon-dense-size, $md-font-icon-size) + $md-default-padding;

      &--left-icon-offset {
        padding-left: $margin;
      }

      &--right-icon-offset {
        padding-right: $margin;
      }
    }
  }

  @if $include-password or $include-inline-indicator {
    @include react-md-text-field-inline-indicator-desktop($include-floating-label, $include-block);
  }

  @include react-md-text-field-custom-sizes-desktop($custom-sizes, $include-floating-label);
}

/// Includes the media queries for text fields on mobile devices and desktop screens. The floating
/// labels, blocks, icons, or password styles can be omitted by disabling the defaults or passing
/// false here. This should normally be used within a media query for mobile devices.
///
/// > Note: If the password is included, icons will automatically be included since they
/// > share styles.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-fields-media;
///
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the multiline
///     styling of text fields should be included.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the `block` styling
///     should be included.
/// @param {Boolean} include-icon [$md-text-field-include-icon] - Boolean if the minimal styling for
///     using icons should be included.
/// @param {Boolean} include-message [$md-text-field-include-message] - Boolean if the text field message
///     styling should be included. This is the CSS for the counter and/or help/error text.
/// @param {Boolean} include-password [$md-text-field-include-password] - Boolean if the styling for password
///     text fields should be included.
/// @param {Boolean} include-inline-indicator [$md-text-field-include-inline-indicator] - Boolean if the styling
///     for inline indicators should be included. If the `$include-password` param is `true`, this will automatically
///     be included as well.
/// @param {Boolean} include-dense-icons [$md-font-icon-include-dense] - Boolean if the dense font icon
///     styles should be included for text fields.
/// @param {Map} custom-sizes [$md-text-field-custom-sizes] - A map of custom sizes to apply. If you do not
///     want any custom sizes, set the global or this param to null.
/// @group text-fields, media-queries
@mixin react-md-text-fields-media(
  $include-floating-label: $md-text-field-include-floating-label,
  $include-block: $md-text-field-include-block,
  $include-icon: $md-text-field-include-icon,
  $include-message: $md-text-field-include-message,
  $include-password: $md-text-field-include-password,
  $include-inline-indicator: $md-text-field-include-inline-indicator,
  $include-dense-icons: $md-font-icon-include-dense,
  $custom-sizes: $md-text-field-custom-sizes
) {
  @media #{$md-mobile-media} {
    @include react-md-text-fields-mobile($include-floating-label, $include-block, $include-icon, $include-password, $custom-sizes);
  }

  @media #{$md-desktop-media} {
    @include react-md-text-fields-desktop($include-floating-label, $include-block, $include-icon, $include-message, $include-password, $include-dense-icons, $custom-sizes);
  }
}

/// Includes the CSS for text fields. The floating label, block styling, icon, password, and
/// messages are all optional. These sections can be enabled or disabled by modifying the params
/// on this mixin or modifying the default values. If the `$include-media` param is true, the
/// mobile and desktop media queries will be included for all included parts of the text fields.
///
/// Also if the `$md-text-field-custom-sizes` map is defined, each custom size will be created.
///
/// @example scss - Basic Usage SCSS
///   @include react-md-text-fields;
///
///   // or
///   $md-primary-color: $md-light-blue-500;
///   @include react-md-text-fields;
///
///   // or
///   @include react-md-text-fields($md-light-theme, $md-light-blue-500);
///
///
/// @see {mixin} react-md-text-fields-media
/// @param {Color} primary-color [$md-primary-color] - The primary color to theme text fields with.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
/// @param {Color} error-color [$md-red-500] - The error color to use with text fields.
/// @param {Boolean} include-media [$md-media-included] - Boolean if the media queries should be included.
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the multiline
///     styling of text fields should be included.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the `block` styling
///     should be included.
/// @param {Boolean} include-icon [$md-text-field-include-icon] - Boolean if the minimal styling for
///     using icons should be included.
/// @param {Boolean} include-message [$md-text-field-include-message] - Boolean if the text field message
///     styling should be included. This is the CSS for the counter and/or help/error text.
/// @param {Boolean} include-password [$md-text-field-include-password] - Boolean if the styling for password
///     text fields should be included.
/// @param {Boolean} include-inline-indicator [$md-text-field-include-inline-indicator] - Boolean if the styling
///     for inline indicators should be included. If the `$include-password` param is `true`, this will automatically
///     be included as well.
/// @param {Boolean} include-dense-icons [$md-font-icon-include-dense] - Boolean if the dense font icon
///     styles should be included for text fields.
/// @param {Map} custom-sizes [$md-text-field-custom-sizes] - This is a map of size names and font sizes
///     to apply as custom sizes for the text field.
@mixin react-md-text-fields(
  $primary-color: $md-primary-color,
  $light-theme: $md-light-theme,
  $error-color: $md-error-color,
  $include-media: $md-media-included,
  $include-multiline: $md-text-field-include-multiline,
  $include-floating-label: $md-text-field-include-floating-label,
  $include-block: $md-text-field-include-block,
  $include-icon: $md-text-field-include-icon,
  $include-message: $md-text-field-include-message,
  $include-password: $md-text-field-include-password,
  $include-inline-indicator: $md-text-field-include-inline-indicator,
  $include-dense-icons: $md-font-icon-include-dense,
  $custom-sizes: $md-text-field-custom-sizes
) {
  @include react-md-text-field-container($light-theme, $include-multiline, $include-block, $include-message);
  @include react-md-text-field($light-theme, $include-multiline);
  @include react-md-text-field-divider($primary-color, $error-color);

  @if $include-floating-label {
    @include react-md-text-field-floating-label($primary-color, $light-theme, $error-color);
  }

  @if $include-message {
    @include react-md-text-field-message($light-theme, $error-color, $include-icon);
  }

  @if $include-password or $include-icon {
    @include react-md-text-field-icon($primary-color, $light-theme, $error-color);
  }

  @if $include-password or $include-inline-indicator {
    @include react-md-text-field-inline-indicator;
  }

  @if $include-password {
    @include react-md-text-field-password($light-theme);
  }

  @if $include-media {
    @include react-md-text-fields-media($include-floating-label, $include-block, $include-icon, $include-message, $include-password, $include-dense-icons, $custom-sizes);
  }

  @include react-md-text-field-custom-sizes($md-text-field-custom-sizes, $include-floating-label);
}


/// Themes all parts of the text fields if they are included.
///
/// @example scss - Example Usage SCSS
///   .my-subtheme {
///     @include react-md-theme-text-fields($md-blue-500);
///   }
///
/// @see {mixin} react-md-theme-text-field-divider
/// @param {Color} primary-color - The primary color to theme text fields with.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
/// @param {Color} error-color [$md-red-500] - The error color to use with text fields.
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the multiline
///     styling of text fields should be included.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the `block` styling
///     should be included.
/// @param {Boolean} include-icon [$md-text-field-include-icon] - Boolean if the minimal styling for
///     using icons should be included.
/// @param {Boolean} include-message [$md-text-field-include-message] - Boolean if the text field message
///     styling should be included. This is the CSS for the counter and/or help/error text.
/// @param {Boolean} include-password [$md-text-field-include-password] - Boolean if the styling for password
///     text fields should be included.
/// @group text-fields, themes
@mixin react-md-theme-text-fields(
  $primary-color,
  $light-theme: $md-light-theme,
  $error-color: $md-error-color,
  $include-multiline: $md-text-field-include-multiline,
  $include-floating-label: $md-text-field-include-floating-label,
  $include-block: $md-text-field-include-block,
  $include-icon: $md-text-field-include-icon,
  $include-message: $md-text-field-include-message,
  $include-password: $md-text-field-include-password
) {
  @if $light-theme != $md-light-theme {
    .md-text-field {
      @include react-md-style-placeholders {
        color: get-color('secondary', $light-theme);
      };

      &[disabled] {
        @include react-md-style-placeholders {
          color: get-color('disabled', $light-theme);
        };
      }
    }
  }

  @include react-md-theme-text-field-divider($primary-color, $error-color);
}


/// Creates the styles for the text-field-container on mobile devices. This
/// should normally be used within the `react-md-text-fields-mobile` mixin.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @import react-md-text-field-container-mobile;
///   }
///
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if block styling should
///     be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-container-mobile($include-block: $md-text-field-include-block) {
  @if $include-block {
    .md-text-field-container--input-block {
      padding-bottom: $md-text-field-mobile-block-padding;
      padding-top: $md-text-field-mobile-block-padding;
    }

    .md-text-field-container--multiline-block {
      margin-bottom: $md-text-field-mobile-block-padding;
      margin-top: $md-text-field-mobile-block-padding;
    }

    .md-text-field-container--padded-block {
      padding-left: $md-text-field-mobile-block-padding;
      padding-right: $md-text-field-mobile-block-padding;
    }
  }
}


/// Creates the styles for the text-field-container on desktop screens. This
/// should normally be used within the `react-md-text-fields-desktop` mixin.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @import react-md-text-field-container-desktop;
///   }
///
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if block styling should
///     be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-container-desktop($include-block: $md-text-field-include-block) {
  @if $include-block {
    .md-text-field-container--input-block {
      padding-bottom: $md-text-field-desktop-block-padding;
      padding-top: $md-text-field-desktop-block-padding;
    }

    .md-text-field-container--multiline-block {
      margin-bottom: $md-text-field-desktop-block-padding;
      margin-top: $md-text-field-desktop-block-padding;
    }

    .md-text-field-container--padded-block {
      padding-left: $md-text-field-desktop-block-padding;
      padding-right: $md-text-field-desktop-block-padding;
    }
  }
}


/// Includes the media queries for the text-field container.
///
/// @example scss - Example Usage SCSS
///   @import react-md-text-field-container-media;
///
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if block styling should
///     be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-container-media($include-block: $md-text-field-include-block) {
  @if $include-block {
    @media #{$md-mobile-media} {
      @include react-md-text-field-container-mobile($include-block);
    }

    @media #{$md-desktop-media} {
      @include react-md-text-field-container-desktop($include-block);
    }
  }
}

/// Includes only the `md-text-field-container` CSS. This should normally be used from
/// the `react-md-text-fields` mixin. The placeholders will also be styled accordingly.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-container;
///
///   // or
///   $md-primary-color: $md-orange-500;
///   @include react-md-text-field-container;
///
///
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if currently using the light
///     theme.
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the
///     multiline text field CSS should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the block styling
///     for text fields should be included.
/// @param {Boolean} include-message [$md-text-field-include-message] - Boolean if the styles for the inline
///     messages or counter should be included. This will only every create styles if the `include-block` param
///     is `true` as well.
@mixin react-md-text-field-container(
  $light-theme: $md-light-theme,
  $include-multiline: $md-text-field-include-multiline,
  $include-block: $md-text-field-include-block,
  $include-message: $md-text-field-include-message
) {

  .md-text-field-container {
    position: relative;
  }

  .md-text-field-container--input {
    line-height: $md-text-field-line-height;
  }

  @if $include-block {
    .md-text-field-container--input-block {
      display: flex;

      @if $include-message {
        .md-text-field-message-container {
          flex-shrink: 0;
        }
      }
    }
  }

  @if $include-multiline {
    .md-text-field-multiline-container,
    .md-text-field-container--multiline {
      transition-duration: $md-transition-time;
      transition-property: height;
    }

    .md-text-field-multiline-container {
      position: relative;
    }

    .md-text-field-container--multiline {
      display: flex;
      flex-direction: column;
    }
  }
}



/// Includes the CSS for the input / textarea tags for text fields. This is normally
/// used only within the `react-md-text-fields` mixin.
///
/// @example scss - Basic Usage SCSS
///   @include react-md-text-field;
///
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text field should be styled
///     for the light-theme.
/// @param {Boolean} include-multiline [$md-text-field-include-multiline] - Boolean if the multiline styling
///     of text fields should be included.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the floating
///     label styles should be included.
@mixin react-md-text-field($light-theme: $md-light-theme, $include-multiline: $md-text-field-include-multiline) {
  .md-text-field {
    background: none;
    border: 0;
    line-height: inherit;
    padding: 0;
    width: 100%;

    @include react-md-style-placeholders {
      color: get-color('secondary', $light-theme);
    }

    &:focus {
      outline-style: none;
    }

    &[type="search"] {
      // This fixes the weird styling Safari applies to search text fields.
      -webkit-appearance: textfield;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      box-shadow: $md-text-field-autocompleted-shadow;
    }

    &--margin {
      margin-top: 13px;
    }

    &[disabled] {
      @include react-md-style-placeholders {
        color: get-color('disabled', $light-theme);
      }
    }
  }

  @if $include-multiline {
    .md-text-field--multiline {
      padding-bottom: 0;
      padding-top: 0;
      resize: none;
    }

    .md-text-field--multiline-mask {
      overflow: hidden;
      position: absolute;
      visibility: hidden;
    }
  }
}

/// Creates the CSS for styling text fields on a mobile device. This should
/// be used within media queries.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-mobile;
///   }
///
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-mobile($include-floating-label: $md-text-field-include-floating-label) {
  .md-text-field {
    font-size: $md-text-field-mobile-font-size;
  }

  @if $include-floating-label {
    .md-text-field--floating-margin {
      margin-top: $md-text-field-mobile-floating-label-margin;
    }
  }

}

/// Creates the CSS for styling text fields on a mobile device. This should
/// be used within media queries.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-desktop;
///   }
///
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-desktop($include-floating-label: $md-text-field-include-floating-label) {
  .md-text-field {
    font-size: $md-text-field-desktop-font-size;
  }

  @if $include-floating-label {
    .md-text-field--floating-margin {
      margin-top: $md-text-field-desktop-floating-label-margin;
    }
  }
}

/// Includes the media queries for text fields on mobile and desktop devices. This
/// is really only helpful if you do not use the `react-md-text-fields` mixin.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-media;
///
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     floating label styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-media($include-floating-label: $md-text-field-include-floating-label) {
  @media #{$md-mobile-media} {
    @include react-md-text-field-mobile($include-floating-label);
  }

  @media #{$md-desktop-media} {
    @include react-md-text-field-desktop($include-floating-label);
  }
}



/// Includes the styles for the text field's floating label.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-floating-label;
///
/// @param {Color} primary-color [$md-primary-color] - The primary color to theme text fields with.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
/// @param {Color} error-color [$md-red-500] - The error color to use with text fields.
@mixin react-md-text-field-floating-label($primary-color: $md-primary-color, $light-theme: $md-light-theme, $error-color: $md-error-color) {
  .md-floating-label {
    cursor: text;
    line-height: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition-duration: $md-transition-time;
    transition-property: transform, font-size, color;
    white-space: nowrap;
  }

  .md-floating-label--floating {
    font-size: $md-text-field-active-font-size;
  }

  .md-floating-label--icon-offset {
    left: $md-font-icon-size + $md-default-padding;

    @if $md-font-icon-include-dense {
      @media #{$md-desktop-media} {
        left: $md-font-icon-dense-size + $md-default-padding;
      }
    }
  }
}


/// @deprecated This is no longer needed since the colors come from the `.md-text--{color-type}`.
@mixin react-md-theme-text-field-floating-label($primary-color: $md-primary-color, $light-theme: $md-light-theme, $error-color: $md-error-color) {
}

/// Creates the styles for the text field floating labels for mobile devices.
/// This should be used within a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-floating-label-mobile;
///   }
/// @group text-fields, media-queries
@mixin react-md-text-field-floating-label-mobile {
  .md-floating-label--inactive {
    $text-field-size: floor($md-text-field-mobile-font-size * $md-line-height);
    $distance: $md-text-field-mobile-top-padding + $md-text-field-active-font-size + ($text-field-size / 2);

    transform: translate3d(0, $distance, 0);
  }

  .md-floating-label--inactive-sized {
    font-size: $md-text-field-mobile-font-size;
  }

  .md-floating-label--floating {
    transform: translate3d(0, $md-default-padding, 0);
  }
}

/// Creates the styles for the text field floating labels for desktop screens.
/// This should be used within a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-floating-label-desktop;
///   }
/// @group text-fields, media-queries
@mixin react-md-text-field-floating-label-desktop {
  .md-floating-label--inactive {
    $text-field-size: floor($md-text-field-desktop-font-size * $md-line-height);
    $distance: $md-text-field-desktop-top-padding + $md-text-field-active-font-size + ($text-field-size / 2);

    transform: translate3d(0, $distance, 0);
  }

  .md-floating-label--inactive-sized {
    font-size: $md-text-field-desktop-font-size;
  }

  .md-floating-label--floating {
    transform: translate3d(0, 12px, 0);
  }
}

/// Includes the media queries and styles for the text field floating labels..
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-floating-label-media;
/// @group text-fields, media-queries
@mixin react-md-text-field-floating-label-media {
  @media #{$md-mobile-media} {
    @include react-md-text-field-floating-label-mobile;
  }

  @media #{$md-desktop-media} {
    @include react-md-text-field-floating-label-desktop;
  }
}


/// Includes only CSS for the text field dividers. This is really used if
/// you do not use the `react-md-text-fields` mixin.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-divider;
///
/// @param {Color} primary-color [$md-primary-color] - The primary color to theme text fields with.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
/// @param {Color} error-color [$md-error-color] - The error color to use with text fields.
@mixin react-md-text-field-divider($primary-color: $md-primary-color, $error-color: $md-error-color) {
  .md-divider--text-field {
    margin-top: $md-text-field-divider-top-padding;
    overflow: visible;

    &::after {
      background: $primary-color;
      content: '';
      display: block;
      height: $md-text-field-divider-active-height;
      transition-duration: $md-transition-time;
      transition-property: width;
      width: 0;
    }
  }

  .md-divider--text-field-expanded::after {
    width: 100%;
  }

  .md-divider--text-field-error,
  .md-divider--text-field-error::after {
    background: $error-color;
  }

  .md-divider--expand-from-left::after {
    left: 0;
  }

  .md-divider--expand-from-center::after {
    margin: auto;
  }

  .md-divider--expand-from-right::after {
    @extend %md-cell--right;

    right: 0;
  }
}

/// Modifies the styles of the text-field-divider ONLY if:
/// - the `$primary-color` is different than `$md-primary-color`
/// - the `$error-color` is different than `$md-error-color`.
///
/// In most cases, you should use the `react-md-theme-text-fields` mixin instead.
///
/// @example scss - Example Usage SCSS
///   .my-subsection-styling {
///     @include react-md-theme-text-field-divider($md-light-blue-400, true, $md-orange-500);
///   }
///
/// @see {mixin} react-md-theme-text-fields
/// @param {Color} primary-color [$md-primary-color] - The primary color to use.
/// @param {Color} error-color [$md-error-color] - The error color to use.
/// @group text-fields, themes
@mixin react-md-theme-text-field-divider($primary-color: $md-primary-color, $error-color: $md-error-color) {
  @if $primary-color != $md-primary-color {
    .md-divider--text-field:not(.md-divider--text-field-error)::after {
      background: $primary-color;
    }
  }

  @if $error-color != $md-error-color {
    .md-divider--text-field-error,
    .md-divider--text-field-error::after {
      background: $error-color;
    }
  }
}

/// Creates the styles for the text field dividers for mobile devices.
/// This should be used within a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-divider-mobile;
///   }
/// @group text-fields, media-queries
@mixin react-md-text-field-divider-mobile {
  .md-divider--text-field {
    margin-bottom: $md-text-field-mobile-divider-bottom-padding;
  }
}

/// Creates the styles for the text field dividers for mobile devices.
/// This should be used within a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-divider-desktop;
///   }
/// @group text-fields, media-queries
@mixin react-md-text-field-divider-desktop {
  .md-divider--text-field {
    margin-bottom: $md-text-field-desktop-divider-bottom-padding;
  }
}

/// Includes the mobile and desktop media queries for the text field dividers.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-divider-media;
/// @group text-fields, media-queries
@mixin react-md-text-field-divider-media {
  @media #{$md-mobile-media} {
    @include react-md-text-field-divider-mobile;
  }

  @media #{$md-desktop-media} {
    @include react-md-text-field-divider-desktop;
  }
}

/// Includes the styles for the text field message / counter component.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-message;
///
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
/// @param {Color} error-color [$md-red-500] - The error color to use with text fields.
/// @param {Boolean} include-icon [$md-text-field-include-icon] - Boolean if the styles for text fields
///     with icons and messages should be included.
@mixin react-md-text-field-message($light-theme: $md-light-theme, $error-color: $md-error-color, $include-icon: $md-text-field-include-icon) {
  .md-text-field-message-container {
    display: flex;
    font-size: $md-text-field-message-font-size;
    justify-content: space-between;
  }

  .md-text-field-message-container--count-only {
    justify-content: flex-end;
  }

  @if $include-icon {
    .md-text-field-message-container {
      $padding: $md-font-icon-size + $md-default-padding;

      &--left-icon-offset {
        padding-left: $padding;
      }

      &--right-icon-offset {
        padding-right: $padding;
      }
    }
  }

  .md-text-field-message {
    color: inherit;
    flex-grow: 1;
    line-height: $md-text-field-message-line-height;
    transition-duration: $md-transition-time;
    transition-property: opacity;
    white-space: normal;
  }

  .md-text-field-message--counter {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: $md-default-padding;
  }

  .md-text-field-message--inactive {
    opacity: 0;
  }

  .md-text-field-message--active {
    opacity: 1;
  }
}

/// @deprecated The theme colors now come from the `.md-text--{color-type}` class names.
@mixin react-md-theme-text-field-message($light-theme: $md-light-theme, $error-color: $md-error-color) {
}




/// Includes the styles for using icons with text fields. This is really only useful if you did
/// not use the `react-md-text-fields` mixin.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-icon;
///
/// @param {Color} primary-color [$md-primary-color] - The primary color to theme text fields with.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
/// @param {Color} error-color [$md-red-500] - The error color to use with text fields.
@mixin react-md-text-field-icon($primary-color: $md-primary-color, $light-theme: $md-light-theme, $error-color: $md-error-color) {
  .md-text-field-divider-container {
    display: inline-block;
  }

  .md-text-field-divider-container--grow {
    display: block;
    flex-grow: 1;
  }

  .md-text-field-icon + .md-text-field-divider-container,
  .md-text-field-divider-container + .md-text-field-icon {
    margin-left: $md-default-padding;
  }

  .md-text-field-icon-container {
    align-items: flex-end;
    display: flex;
    width: 100%;

    .md-text-field-container--input-block & {
      align-items: center;
    }
  }
}



/// @deprecated
@mixin react-md-theme-text-field-icon($primary-color: $md-primary-color, $light-theme: $md-light-theme, $error-color: $md-error-color) {
}

/// Creates the styles for text field icons on mobile devices. This should be included within
/// a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-icon-mobile;
///   }
/// @group text-fields, media-queries
@mixin react-md-text-field-icon-mobile {
  $text-field-height: 19px;

  .md-text-field-icon--positioned {
    margin-bottom: $md-default-padding - (($md-font-icon-size - $text-field-height) / 2);
  }
}

/// Creates the styles for text field icons on desktop screens. This should be included within
/// a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-icon-desktop;
///   }
///
/// @param {Boolean} include-password [$md-text-field-include-password] - Boolean if the password button
///     should be included.
/// @param {Boolean} include-dense-icons [$md-font-icon-include-dense] - Boolean if the dense font icon
///     styles should be included for text fields.
/// @group text-fields, media-queries
@mixin react-md-text-field-icon-desktop($include-password: $md-text-field-include-password, $include-dense-icons: $md-font-icon-include-dense) {
  $text-field-height: 15px;

  .md-text-field-icon--positioned {
    margin-bottom: 12px - ((if($include-dense-icons, $md-font-icon-dense-size, $md-font-icon-size) - $text-field-height) / 2);
  }

  @if $include-password and $include-dense-icons {
    .md-password-btn.md-password-btn {
      height: $md-font-icon-dense-size;
      width: $md-font-icon-dense-size;
    }
  }
}

/// Includes the media queries and styles for the text field icons.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-icon-media;
///
/// @param {Boolean} include-dense-icons [$md-font-icon-include-dense] - Boolean if the dense font icon
///     styles should be included for text fields.
/// @group text-fields, media-queries
@mixin react-md-text-field-icon-media($include-dense-icons: $md-font-icon-include-dense) {
  @media #{$md-mobile-media} {
    @include react-md-text-field-icon-mobile;
  }

  @media #{$md-desktop-media} {
    @include react-md-text-field-icon-desktop($include-dense-icons);
  }
}


/// Includes the styles for having a password text field.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-password;
///
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if the text fields should be styled for
///     the light or dark theme.
@mixin react-md-text-field-password($light-theme: $md-light-theme) {
  .md-password-btn {
    background: transparent;
    border: 0;
    height: $md-font-icon-size;
    padding: 0;
    width: $md-font-icon-size;

    &:not(.md-password-btn--focus):focus {
      outline-style: none;
    }

    .md-icon {
      color: inherit;
    }
  }

  .md-password-btn--active {

    &::before {
      background: get-color('secondary', $light-theme);
    }
  }

  .md-password-btn--invisible {
    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      top: 50%;
      width: 100%;
    }

    &::before {
      transform: translate3d(-1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
    }

    &::after {
      background: get-color('background', $light-theme);
      transform: translate3d(1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
    }
  }
}


/// Modifies the styles of the text-field-password ONLY if the value of `$light-theme` is
/// different than the `$md-light-theme`. This is only useful if you have a part of your app
/// that is `dark-theme` while another section is not. In most cases, you should use the
/// `react-md-theme-text-fields` mixin instead.
///
/// @example scss - Example Usage SCSS
///   .my-subsection-styling {
///     @include react-md-theme-text-field-password(true);
///   }
///
/// @see {mixin} react-md-theme-text-fields
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if using the light theme.
/// @group text-fields, themes
@mixin react-md-theme-text-field-password($light-theme: $md-light-theme) {
  @if $light-theme != $md-light-theme {
    .md-password-btn--active {
      &::before {
        background: get-color('secondary', $light-theme);
      }
    }

    .md-password-btn--invisible::after {
      background: get-color('background', $light-theme);
    }
  }
}

/// Creates the styles for the text field's inline indicator. This is is for showing a password button
/// or some other icon within the text field.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-inline-indicator;
@mixin react-md-text-field-inline-indicator {
  .md-text-field-inline-indicator {
    position: absolute;
    right: 0;
  }

  .md-text-field--inline-indicator {
    width: calc(100% - #{$md-font-icon-size});
  }
}

/// Creates the styles for showing inline indicators in text fields on mobile devices. This should
/// be used within a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-inline-indicator-mobile;
///   }
///
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///   floating label styles for inline indicators should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the block styles should
///     be included
/// @group text-fields, media-queries
@mixin react-md-text-field-inline-indicator-mobile($include-floating-label: $md-text-field-include-floating-label, $include-block: $md-text-field-include-block) {
  .md-text-field-inline-indicator {
    top: 9px;

    @if $include-floating-label {
      &--floating {
        top: 34px;
      }
    }

    @if $include-block {
      &--block {
        top: 17px;
      }
    }
  }
}

/// Creates the styles for showing inline indicators in text fields on desktop screens. This should
/// be used within a media query.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-inline-indicator-desktop;
///   }
///
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///   floating label styles for inline indicators should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the block styles should
///     be included.
/// @param {Boolean} include-dense-icons [$md-font-icon-include-dense] - Boolean if the dense icons styles
///     should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-inline-indicator-desktop(
  $include-floating-label: $md-text-field-include-floating-label,
  $include-block: $md-text-field-include-block,
  $include-dense-icons: $md-font-icon-include-dense
) {
  .md-text-field-inline-indicator {
    top: if($include-dense-icons, 9px, 7px);

    @if $include-floating-label {
      &--floating {
        top: if($include-dense-icons, 30px, 28px);
      }
    }

    @if $include-block {
      &--block {
        top: if($include-dense-icons, 12px, 10px);
      }
    }
  }
}

/// Includes the media queries for showing inline indicators in text fields.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-inline-indicator-media;
///
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///   floating label styles for inline indicators should be included.
/// @param {Boolean} include-block [$md-text-field-include-block] - Boolean if the block styles should
///     be included
/// @group text-fields, media-queries
@mixin react-md-text-field-inline-indicator-media($include-floating-label: $md-text-field-include-floating-label, $include-block: $md-text-field-include-block) {
  @media #{$md-mobile-media} {
    @include react-md-text-field-inline-indicator-mobile($include-floating-label, $include-block);
  }

  @media #{$md-desktop-media} {
    @include react-md-text-field-inline-indicator-desktop($include-floating-label, $include-block);
  }
}


/// Creates the styles for a custom sized text field for mobile devices. This
/// should normally be used within the `react-md-text-field-custom-sizes-mobile`
/// mixin.
///
/// @example scss - Example usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-custom-size-mobile('title', (34px, .25s, 46px, 34px));
///
/// @see {mixin} react-md-text-field-custom-sizes-mobile
/// @param {String} name - The custom size name.
/// @param {List} list - A list containing the font size, the transition-time, the mobile y position
///     and the desktop y position.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if floating label
///     styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-custom-size-mobile($name, $list, $include-floating-label: $md-text-field-include-floating-label) {
  @if $include-floating-label != null {
    .md-floating-label--inactive-#{$name} {
      transform: translate3d(0, nth($list, 3), 0);
    }
  }
}


/// Creates the styles for a custom sized text field for desktop screens. This
/// should normally be used within the `react-md-text-field-custom-sizes-desktop`
/// mixin.
///
/// @example scss - Example usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-custom-size-desktop('title', (34px, .25s, 46px, 34px));
///
/// @see {mixin} react-md-text-field-custom-sizes-desktop
/// @param {String} name - The custom size name.
/// @param {List} list - A list containing the font size, the transition-time, the mobile y position
///     and the desktop y position.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if floating label
///     styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-custom-size-desktop($name, $list, $include-floating-label: $md-text-field-include-floating-label) {
  @if $include-floating-label != null {
    .md-floating-label--inactive-#{$name} {
      transform: translate3d(0, nth($list, 4), 0);
    }
  }
}


/// Creates all the styles for all the custom sizes for text fields for mobile devices. This
/// should normally be used in a media query or the `react-md-text-fields` mixin.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-mobile-media} {
///     @include react-md-text-field-custom-sizes-mobile($md-text-field-custom-sizes);
///   }
///
/// @see {mixin} react-md-text-fields
/// @see md-text-field-custom-sizes
/// @param {Map} custom-sizes [$md-text-field-custom-sizes] - A map of custom sizes to use
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if floating label
///     styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-custom-sizes-mobile($custom-sizes: $md-text-field-custom-sizes, $include-floating-label: $md-text-field-include-floating-label) {
  @if $custom-sizes != null {
    @each $name, $list in $custom-sizes {
      @include react-md-text-field-custom-size-mobile($name, $list, $include-floating-label);
    }
  }
}



/// Creates all the styles for all the custom sizes for text fields for desktop screens. This
/// should normally be used in a media query or the `react-md-text-fields` mixin.
///
/// @example scss - Example Usage SCSS
///   @media #{$md-desktop-media} {
///     @include react-md-text-field-custom-sizes-desktop($md-text-field-custom-sizes);
///   }
///
/// @see {mixin} react-md-text-fields
/// @see md-text-field-custom-sizes
/// @param {Map} custom-sizes [$md-text-field-custom-sizes] - A map of custom sizes to use
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if floating label
///     styles should be included.
/// @group text-fields, media-queries
@mixin react-md-text-field-custom-sizes-desktop($custom-sizes: $md-text-field-custom-sizes, $include-floating-label: $md-text-field-include-floating-label) {
  @if $custom-sizes != null {
    @each $name, $list in $custom-sizes {
      @include react-md-text-field-custom-size-desktop($name, $list, $include-floating-label);
    }
  }
}


/// Includes the CSS for custom sizes for text fields. This will create some class names with the
/// components they modify and a `--#{$name}` suffix.
///
/// @example scss - Example Usage SCSS
///   @include react-md-text-field-custom-sizes;
///
/// @example scss - Example Usage CSS Output
///   .md-text-field--title {
///     font-size: 34px;
///   }
///
///   .md-floating-label--title {
///     font-size: 34px;
///   }
///
/// @param {Map} font-sizes [$md-text-field-custom-sizes] - A map of size names with font sizes.
/// @param {Boolean} include-floating-label [$md-text-field-include-floating-label] - Boolean if the
///     custom text field size should include the styles for floating labels.
@mixin react-md-text-field-custom-sizes($font-sizes: $md-text-field-custom-sizes, $include-floating-label: $md-text-field-include-floating-label) {
  @if $font-sizes != null {
    @each $name, $list in $font-sizes {
      $font-size: nth($list, 1);

      .md-text-field--#{$name} {
        font-size: $font-size;
      }

      @if $include-floating-label {
        $time: nth($list, 2);

        @if $time != null {
          .md-floating-label--#{$name} {
            transition-duration: $time;
          }
        }

        .md-floating-label--inactive-#{$name} {
          font-size: $font-size;
        }
      }
    }
  }
}



/// A mixin for adding custom styles to placeholders since the autoprefixer doesn't seem
/// to be able to pick it up correctly.
///
/// @example scss - Example Scss Usage
///   .md-text-field[disabled] {
///     @include react-md-style-placeholders {
///       color: get-color(disabled, true);
///     }
///   }
///
///   .some-field-container {
///     @include react-md-style-placeholders(true) {
///       color: red;
///     }
///   }
///
/// @example scss - Example CSS Output
///   .md-text-field[disabled]:placeholder {
///     color: rgba(0, 0, 0, .87);
///   }
///
///   .md-text-field[disabled]:-moz-placeholder {
///     color: rgba(0, 0, 0, .87);
///   }
///
///   .md-text-field[disabled]::-moz-placeholder {
///     color: rgba(0, 0, 0, .87);
///   }
///
///   .md-text-field[disabled]:-ms-input-placeholder {
///     color: rgba(0, 0, 0, .87);
///   }
///
///   .md-text-field[disabled]::-webkit-placeholder {
///     color: rgba(0, 0, 0, .87);
///   }
///
///
///   .some-field-container :placeholder {
///     color: red;
///   }
///
///   .some-field-container :-moz-placeholder {
///     color: red;
///   }
///
///   .some-field-container ::-moz-placeholder {
///     color: red;
///   }
///
///   .some-field-container :-ms-input-placeholder {
///     color: red;
///   }
///
///   .some-field-container ::-webkit-placeholder {
///     color: red;
///   }
///
/// @param {Boolean} child [true] - Boolean if the placeholder should apply to a child of the class
///   name instead of applying to the current class name/selector.
@mixin react-md-style-placeholders($child: false) {
  @if $child {
    // scss-lint:disable VendorPrefix, PseudoElement
    :placeholder {
      @content;
    }

    :-moz-placeholder {
      @content;
    }

    ::-moz-placeholder {
      @content;
    }

    :-ms-input-placeholder {
      @content;
    }

    ::-webkit-input-placeholder {
      @content;
    }
  } @else {
    // scss-lint:disable VendorPrefix, PseudoElement

    &:placeholder {
      @content;
    }

    &:-moz-placeholder {
      @content;
    }

    &::-moz-placeholder {
      @content;
    }

    &:-ms-input-placeholder {
      @content;
    }

    &::-webkit-input-placeholder {
      @content;
    }
  }
}


/// Applies a color to all the browser placeholder pseudo elements.
///
/// @example scss - Basic Usage SCSS
///   .md-primary.md-toolbar {
///     @include md-theme-placeholders($md-dark-theme-help-text-color);
///   }
///
/// @example scss - Basic Usage CSS Output
///   .md-primary.md-toolbar {
///     :-moz-placeholder {
///       color: $color;
///     }
///
///     ::-moz-placeholder {
///       color: $color;
///     }
///
///     :-ms-input-placeholder {
///       color: $color;
///     }
///
///     ::-webkit-input-placeholder {
///       color: $color;
///     }
///   }
///
/// @param {Color} color - The placeholder color to use
/// @group text-fields, themes
/// @deprecated
/// @see react-md-style-placeholders
@mixin react-md-theme-placeholders($color) {
  @include react-md-style-placeholders(true) {
    color: $color;
  }
}
