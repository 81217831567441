@mixin button($background-color: $primary) {
  width: 200px;
  height: 55px;
  background: $background-color;
  border-radius: 42px;
  border: none;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: $white;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @supports (-ms-ime-align:auto) {
    justify-content: center; /* Edge only */
  }

  &:hover {
    text-decoration: none;
  }

  span {
    @include media-breakpoint-down(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @include media-breakpoint-down(xs) {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@mixin cta-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 5px 13px -2px rgba(155, 155, 155, 0.5);
  padding: 15px;

  .button {
    @include button;

    width: 350px;
    margin: auto;
    font-size: 18px;
    padding: 26px;
    line-height: 2px;
    border-radius: 45px;
  }
}
