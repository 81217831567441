.status-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;

  @media (max-width: 1092px) {
    margin: 0 20px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: center;
  }


  .status {
    min-width: 198px;
    max-width: 212px;
    width: auto;
    height: 180px;
    background-color: #E2EEE4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    position: relative;
    margin: 12px 6px;
    padding: 0 20px;

    @include media-breakpoint-down(sm) {
      width: 300px;
      max-width: unset;
    }

    &:not(:first-child):before {
      content: "";
      position: absolute;
      bottom: 55px;
      left: -20px;
      height: 28px;
      width: 28px;
      background-image: url(../images/arrow_icon.png);
      background-repeat: no-repeat;
    }

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 55px;
      right: -20px;
      height: 28px;
      width: 28px;
      background-image: url(../images/arrow_icon.png);
      background-repeat: no-repeat;
    }

    .value {
      font-size: 18px;
      font-weight: 400;
      color: $black;
      padding: 0 10px;
    }

    div {
      color: $primary;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .day {
        font-size: 36px;
        line-height: 36px;
      }

      .time {
        font-weight: 400;
      }
    }
  }
}
