.filepond--drip {
  background: #ffffff !important;
  border: 1px solid #bbbbbb;
  border-radius: 0 !important;
}

.filepond--drop-label {
  color: $black !important;

  label {
    font-size: 16px !important;
  }
}

.filepond--panel-root {
  background: $background !important;
  border-radius: 0 !important;
}

[data-filepond-item-state='processing-complete'] {
  .filepond--item-panel {
    background-color: $primary !important;
  }
}

.filepond--panel-top {
    border-radius: 0 !important;
}

.filepond--panel-bottom {
    border-radius: 0 !important;
}