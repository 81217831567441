.intro-page {
  min-height: 1000px;
  background-image: url('../images/intro/farm.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 1050px;

  @media (max-width: 1600px) {
    background-size: 60vw;
  }

  @media (max-width: 1280px) {
    background-size: 60vw;
  }

  @media (max-width: 800px) {
    background-image: url('../images/intro/farm-mobile.png');
    background-size: contain;
  }

  .header {
    display: flex;
    flex-direction: column;
    
    margin: 60px 100px 0px 100px;

    @media (max-width: 1280px) {
      margin: 30px 50px 0px 30px;
    }

    .logo {
      width: 232px;
      height: 70px;
      margin-bottom: 65px;

      @media (max-width: 1280px) {
        margin-bottom: 30px;
      }

      @media (max-width: 800px) {
        margin-bottom: 85vw;
      }
    }

    .beta-badge {
        position: absolute;
        width: 144px;
        height: 144px;
        right: 40px;
        top: 40px;

        @media (max-width: 1600px) {
            width: 8vw;
            height: 8vw;
            right: 2vw;
            top: 2vw;
        }
    
        @media (max-width: 800px) {
            width: 25vw;
            height: 25vw;
            right: 13vw;
            top: 70vw;
        }
    }

    .hero-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 50px;

      .left {
        align-items: center !important;
        width: 40vw;

        @media (max-width: 800px) {
          width: 100vw;
        }

        .head-1 {
          color: $black;

          @include font-size(45px);

          font-weight: bold;

          @media (max-width: 1600px) {
            @include font-size(32px);
          }

          @media (max-width: 1280px) {
            @include font-size(24px);
          }
        }

        .sep {
          @include media-breakpoint-down(xs) {
            min-width: 50px;
            margin-top: 15px;
          }


          display: inline-block;
          width: 6vw;
          margin: 2vw 0vw 0vw 0vw;
          border-top: 2px solid $primary;
        }

        .sub-1 {
          color: $black;
          font-size: 15px;
          font-weight: 300;

          @media (max-width: 1600px) {
            font-size: 14px;
          }

          @media (max-width: 1280px) {
            font-size: 13px;
          }
        }

        .intro-button {
          @include button($primary);

          margin-top: 50px;
          margin-bottom: 20px;

          @include media-breakpoint-down(md) {
            margin-top: 30px;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 60vw;

        img {
          margin-top: 110px;
          margin-right: 225px;
          height: 420px;

          @media (max-width: 1600px) {
            margin-top: 5vw;
            margin-right: 12vw;
            height: 25vw;
          }

          @media (max-width: 1280px) {
            margin-top: 7vw;
            margin-right: 14vw;
            height: 25vw;
          }
        }

        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
}

.content {
  flex: 1;
  background-color: #ffffff;
  padding-top: 30px;

  a:not(.button) {
    color: $primary;
    font-weight: 600;
    text-decoration: none;

    @include font-size(16px);
    @include media-breakpoint-down(xs) {
      text-decoration: underline;
    }


    &:hover {
      text-decoration: underline;
    }
  }

  .demo-button {
    @include button($primary);
  }

  .row {
    @include media-breakpoint-down(xs) {
      padding: 15px;
    }


    max-width: 1280px;
    margin: auto;
    padding: 20px 20px 60px 20px;

    h2 {
      color: #323232;
    }

    .col,
    .col-6,
    .col-12 {
      @include media-breakpoint-down(xs) {
        padding: 15px 15px 0px 15px;
      }


      padding: 30px 30px 0px 30px;
    }
  }

  .alternate {
    background-color: $background;
  }

  .img-fluid {
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
  }

  .inter {
    @include media-breakpoint-down(xs) {
      padding-bottom: 15px;
    }


    padding-bottom: 30px;

    .row-header {
      max-width: 1280px;
      margin: auto;

      .col,
      .col-6,
      .col-12 {
        @include media-breakpoint-down(xs) {
          padding: 10px 10px 0px 10px;
        }


        padding: 20px 20px 0px 20px;
      }
    }
  }

  .demo {
    background-image: url('../images/intro/demo.svg');
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: top;
  }
}
