.toast-container {
  top: 40% !important;
  left: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    left: unset;
    width: 85%;
  }

  & > div {
    box-shadow: 0px 0px 24px 3px rgba(149, 149, 149, 0.5);
    background-size: 48px;
    background-position: center 40px !important;
    background-repeat: no-repeat;
    border-radius: 3px !important;
    background-color: #ffffff;
    min-height: 188px;
    height: auto;
    margin: 0;

    &.Toastify__toast--success {
      background-image: url(../images/success.svg);
    }

    &.Toastify__toast--error {
      background-image: url(../images/error.svg);
    }

    &.Toastify__toast--warning {
      background-image: url(../images/warning.svg);
    }

    .Toastify__close-button {
      display: none;
      width: 0;
    }
  }
}

.toast-body {
  color: rgb(63, 63, 63);
  font-size: 24px;
  margin: 100px 0 30px !important;
}
