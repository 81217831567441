.react-autosuggest__container {
  padding-top: 24px;
  margin: 0 auto;
  text-align: center;
  max-width: 1092px;
  width: 100%;

  @media (max-width: 1092px) {
    padding: 24px 20px 0;
    margin: 0;
  }
}

.react-autosuggest__input {
  max-width: 1092px;
  width: 100%;
  height: 24px;
  padding: 20px 20px 20px 60px;
  font-size: 28px;
  border: 1px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  color: rgb(63, 63, 63);
  background-color: transparent;
  background-image: url(../images/search.svg);
  background-size: 20px;
  background-position: 0px;
  background-repeat: no-repeat;
  margin-bottom: 1px;

  &:focus {
    border-bottom: 3px solid $primary;
  }

  &::placeholder {
    font-size: 18px;
    color: rgb(151, 151, 151);
  }
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  width: 100%;
  position: relative;
  top: -1px;
  left: 1px;
  border: 1px solid rgb(228, 228, 228);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;
  border-radius: 6px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #efefef;
}

.react-autosuggest__section-container {
  border-top: 1px solid #efefef;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #3f3f3f;
}
