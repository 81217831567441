$footer-gradient-start: $background;
$footer-gradient-end: $background;

footer {
  display: flex;
  flex-direction: row;
  padding: 30px;
  background-image: linear-gradient(to top, $footer-gradient-start, $footer-gradient-end);

  display: flex;
  justify-content: space-between;

  div {
    padding: 10px;
  }

  a, a:hover, a:visited, a:active {
    color: $primary;
    word-break: break-all;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}
