.login-page {
  .roles-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin: 40px;
    height: 700px;

    @include media-breakpoint-down(md) {
      margin: 20px;
      flex-direction: column;
      flex-wrap: unset;
      height: unset;
      align-items: center;
    }


    .role-wrapper {
      width: 50%;
      flex: 0 0 50%;
      display: flex;
      align-items: stretch;
      height: 350px;
      max-width: 600px;

      @include media-breakpoint-down(md) {
        width: auto;
        height: auto;
        flex: unset;
      }


      .role-wrapper-inner {
        display: flex;
        flex-direction: row;
        background: $background;
        margin: 5px;
        padding: 30px;

        @include media-breakpoint-down(sm) {
          padding: 10px;
        }

        .role-loader {
          height: 95px;
        }

        .role-icon {
          width: 92px;
          height: 87px;
          border: 1px solid #dadada;
          box-sizing: border-box;
          margin: 0px 20px 20px 0px;
          padding: 10px 20px 20px 17px;
          border-radius: 46px;

          img {
            width: 56px;
            height: 63px;
          }          
        }

        .role-name {
          @include font-size(28px);
          @include media-breakpoint-down(xs) {
            @include font-size(18px);
          }


          color: $black;
          font-family: $font-family-sans-serif;
          font-weight: 300;
          margin-bottom: 20px;
        }

        .role-description {
          padding-bottom: 20px;
        }

        .role-cta {
          margin: 20px 0;

          .button {
            outline: none;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:first-child {
          flex: 0 0 100%;
          width: 50%;
          height: 700px;

          .role-wrapper-inner {
            flex-direction: column;
            width: 100%;

            .role-icon {
              width: 184px;
              height: 174px;
              padding: 20px 20px 20px 35px;
              border-radius: 100px;

              img {
                width: 104px;
                height: 122px;
              }
            }

            .role-description {
              max-width: 500px;
            }
          }
        }
      }
    }
  }

  .cta-wrapper {
    @include cta-wrapper;
  }
}
