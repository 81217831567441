.md-tabs {
  margin-bottom: 30px;
  padding: 0 !important;
  @include media-breakpoint-down(sm) {
    padding: 16px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.md-tab-label {
  font-size: 18px;
  font-weight: 700;
  color: $black;
}

.md-tab-panel#item-details-panel-1 {
  overflow-y: auto;
}

.md-tab {
  margin: 0 5px;
  border-radius: 30px;
  background-color: $background;
  height: 55px;
  text-transform: unset;
  color: $black;
  width: 200px;

  @include media-breakpoint-down(sm) {
    margin: 5px 0;
  }
}

.md-tab--active {
  .md-ink-container {
    background: $primary;
  }
  .md-tab-label {
    color: $white;
    z-index: 2;
  }
}

.md-tab-indicator {
  background: none;
}

.tabs-wrapper {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
