.partners {
  padding: 40px 100px 40px 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    padding: 20px;
  }

  img {
    margin: 20px;
    max-height: 40px;
    max-width: 180px;
  }

  &.dark {
    background-color: #f9f9f9;
  }
}
