.detail-section-wrapper {
  border-top: 1px solid rgb(201, 201, 201);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 100px;

  .details-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 34%;
    border-bottom: 1px solid rgb(201, 201, 201);
    padding: 10px;
    margin: 0;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @media (min-width: 769px) {
      &:not(:nth-child(3n)) {
        border-right: 1px solid rgb(201, 201, 201);
        width: 33%;
      }
    }

    & > span {
      color: rgb(63, 63, 63);
      margin: 0 auto;

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    .label {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0px;
    }

    .value {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .caption {
      font-size: 18px;
      font-weight: 700;
    }

    .value2 {
      font-size: 18px;
      font-weight: 400;
      margin-left: 10px;
    }
  }
}
