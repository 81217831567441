nav {
  line-height: 1;

  ul {
    @include media-breakpoint-down(xs) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      padding: 13px 15px 13px 0;
    }

    margin: 0;
    padding: 13px 30px 13px 40px;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    text-align: right;

    li {
      @include media-breakpoint-down(sm) {
        margin: 0px 0px 0px 18px;
      }

      display: inline-block;
      margin: 0px 0px 0px 30px;

      #{$a-tags} {
        @include font-size(12px);

        height: 17px;
        color: var(--white);
        font-family: $font-family-sans-serif;
        font-weight: 600;
        text-decoration: none;
      }
    }
  }
}
