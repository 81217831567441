.details-page {
  .secondary {
    @include button(#ffffff);

    border: $primary 1px solid;
    color: $primary;
    margin-right: 20px;
  }

  .loader-wrapper {
    height: 130px;
    width: 100%;
    margin: 30px auto;
  }

  .details-wrapper {
    max-width: 1092px;
    width: 90%;
    display: flex;
    margin: 0 auto;

    .md-block-centered {
      width: 100%;
    }

    .route-cta-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 30px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: baseline;
        margin: 20px;
      }


      @media (max-width: 768px) {
        margin: 20px;
      }

      .ca-title {
        display: none;

        @include media-breakpoint-down(md) {
          display: block;
          font-size: 22px;
          font-weight: 700;
          color: rgb(63, 63, 63);
          margin: 25px 0 20px;
          margin-right: 20px;
        }
      }

      .details-page-button-container {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .details-page-button {
          @include button;

          width: auto;
          font-size: 18px;
          font-weight: 700;
          padding: 26px;
          line-height: 2px;
          border-radius: 45px;
          text-transform: unset;
          margin: 0 5px 0 5px;

          @include media-breakpoint-down(md) {
            white-space: normal;
            line-height: unset;
            padding: 12px 26px;
            margin-bottom: 10px;;
            height: 100%;
          }
        }
      }
    }
  }
}
