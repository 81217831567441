@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,700);
.md-display-4 {
  letter-spacing: -.1px; }

.md-display-3 {
  letter-spacing: -.05px; }

.md-display-2, h1, .md-display-1, h2, .md-headline {
  letter-spacing: 0; }

h3, .md-title {
  letter-spacing: .5px; }

h4, .md-subheading-2, h5, .md-subheading-1, p, .md-body-1, h6, .md-body-2 {
  letter-spacing: .1px; }

caption, .md-caption {
  letter-spacing: .2px; }

h1, .md-display-1,
.md-display-2,
.md-display-3,
.md-display-4,
h2,
.md-headline,
h3,
.md-title,
h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
h6,
.md-body-2,
caption,
.md-caption {
  margin: 0; }

h1, .md-display-1,
.md-display-2,
.md-display-3,
.md-display-4,
h2,
.md-headline,
h3,
.md-title {
  margin-bottom: 14px; }


h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
h6,
.md-body-2,
caption,
.md-caption {
  margin-bottom: 10px; }


.md-display-3,
.md-display-4,
h3,
.md-title {
  white-space: nowrap; }

h1, .md-display-1,
.md-display-2,
.md-display-3,
h2,
.md-headline,
h5,
.md-subheading-1,
h4,
.md-subheading-2,
p,
.md-body-1,
caption,
.md-caption {
  font-weight: 400; }


h3,
.md-title,
h6,
.md-body-2 {
  font-weight: 500; }


.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 128px; }


.md-display-3 {
  font-size: 56px;
  line-height: 84px; }


.md-display-2 {
  font-size: 45px;
  line-height: 48px; }

h1, .md-display-1 {
  font-size: 34px;
  line-height: 40px; }


h2,
.md-headline {
  font-size: 24px;
  line-height: 32px; }


h3,
.md-title {
  font-size: 20px;
  line-height: 28px; }


h4,
.md-subheading-2 {
  line-height: 28px; }


h5,
.md-subheading-1 {
  line-height: 24px; }


p,
.md-body-1 {
  line-height: 20px; }


h6,
.md-body-2 {
  line-height: 24px; }


caption,
.md-caption {
  font-size: 12px; }

.md-text-left, .md-picker-control {
  text-align: left; }

.md-text-center, .md-calendar-date {
  text-align: center; }

.md-text-right {
  text-align: right; }

.md-text-justify {
  text-align: justify; }

.md-text-capitalize {
  text-transform: capitalize; }

.md-text-lowercalse {
  text-transform: lowercase; }

.md-text-uppercase {
  text-transform: uppercase; }

.md-text-nowrap {
  white-space: nowrap; }

.md-text-no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.md-font-light {
  font-weight: 300; }

.md-font-regular {
  font-weight: 400; }

.md-font-medium, .md-btn .md-icon-text, .md-clock-time-value {
  font-weight: 500; }

.md-font-semibold {
  font-weight: 600; }

.md-font-bold {
  font-weight: 700; }

.md-transition--sharp {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1); }

.md-transition--standard {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.md-transition--acceleration, .md-drop-down-leave.md-drop-down-leave-active {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }


.md-transition--decceleration,
.md-transition--deceleration,
.md-drop-down-enter.md-drop-down-enter-active {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-calendar-date, .md-inline-block {
  display: inline-block;
  vertical-align: bottom; }

.md-full-width {
  width: 100%; }

.md-text-container, .md-bottom-nav .md-icon, .md-block-centered {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.md-grid.md-grid--no-spacing > .md-cell.md-cell--right, .md-cell--right, .md-collapser--card, .md-divider--expand-from-right::after {
  margin-left: auto; }

.md-grid.md-grid--no-spacing > .md-cell.md-cell--right, .md-cell--right, .md-collapser--card, .md-divider--expand-from-right::after {
  margin-left: auto; }

.md-expansion-panel-list, .md-list-unstyled, .md-list, .md-tabs {
  list-style: none;
  margin: 0;
  padding-left: 0; }

.md-media img, .md-media iframe, .md-media svg, .md-media video, .md-media embed, .md-media object {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

@media screen and (min-width: 320px) {
  .md-subheading-2,
  .md-subheading-1 {
    font-size: 16px; }
  .md-body-2,
  .md-body-1 {
    font-size: 14px; }
  h5,
  h4 {
    font-size: 16px; }
  h6,
  p {
    font-size: 14px; } }

@media screen and (min-width: 1025px) {
  .md-subheading-2,
  .md-subheading-1 {
    font-size: 15px; }
  .md-body-2,
  .md-body-1 {
    font-size: 13px; }
  h5,
  h4 {
    font-size: 15px; }
  h6,
  p {
    font-size: 13px; } }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

html {
  background: #fafafa;
  font-size: 14px;
  min-width: 100%; }

body {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.42857;
  text-rendering: optimizeLegibility; }


h2,
.md-headline,
h3,
.md-title,
h4,
.md-subheading-2,
h5,
.md-subheading-1,
h6,
.md-body-2,
p,
.md-body-1 {
  color: rgba(0, 0, 0, 0.87); }


.md-display-4,
.md-display-3,
.md-display-2, h1, .md-display-1,
caption,
.md-caption {
  color: rgba(0, 0, 0, 0.54); }

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: "Roboto", sans-serif; }

.md-text-container {
  max-width: 640px;
  width: 100%; }
  .md-text-container.md-text-container.md-cell {
    margin-left: auto;
    margin-right: auto; }

.md-fake-btn {
  background: transparent;
  position: relative; }
  .md-fake-btn--no-outline {
    outline-style: none; }

.md-no-scroll.md-no-scroll {
  overflow: hidden;
  position: fixed; }

.md-pointer--hover:hover {
  cursor: pointer; }

.md-pointer--none {
  pointer-events: none; }

.md-content-jump {
  left: -1000px;
  position: absolute;
  top: -1000px; }
  .md-content-jump:active, .md-content-jump:focus {
    left: 0;
    top: 0; }

.md-grid {
  -ms-flex-align: stretch;
      align-items: stretch;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  margin: 0 auto; }
  .md-grid.md-grid--no-spacing {
    padding: 0; }
    .md-grid.md-grid--no-spacing > .md-cell {
      margin: 0; }
  .md-grid--stacked {
    -ms-flex-direction: column;
        flex-direction: column; }

.md-cell--top {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.md-cell--middle {
  -ms-flex-item-align: center;
      align-self: center; }

.md-cell--center {
  margin-left: auto;
  margin-right: auto; }

.md-cell--bottom {
  -ms-flex-item-align: end;
      align-self: flex-end; }

.md-cell--stretch {
  -ms-flex-item-align: stretch;
      align-self: stretch; }

@media (max-width: 599px) {
  .md-grid {
    padding: 8px; }
  .md-cell {
    width: calc(100% - 16px);
    margin: 8px; }
    .md-grid.md-grid--no-spacing > .md-cell {
      width: 100%; }
  .md-cell--phone-hidden {
    display: none !important; }
  .md-cell--order-1,
  .md-cell--order-1-phone.md-cell--order-1-phone {
    -ms-flex-order: 1;
        order: 1; }
  .md-cell--order-2,
  .md-cell--order-2-phone.md-cell--order-2-phone {
    -ms-flex-order: 2;
        order: 2; }
  .md-cell--order-3,
  .md-cell--order-3-phone.md-cell--order-3-phone {
    -ms-flex-order: 3;
        order: 3; }
  .md-cell--order-4,
  .md-cell--order-4-phone.md-cell--order-4-phone {
    -ms-flex-order: 4;
        order: 4; }
  .md-cell--order-5,
  .md-cell--order-5-phone.md-cell--order-5-phone {
    -ms-flex-order: 5;
        order: 5; }
  .md-cell--order-6,
  .md-cell--order-6-phone.md-cell--order-6-phone {
    -ms-flex-order: 6;
        order: 6; }
  .md-cell--order-7,
  .md-cell--order-7-phone.md-cell--order-7-phone {
    -ms-flex-order: 7;
        order: 7; }
  .md-cell--order-8,
  .md-cell--order-8-phone.md-cell--order-8-phone {
    -ms-flex-order: 8;
        order: 8; }
  .md-cell--order-9,
  .md-cell--order-9-phone.md-cell--order-9-phone {
    -ms-flex-order: 9;
        order: 9; }
  .md-cell--order-10,
  .md-cell--order-10-phone.md-cell--order-10-phone {
    -ms-flex-order: 10;
        order: 10; }
  .md-cell--order-11,
  .md-cell--order-11-phone.md-cell--order-11-phone {
    -ms-flex-order: 11;
        order: 11; }
  .md-cell--order-12,
  .md-cell--order-12-phone.md-cell--order-12-phone {
    -ms-flex-order: 12;
        order: 12; }
  .md-cell--1,
  .md-cell--1-phone.md-cell--1-phone {
    width: calc(25% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--1, .md-grid.md-grid--no-spacing >
    .md-cell--1-phone.md-cell--1-phone {
      width: 25%; }
  .md-cell--2,
  .md-cell--2-phone.md-cell--2-phone {
    width: calc(50% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--2, .md-grid.md-grid--no-spacing >
    .md-cell--2-phone.md-cell--2-phone {
      width: 50%; }
  .md-cell--3,
  .md-cell--3-phone.md-cell--3-phone {
    width: calc(75% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--3, .md-grid.md-grid--no-spacing >
    .md-cell--3-phone.md-cell--3-phone {
      width: 75%; }
  .md-cell--4,
  .md-cell--4-phone.md-cell--4-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--4, .md-grid.md-grid--no-spacing >
    .md-cell--4-phone.md-cell--4-phone {
      width: 100%; }
  .md-cell--5,
  .md-cell--5-phone.md-cell--5-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--5, .md-grid.md-grid--no-spacing >
    .md-cell--5-phone.md-cell--5-phone {
      width: 100%; }
  .md-cell--6,
  .md-cell--6-phone.md-cell--6-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--6, .md-grid.md-grid--no-spacing >
    .md-cell--6-phone.md-cell--6-phone {
      width: 100%; }
  .md-cell--7,
  .md-cell--7-phone.md-cell--7-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--7, .md-grid.md-grid--no-spacing >
    .md-cell--7-phone.md-cell--7-phone {
      width: 100%; }
  .md-cell--8,
  .md-cell--8-phone.md-cell--8-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--8, .md-grid.md-grid--no-spacing >
    .md-cell--8-phone.md-cell--8-phone {
      width: 100%; }
  .md-cell--9,
  .md-cell--9-phone.md-cell--9-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--9, .md-grid.md-grid--no-spacing >
    .md-cell--9-phone.md-cell--9-phone {
      width: 100%; }
  .md-cell--10,
  .md-cell--10-phone.md-cell--10-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--10, .md-grid.md-grid--no-spacing >
    .md-cell--10-phone.md-cell--10-phone {
      width: 100%; }
  .md-cell--11,
  .md-cell--11-phone.md-cell--11-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--11, .md-grid.md-grid--no-spacing >
    .md-cell--11-phone.md-cell--11-phone {
      width: 100%; }
  .md-cell--12,
  .md-cell--12-phone.md-cell--12-phone {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--12, .md-grid.md-grid--no-spacing >
    .md-cell--12-phone.md-cell--12-phone {
      width: 100%; }
  .md-cell--1-offset,
  .md-cell--1-phone-offset.md-cell--1-phone-offset {
    margin-left: calc(25% + 8px); }
    .md-grid--no-spacing > .md-cell--1-offset, .md-grid--no-spacing >
    .md-cell--1-phone-offset.md-cell--1-phone-offset {
      margin-left: 25%; }
  .md-cell--2-offset,
  .md-cell--2-phone-offset.md-cell--2-phone-offset {
    margin-left: calc(50% + 8px); }
    .md-grid--no-spacing > .md-cell--2-offset, .md-grid--no-spacing >
    .md-cell--2-phone-offset.md-cell--2-phone-offset {
      margin-left: 50%; }
  .md-cell--3-offset,
  .md-cell--3-phone-offset.md-cell--3-phone-offset {
    margin-left: calc(75% + 8px); }
    .md-grid--no-spacing > .md-cell--3-offset, .md-grid--no-spacing >
    .md-cell--3-phone-offset.md-cell--3-phone-offset {
      margin-left: 75%; } }

@media (min-width: 600px) and (max-width: 839px) {
  .md-grid {
    padding: 8px; }
  .md-cell {
    width: calc(50% - 16px);
    margin: 8px; }
    .md-grid.md-grid--no-spacing > .md-cell {
      width: 50%; }
  .md-cell--tablet-hidden {
    display: none !important; }
  .md-cell--order-1,
  .md-cell--order-1-tablet.md-cell--order-1-tablet {
    -ms-flex-order: 1;
        order: 1; }
  .md-cell--order-2,
  .md-cell--order-2-tablet.md-cell--order-2-tablet {
    -ms-flex-order: 2;
        order: 2; }
  .md-cell--order-3,
  .md-cell--order-3-tablet.md-cell--order-3-tablet {
    -ms-flex-order: 3;
        order: 3; }
  .md-cell--order-4,
  .md-cell--order-4-tablet.md-cell--order-4-tablet {
    -ms-flex-order: 4;
        order: 4; }
  .md-cell--order-5,
  .md-cell--order-5-tablet.md-cell--order-5-tablet {
    -ms-flex-order: 5;
        order: 5; }
  .md-cell--order-6,
  .md-cell--order-6-tablet.md-cell--order-6-tablet {
    -ms-flex-order: 6;
        order: 6; }
  .md-cell--order-7,
  .md-cell--order-7-tablet.md-cell--order-7-tablet {
    -ms-flex-order: 7;
        order: 7; }
  .md-cell--order-8,
  .md-cell--order-8-tablet.md-cell--order-8-tablet {
    -ms-flex-order: 8;
        order: 8; }
  .md-cell--order-9,
  .md-cell--order-9-tablet.md-cell--order-9-tablet {
    -ms-flex-order: 9;
        order: 9; }
  .md-cell--order-10,
  .md-cell--order-10-tablet.md-cell--order-10-tablet {
    -ms-flex-order: 10;
        order: 10; }
  .md-cell--order-11,
  .md-cell--order-11-tablet.md-cell--order-11-tablet {
    -ms-flex-order: 11;
        order: 11; }
  .md-cell--order-12,
  .md-cell--order-12-tablet.md-cell--order-12-tablet {
    -ms-flex-order: 12;
        order: 12; }
  .md-cell--1,
  .md-cell--1-tablet.md-cell--1-tablet {
    width: calc(12.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--1, .md-grid.md-grid--no-spacing >
    .md-cell--1-tablet.md-cell--1-tablet {
      width: 12.5%; }
  .md-cell--2,
  .md-cell--2-tablet.md-cell--2-tablet {
    width: calc(25% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--2, .md-grid.md-grid--no-spacing >
    .md-cell--2-tablet.md-cell--2-tablet {
      width: 25%; }
  .md-cell--3,
  .md-cell--3-tablet.md-cell--3-tablet {
    width: calc(37.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--3, .md-grid.md-grid--no-spacing >
    .md-cell--3-tablet.md-cell--3-tablet {
      width: 37.5%; }
  .md-cell--4,
  .md-cell--4-tablet.md-cell--4-tablet {
    width: calc(50% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--4, .md-grid.md-grid--no-spacing >
    .md-cell--4-tablet.md-cell--4-tablet {
      width: 50%; }
  .md-cell--5,
  .md-cell--5-tablet.md-cell--5-tablet {
    width: calc(62.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--5, .md-grid.md-grid--no-spacing >
    .md-cell--5-tablet.md-cell--5-tablet {
      width: 62.5%; }
  .md-cell--6,
  .md-cell--6-tablet.md-cell--6-tablet {
    width: calc(75% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--6, .md-grid.md-grid--no-spacing >
    .md-cell--6-tablet.md-cell--6-tablet {
      width: 75%; }
  .md-cell--7,
  .md-cell--7-tablet.md-cell--7-tablet {
    width: calc(87.5% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--7, .md-grid.md-grid--no-spacing >
    .md-cell--7-tablet.md-cell--7-tablet {
      width: 87.5%; }
  .md-cell--8,
  .md-cell--8-tablet.md-cell--8-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--8, .md-grid.md-grid--no-spacing >
    .md-cell--8-tablet.md-cell--8-tablet {
      width: 100%; }
  .md-cell--9,
  .md-cell--9-tablet.md-cell--9-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--9, .md-grid.md-grid--no-spacing >
    .md-cell--9-tablet.md-cell--9-tablet {
      width: 100%; }
  .md-cell--10,
  .md-cell--10-tablet.md-cell--10-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--10, .md-grid.md-grid--no-spacing >
    .md-cell--10-tablet.md-cell--10-tablet {
      width: 100%; }
  .md-cell--11,
  .md-cell--11-tablet.md-cell--11-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--11, .md-grid.md-grid--no-spacing >
    .md-cell--11-tablet.md-cell--11-tablet {
      width: 100%; }
  .md-cell--12,
  .md-cell--12-tablet.md-cell--12-tablet {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--12, .md-grid.md-grid--no-spacing >
    .md-cell--12-tablet.md-cell--12-tablet {
      width: 100%; }
  .md-cell--1-offset,
  .md-cell--1-tablet-offset.md-cell--1-tablet-offset {
    margin-left: calc(12.5% + 8px); }
    .md-grid--no-spacing > .md-cell--1-offset, .md-grid--no-spacing >
    .md-cell--1-tablet-offset.md-cell--1-tablet-offset {
      margin-left: 12.5%; }
  .md-cell--2-offset,
  .md-cell--2-tablet-offset.md-cell--2-tablet-offset {
    margin-left: calc(25% + 8px); }
    .md-grid--no-spacing > .md-cell--2-offset, .md-grid--no-spacing >
    .md-cell--2-tablet-offset.md-cell--2-tablet-offset {
      margin-left: 25%; }
  .md-cell--3-offset,
  .md-cell--3-tablet-offset.md-cell--3-tablet-offset {
    margin-left: calc(37.5% + 8px); }
    .md-grid--no-spacing > .md-cell--3-offset, .md-grid--no-spacing >
    .md-cell--3-tablet-offset.md-cell--3-tablet-offset {
      margin-left: 37.5%; }
  .md-cell--4-offset,
  .md-cell--4-tablet-offset.md-cell--4-tablet-offset {
    margin-left: calc(50% + 8px); }
    .md-grid--no-spacing > .md-cell--4-offset, .md-grid--no-spacing >
    .md-cell--4-tablet-offset.md-cell--4-tablet-offset {
      margin-left: 50%; }
  .md-cell--5-offset,
  .md-cell--5-tablet-offset.md-cell--5-tablet-offset {
    margin-left: calc(62.5% + 8px); }
    .md-grid--no-spacing > .md-cell--5-offset, .md-grid--no-spacing >
    .md-cell--5-tablet-offset.md-cell--5-tablet-offset {
      margin-left: 62.5%; }
  .md-cell--6-offset,
  .md-cell--6-tablet-offset.md-cell--6-tablet-offset {
    margin-left: calc(75% + 8px); }
    .md-grid--no-spacing > .md-cell--6-offset, .md-grid--no-spacing >
    .md-cell--6-tablet-offset.md-cell--6-tablet-offset {
      margin-left: 75%; }
  .md-cell--7-offset,
  .md-cell--7-tablet-offset.md-cell--7-tablet-offset {
    margin-left: calc(87.5% + 8px); }
    .md-grid--no-spacing > .md-cell--7-offset, .md-grid--no-spacing >
    .md-cell--7-tablet-offset.md-cell--7-tablet-offset {
      margin-left: 87.5%; } }

@media (min-width: 840px) {
  .md-grid {
    padding: 8px; }
  .md-cell {
    width: calc(33.33333% - 16px);
    margin: 8px; }
    .md-grid.md-grid--no-spacing > .md-cell {
      width: 33.33333%; }
  .md-cell--desktop-hidden {
    display: none !important; }
  .md-cell--order-1,
  .md-cell--order-1-desktop.md-cell--order-1-desktop {
    -ms-flex-order: 1;
        order: 1; }
  .md-cell--order-2,
  .md-cell--order-2-desktop.md-cell--order-2-desktop {
    -ms-flex-order: 2;
        order: 2; }
  .md-cell--order-3,
  .md-cell--order-3-desktop.md-cell--order-3-desktop {
    -ms-flex-order: 3;
        order: 3; }
  .md-cell--order-4,
  .md-cell--order-4-desktop.md-cell--order-4-desktop {
    -ms-flex-order: 4;
        order: 4; }
  .md-cell--order-5,
  .md-cell--order-5-desktop.md-cell--order-5-desktop {
    -ms-flex-order: 5;
        order: 5; }
  .md-cell--order-6,
  .md-cell--order-6-desktop.md-cell--order-6-desktop {
    -ms-flex-order: 6;
        order: 6; }
  .md-cell--order-7,
  .md-cell--order-7-desktop.md-cell--order-7-desktop {
    -ms-flex-order: 7;
        order: 7; }
  .md-cell--order-8,
  .md-cell--order-8-desktop.md-cell--order-8-desktop {
    -ms-flex-order: 8;
        order: 8; }
  .md-cell--order-9,
  .md-cell--order-9-desktop.md-cell--order-9-desktop {
    -ms-flex-order: 9;
        order: 9; }
  .md-cell--order-10,
  .md-cell--order-10-desktop.md-cell--order-10-desktop {
    -ms-flex-order: 10;
        order: 10; }
  .md-cell--order-11,
  .md-cell--order-11-desktop.md-cell--order-11-desktop {
    -ms-flex-order: 11;
        order: 11; }
  .md-cell--order-12,
  .md-cell--order-12-desktop.md-cell--order-12-desktop {
    -ms-flex-order: 12;
        order: 12; }
  .md-cell--1,
  .md-cell--1-desktop.md-cell--1-desktop {
    width: calc(8.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--1, .md-grid.md-grid--no-spacing >
    .md-cell--1-desktop.md-cell--1-desktop {
      width: 8.33333%; }
  .md-cell--2,
  .md-cell--2-desktop.md-cell--2-desktop {
    width: calc(16.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--2, .md-grid.md-grid--no-spacing >
    .md-cell--2-desktop.md-cell--2-desktop {
      width: 16.66667%; }
  .md-cell--3,
  .md-cell--3-desktop.md-cell--3-desktop {
    width: calc(25% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--3, .md-grid.md-grid--no-spacing >
    .md-cell--3-desktop.md-cell--3-desktop {
      width: 25%; }
  .md-cell--4,
  .md-cell--4-desktop.md-cell--4-desktop {
    width: calc(33.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--4, .md-grid.md-grid--no-spacing >
    .md-cell--4-desktop.md-cell--4-desktop {
      width: 33.33333%; }
  .md-cell--5,
  .md-cell--5-desktop.md-cell--5-desktop {
    width: calc(41.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--5, .md-grid.md-grid--no-spacing >
    .md-cell--5-desktop.md-cell--5-desktop {
      width: 41.66667%; }
  .md-cell--6,
  .md-cell--6-desktop.md-cell--6-desktop {
    width: calc(50% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--6, .md-grid.md-grid--no-spacing >
    .md-cell--6-desktop.md-cell--6-desktop {
      width: 50%; }
  .md-cell--7,
  .md-cell--7-desktop.md-cell--7-desktop {
    width: calc(58.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--7, .md-grid.md-grid--no-spacing >
    .md-cell--7-desktop.md-cell--7-desktop {
      width: 58.33333%; }
  .md-cell--8,
  .md-cell--8-desktop.md-cell--8-desktop {
    width: calc(66.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--8, .md-grid.md-grid--no-spacing >
    .md-cell--8-desktop.md-cell--8-desktop {
      width: 66.66667%; }
  .md-cell--9,
  .md-cell--9-desktop.md-cell--9-desktop {
    width: calc(75% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--9, .md-grid.md-grid--no-spacing >
    .md-cell--9-desktop.md-cell--9-desktop {
      width: 75%; }
  .md-cell--10,
  .md-cell--10-desktop.md-cell--10-desktop {
    width: calc(83.33333% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--10, .md-grid.md-grid--no-spacing >
    .md-cell--10-desktop.md-cell--10-desktop {
      width: 83.33333%; }
  .md-cell--11,
  .md-cell--11-desktop.md-cell--11-desktop {
    width: calc(91.66667% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--11, .md-grid.md-grid--no-spacing >
    .md-cell--11-desktop.md-cell--11-desktop {
      width: 91.66667%; }
  .md-cell--12,
  .md-cell--12-desktop.md-cell--12-desktop {
    width: calc(100% - 16px); }
    .md-grid.md-grid--no-spacing > .md-cell--12, .md-grid.md-grid--no-spacing >
    .md-cell--12-desktop.md-cell--12-desktop {
      width: 100%; }
  .md-cell--1-offset,
  .md-cell--1-desktop-offset.md-cell--1-desktop-offset {
    margin-left: calc(8.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--1-offset, .md-grid--no-spacing >
    .md-cell--1-desktop-offset.md-cell--1-desktop-offset {
      margin-left: 8.33333%; }
  .md-cell--2-offset,
  .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
    margin-left: calc(16.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--2-offset, .md-grid--no-spacing >
    .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
      margin-left: 16.66667%; }
  .md-cell--3-offset,
  .md-cell--3-desktop-offset.md-cell--3-desktop-offset {
    margin-left: calc(25% + 8px); }
    .md-grid--no-spacing > .md-cell--3-offset, .md-grid--no-spacing >
    .md-cell--3-desktop-offset.md-cell--3-desktop-offset {
      margin-left: 25%; }
  .md-cell--4-offset,
  .md-cell--4-desktop-offset.md-cell--4-desktop-offset {
    margin-left: calc(33.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--4-offset, .md-grid--no-spacing >
    .md-cell--4-desktop-offset.md-cell--4-desktop-offset {
      margin-left: 33.33333%; }
  .md-cell--5-offset,
  .md-cell--5-desktop-offset.md-cell--5-desktop-offset {
    margin-left: calc(41.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--5-offset, .md-grid--no-spacing >
    .md-cell--5-desktop-offset.md-cell--5-desktop-offset {
      margin-left: 41.66667%; }
  .md-cell--6-offset,
  .md-cell--6-desktop-offset.md-cell--6-desktop-offset {
    margin-left: calc(50% + 8px); }
    .md-grid--no-spacing > .md-cell--6-offset, .md-grid--no-spacing >
    .md-cell--6-desktop-offset.md-cell--6-desktop-offset {
      margin-left: 50%; }
  .md-cell--7-offset,
  .md-cell--7-desktop-offset.md-cell--7-desktop-offset {
    margin-left: calc(58.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--7-offset, .md-grid--no-spacing >
    .md-cell--7-desktop-offset.md-cell--7-desktop-offset {
      margin-left: 58.33333%; }
  .md-cell--8-offset,
  .md-cell--8-desktop-offset.md-cell--8-desktop-offset {
    margin-left: calc(66.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--8-offset, .md-grid--no-spacing >
    .md-cell--8-desktop-offset.md-cell--8-desktop-offset {
      margin-left: 66.66667%; }
  .md-cell--9-offset,
  .md-cell--9-desktop-offset.md-cell--9-desktop-offset {
    margin-left: calc(75% + 8px); }
    .md-grid--no-spacing > .md-cell--9-offset, .md-grid--no-spacing >
    .md-cell--9-desktop-offset.md-cell--9-desktop-offset {
      margin-left: 75%; }
  .md-cell--10-offset,
  .md-cell--10-desktop-offset.md-cell--10-desktop-offset {
    margin-left: calc(83.33333% + 8px); }
    .md-grid--no-spacing > .md-cell--10-offset, .md-grid--no-spacing >
    .md-cell--10-desktop-offset.md-cell--10-desktop-offset {
      margin-left: 83.33333%; }
  .md-cell--11-offset,
  .md-cell--11-desktop-offset.md-cell--11-desktop-offset {
    margin-left: calc(91.66667% + 8px); }
    .md-grid--no-spacing > .md-cell--11-offset, .md-grid--no-spacing >
    .md-cell--11-desktop-offset.md-cell--11-desktop-offset {
      margin-left: 91.66667%; } }

.md-autocomplete-container {
  position: relative; }

.md-autocomplete-suggestion {
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.15;
  overflow: hidden;
  position: absolute;
  top: 12px;
  white-space: nowrap; }

@media screen and (min-width: 320px) {
  .md-autocomplete-suggestion {
    font-size: 16px; }
    .md-autocomplete-suggestion--floating {
      top: 37px; }
    .md-autocomplete-suggestion--block {
      top: 18px; } }

@media screen and (min-width: 1025px) {
  .md-autocomplete-suggestion {
    font-size: 13px; }
    .md-autocomplete-suggestion--floating {
      top: 33px; }
    .md-autocomplete-suggestion--block {
      top: 15px; } }

.md-avatar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  text-align: center;
  width: 40px; }
  .md-avatar .md-icon {
    color: inherit; }
  .md-avatar--icon-sized {
    height: 24px;
    width: 24px; }

.md-avatar-img {
  height: 100%;
  width: auto; }

.md-avatar-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%; }

.md-avatar--default {
  background: #616161;
  color: #f5f5f5; }

@media screen and (min-width: 1025px) {
  .md-avatar-content {
    font-size: 20px; }
  .md-avatar--icon-sized {
    height: 20px;
    width: 20px; } }

.md-avatar--red {
  background: #d50000;
  color: #ffebee; }

.md-avatar--pink {
  background: #d81b60;
  color: #fff; }

.md-avatar--purple {
  background: #7b1fa2;
  color: #e1bee7; }

.md-avatar--deep-purple {
  background: #311b92;
  color: #d1c4e9; }

.md-avatar--indigo {
  background: #3949ab;
  color: #c5cae9; }

.md-avatar--blue {
  background: #2962ff;
  color: #fff; }

.md-avatar--light-blue {
  background: #4fc3f7;
  color: #311b92; }

.md-avatar--cyan {
  background: #26c6da;
  color: #004d40; }

.md-avatar--teal {
  background: #1de9b6;
  color: #004d40; }

.md-avatar--green {
  background: #2e7d32;
  color: #e8f5e9; }

.md-avatar--light-green {
  background: #aed581;
  color: #1b5e20; }

.md-avatar--lime {
  background: #d4e157;
  color: #00695c; }

.md-avatar--yellow {
  background: #ff0;
  color: #795548; }

.md-avatar--amber {
  background: #ffca28;
  color: #4e342e; }

.md-avatar--orange {
  background: #fb8c00;
  color: #212121; }

.md-avatar--deep-orange {
  background: #ff3d00;
  color: #212121; }

.md-avatar--brown {
  background: #795548;
  color: #efebe9; }

.md-avatar--grey {
  background: #616161;
  color: #f5f5f5; }

.md-avatar--blue-grey {
  background: #455a64;
  color: #eceff1; }

.md-badge-container {
  position: relative; }

.md-badge {
  position: absolute;
  right: -8px;
  top: -8px; }
  .md-badge--circular {
    -ms-flex-align: center;
        align-items: center;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    font-size: 10px;
    height: 24px;
    -ms-flex-pack: center;
        justify-content: center;
    width: 24px; }
  .md-badge--default {
    background: rgba(0, 0, 0, 0.2); }

.md-bottom-navigation {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 11; }
  .md-bottom-navigation--shifting {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: background;
    transition-property: background; }
  .md-bottom-navigation--dynamic {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: background, -webkit-transform;
    transition-property: background, -webkit-transform;
    transition-property: background, transform;
    transition-property: background, transform, -webkit-transform; }
    .md-bottom-navigation--dynamic-inactive {
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0); }

.md-bottom-navigation-offset {
  padding-bottom: 56px; }

.md-bottom-nav {
  color: inherit;
  display: block;
  -ms-flex-positive: 1;
      flex-grow: 1;
  font-size: 12px;
  height: 56px;
  max-width: 168px;
  padding: 8px 12px 10px;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .md-bottom-nav--active {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    font-size: 14px;
    padding-top: 6px; }
  .md-bottom-nav--fixed {
    min-width: 80px; }
  .md-bottom-nav--shifting {
    min-width: 96px;
    position: static;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: max-width;
    transition-property: max-width; }
    .md-bottom-nav--shifting-inactive {
      max-width: 96px;
      min-width: 56px;
      padding-top: 16px; }
    .md-bottom-nav--shifting .md-ink-container {
      overflow: visible; }
    .md-bottom-nav--shifting .md-ink {
      background: rgba(255, 255, 255, 0.12); }
  .md-bottom-nav-label {
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: color, font-size;
    transition-property: color, font-size; }
    .md-bottom-nav-label--shifting-inactive {
      max-width: 32px;
      overflow: hidden;
      white-space: nowrap; }

a.md-btn {
  text-decoration: none; }

.md-btn {
  background: transparent;
  border: 0;
  position: relative;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, color;
  transition-property: background, color; }
  .md-btn[disabled] * {
    pointer-events: none; }
  .md-btn--tooltip {
    overflow: visible; }
  .md-btn:focus {
    outline-style: none; }
  .md-btn .md-icon-separator {
    height: 100%; }

.md-btn--hover {
  background: rgba(153, 153, 153, 0.12); }

.md-btn--color-primary-active {
  background: rgba(40, 153, 63, 0.12); }

.md-btn--color-secondary-active {
  background: rgba(40, 153, 63, 0.12); }

.md-btn--text {
  border-radius: 2px;
  font-weight: 500;
  min-width: 88px;
  padding: 8px 16px;
  text-transform: uppercase; }

.md-btn--raised {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  -webkit-transition: background 0.15s, color 0.15s, -webkit-box-shadow 0.3s;
  transition: background 0.15s, color 0.15s, -webkit-box-shadow 0.3s;
  transition: background 0.15s, box-shadow 0.3s, color 0.15s;
  transition: background 0.15s, box-shadow 0.3s, color 0.15s, -webkit-box-shadow 0.3s; }

.md-btn--raised-disabled {
  background: rgba(0, 0, 0, 0.12); }

.md-btn--raised-pressed {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4); }

.md-btn--icon {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  height: 48px;
  padding: 12px;
  width: 48px; }

.md-btn--floating {
  height: 56px;
  padding: 16px;
  -webkit-transition-property: background, color, -webkit-box-shadow;
  transition-property: background, color, -webkit-box-shadow;
  transition-property: background, box-shadow, color;
  transition-property: background, box-shadow, color, -webkit-box-shadow;
  width: 56px; }

.md-btn--floating-mini {
  height: 40px;
  padding: 8px;
  width: 40px; }

.md-btn--fixed {
  position: fixed;
  z-index: 10; }

@media screen and (min-width: 320px) {
  .md-btn--text {
    height: 36px;
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 14px; }
    .md-btn--text::before, .md-btn--text::after {
      content: '';
      height: 6px;
      left: 0;
      position: absolute;
      right: 0; }
    .md-btn--text::before {
      top: -6px; }
    .md-btn--text::after {
      bottom: -6px; }
  .md-btn--fixed-tl {
    left: 16px;
    top: 16px; }
  .md-btn--fixed-tr {
    right: 16px;
    top: 16px; }
  .md-btn--fixed-bl {
    bottom: 16px;
    left: 16px; }
  .md-btn--fixed-br {
    bottom: 16px;
    right: 16px; } }

@media screen and (min-width: 1025px) {
  .md-btn--text {
    height: 32px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 13px; }
    .md-btn--text::before, .md-btn--text::after {
      display: none;
      visibility: hidden; }
    .md-btn--text::before {
      top: 0; }
    .md-btn--text::after {
      bottom: 0; }
  .md-btn--fixed-tl {
    left: 24px;
    top: 24px; }
  .md-btn--fixed-tr {
    right: 24px;
    top: 24px; }
  .md-btn--fixed-bl {
    bottom: 24px;
    left: 24px; }
  .md-btn--fixed-br {
    bottom: 24px;
    right: 24px; }
  .md-btn--icon {
    height: 40px;
    width: 40px;
    padding: 10px; }
  .md-btn--floating {
    height: 48px;
    padding: 14px;
    width: 48px; }
    .md-btn--floating-mini {
      height: 40px;
      padding: 10px;
      width: 40px; } }

.md-card {
  display: block; }
  .md-card--raise {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-box-shadow;
    transition-property: -webkit-box-shadow;
    transition-property: box-shadow;
    transition-property: box-shadow, -webkit-box-shadow; }

.md-collapser--card {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: background, transform;
  transition-property: background, transform, -webkit-transform; }

.md-card-text {
  font-size: 14px;
  padding: 16px; }
  .md-card-text p {
    font-size: inherit; }
    .md-card-text p:last-child {
      margin-bottom: 0; }
  .md-card-text:last-child {
    padding-bottom: 24px; }

.md-card-title {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  padding: 16px; }
  .md-card-title:last-child {
    padding-bottom: 24px; }
  .md-card-title--primary {
    padding-top: 24px; }
  .md-card-title--title {
    font-size: 14px;
    line-height: 1.42857;
    margin: 0;
    white-space: normal; }
  .md-card-title--large {
    font-size: 24px; }
  .md-card-title--one-line {
    overflow: hidden; }
    .md-card-title--one-line .md-card-title--title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.md-avatar--card {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 16px; }

.md-dialog-footer--card {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start; }

.md-dialog-footer--card-centered {
  -ms-flex-pack: center;
      justify-content: center; }

.md-card--table .md-card-title {
  padding-left: 24px; }

.md-chip {
  -ms-flex-align: center;
      align-items: center;
  background: #e0e0e0;
  border: 0;
  border-radius: 16px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, -webkit-box-shadow;
  transition-property: background, -webkit-box-shadow;
  transition-property: box-shadow, background;
  transition-property: box-shadow, background, -webkit-box-shadow;
  vertical-align: top;
  white-space: nowrap; }
  .md-chip:focus {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    outline-style: none; }

.md-chip--hover.md-chip--hover {
  background: #616161; }

.md-chip-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: color;
  transition-property: color; }

.md-chip .md-avatar {
  border: 0;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px; }

.md-chip--avatar {
  padding-left: 40px; }

.md-chip-icon {
  color: rgba(0, 0, 0, 0.54);
  margin-left: 4px;
  margin-right: 4px;
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 1; }

.md-chip-icon--rotate {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg); }

.md-chip--remove {
  padding-right: 32px; }

.md-chip-text--contact {
  font-size: 14px; }

.md-chip-text--hover {
  color: #fff; }

@media screen and (min-width: 1025px) {
  .md-chip-icon {
    margin-left: 6px;
    margin-right: 6px;
    top: 6px; } }

.md-collapser.md-collapser {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.md-collapser .md-tooltip-container {
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
          transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.md-collapser--flipped {
  -webkit-transform: rotate3d(0, 0, 1, 180deg);
          transform: rotate3d(0, 0, 1, 180deg); }
  .md-collapser--flipped .md-tooltip-container {
    -webkit-transform: rotate3d(0, 0, 1, -180deg);
            transform: rotate3d(0, 0, 1, -180deg); }

.md-data-table {
  border-collapse: collapse;
  max-width: 100%; }
  .md-data-table--full-width {
    width: 100%; }
  .md-data-table--responsive {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto; }

thead .md-table-row,
tbody .md-table-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

tbody .md-table-row {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background;
  transition-property: background; }
  tbody .md-table-row--active {
    background: #f5f5f5; }
  @media (min-device-width: 1025px) {
    tbody .md-table-row--hover {
      background: #eee; } }

.md-table-column {
  line-height: normal;
  padding-bottom: 0;
  padding-right: 24px;
  padding-top: 0;
  vertical-align: middle;
  white-space: nowrap; }
  .md-table-column:first-child {
    padding-left: 24px; }
  .md-table-column--relative {
    position: relative; }
  .md-table-column--adjusted {
    padding-right: 56px; }
  .md-table-column--header {
    font-size: 12px;
    font-weight: 500;
    height: 56px; }
    .md-table-column--header .md-icon {
      font-size: 16px; }
  .md-table-column--data {
    font-size: 13px;
    height: 48px; }
  .md-table-column--plain {
    height: 48px;
    white-space: normal; }
  .md-table-column--grow {
    width: 100%; }
  .md-table-column--select-header {
    padding-left: 16px; }
  .md-table-column .md-icon-separator {
    line-height: inherit; }

.md-table-checkbox .md-selection-control-label {
  display: -ms-flexbox;
  display: flex; }

.md-table-checkbox .md-selection-control-container {
  margin-left: 12px;
  margin-right: 12px; }

.md-edit-dialog.md-dialog {
  width: 250px; }

.md-edit-dialog__label {
  overflow: hidden;
  padding: 16px 0;
  text-overflow: ellipsis;
  width: 250px; }
  @media screen and (max-width: 1024px) {
    .md-edit-dialog__label {
      font-size: 16px; } }

.md-edit-dialog__content {
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  padding-bottom: 8px; }
  .md-edit-dialog__content:not(:first-child) {
    padding-top: 0; }

.md-edit-dialog__header {
  font-weight: 500; }
  .md-edit-dialog__header.md-text-field {
    font-size: 12px; }

.md-edit-dialog__blocked-field {
  height: 47px;
  width: 250px; }
  .md-edit-dialog__blocked-field.md-edit-dialog__blocked-field {
    padding-bottom: 0;
    padding-top: 0; }
  .md-edit-dialog__blocked-field .md-text-field-icon-container {
    -ms-flex-align: center;
        align-items: center; }

.md-table-column--select-field {
  padding-left: 24px; }

.md-select-field-column .md-select-field--btn {
  height: 47px; }

.md-table-footer--pagination .md-table-column {
  padding-left: 0; }

.md-table-pagination {
  height: 56px; }
  .md-table-pagination--controls {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: start;
        justify-content: flex-start;
    position: absolute;
    white-space: nowrap; }

@media (max-width: 767px) {
  .md-table-pagination .md-text-field {
    font-size: 13px; }
  .md-table-pagination .md-icon-text:first-child {
    padding-right: 4px; }
  .md-table-pagination__label {
    display: none; } }

.md-table-card-header {
  position: relative; }
  .md-table-card-header--no-title {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 80px;
    padding-right: 2px; }
  .md-table-card-header .md-card-title {
    padding-right: 2px; }
    .md-table-card-header .md-card-title:last-child {
      padding-bottom: 16px; }
  .md-table-card-header .md-btn--dialog + .md-btn--dialog {
    margin-left: 8px; }

.md-card-title--contextual {
  background: #28993F;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }

.md-card-title--title-contextual {
  color: #28993F;
  font-size: 16px;
  font-weight: 500;
  line-height: 80px; }

.md-drop-down-enter {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0); }
  .md-drop-down-enter.md-drop-down-enter-active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-drop-down-leave {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }
  .md-drop-down-leave.md-drop-down-leave-active {
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-data-table--fixed {
  overflow-y: hidden; }

.md-data-table__fixed-wrapper {
  display: table;
  min-width: 100%;
  position: relative; }
  .md-data-table__fixed-wrapper--header {
    padding-top: 56px; }
  .md-data-table__fixed-wrapper--footer {
    padding-bottom: 48px; }

.md-data-table__scroll-wrapper {
  overflow-x: hidden;
  overflow-y: auto; }

.md-table-column--fixed {
  height: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
  white-space: nowrap; }
  .md-table-column--fixed > * {
    display: none; }
  .md-table-column--fixed .md-table-column__fixed {
    display: block; }

.md-table-column__fixed {
  position: absolute;
  visibility: visible; }
  .md-table-column__fixed--header {
    top: 0; }
  .md-table-column__fixed--footer {
    bottom: 0; }
  .md-table-column__fixed--flex {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex; }
    .md-table-column__fixed--flex-right {
      -ms-flex-pack: end;
          justify-content: flex-end; }
  .md-table-column__fixed .md-table-checkbox--header {
    display: -ms-flexbox;
    display: flex;
    height: 56px; }
  .md-table-column__fixed .md-table-checkbox--footer {
    display: -ms-flexbox;
    display: flex;
    height: 48px; }

.md-dialog-container.md-overlay {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  z-index: 20; }

.md-dialog {
  width: 280px;
  cursor: auto;
  position: fixed; }

.md-dialog--centered {
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  max-height: calc(100% - 48px);
  max-width: calc(100% - 80px); }
  .md-dialog--centered .md-list {
    padding-bottom: 8px;
    padding-top: 0; }
  .md-dialog--centered .md-list-tile {
    height: auto;
    padding: 16px 24px; }
  .md-dialog--centered .md-tile-text--primary {
    white-space: normal; }

.md-dialog--centered-enter {
  -webkit-transform: translate3d(-50%, calc(-50% + -30px), 0);
          transform: translate3d(-50%, calc(-50% + -30px), 0); }
  .md-dialog--centered-enter.md-dialog--centered-enter-active {
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-dialog--centered-leave {
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0); }
  .md-dialog--centered-leave.md-dialog--centered-leave-active {
    -webkit-transform: translate3d(-50%, calc(-50% + -30px), 0);
            transform: translate3d(-50%, calc(-50% + -30px), 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-dialog--full-page {
  bottom: 0;
  left: 0;
  overflow: auto;
  top: 0;
  width: 100vw;
  z-index: 110; }

.md-dialog--full-page-enter {
  -webkit-transform: scale(0);
          transform: scale(0); }
  .md-dialog--full-page-enter.md-dialog--full-page-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-dialog--full-page-leave {
  -webkit-transform: scale(1);
          transform: scale(1); }
  .md-dialog--full-page-leave.md-dialog--full-page-leave-active {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

.md-title--dialog {
  margin-bottom: 0;
  padding: 24px;
  padding-bottom: 20px;
  white-space: normal; }

.md-dialog-content {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.md-dialog-content--padded {
  padding: 24px; }
  .md-dialog-content--padded:not(:first-child) {
    padding-top: 0; }

.md-dialog-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end; }

.md-dialog-footer--inline {
  padding: 8px; }
  .md-dialog-footer--inline .md-btn--dialog + .md-btn--dialog {
    margin-left: 8px; }

.md-dialog-footer--stacked {
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-bottom: 8px;
  padding-right: 8px; }
  .md-dialog-footer--stacked .md-btn--dialog {
    margin-bottom: 6px;
    margin-top: 6px; }

.md-btn--dialog {
  height: 36px;
  min-width: 64px;
  padding-left: 8px;
  padding-right: 8px; }

.md-divider {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  content: '';
  display: block;
  height: 1px;
  margin: 0; }

.md-divider--vertical {
  height: 100%;
  width: 1px; }

.md-divider--inset {
  margin-left: 72px; }

.md-divider-border {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0; }

.md-divider-border--top {
  border-top-width: 1px; }

.md-divider-border--right {
  border-right-width: 1px; }

.md-divider-border--bottom {
  border-bottom-width: 1px; }

.md-divider-border--left {
  border-left-width: 1px; }

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .md-drawer--left {
    max-width: 320px;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
    width: calc(100vw - 56px); }
  .md-drawer--right {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .md-drawer--mini.md-drawer--mini {
    width: 48px; }
  .md-list-tile--mini.md-list-tile--mini {
    padding-left: 12px;
    padding-right: 12px; }
  .md-drawer-relative--mini.md-drawer-relative--mini {
    margin-left: 48px; }
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 56px); } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 48px); } }

@media screen and (min-width: 768px) {
  .md-drawer--left {
    max-width: 400px;
    -webkit-transform: translate3d(-256px, 0, 0);
            transform: translate3d(-256px, 0, 0);
    width: 256px; }
  .md-drawer--right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .md-drawer--mini.md-drawer--mini {
    width: 72px; }
  .md-list-tile--mini.md-list-tile--mini {
    padding-left: 26px;
    padding-right: 26px; }
  .md-drawer-relative {
    margin-left: 256px; }
  .md-drawer-relative--mini.md-drawer-relative--mini {
    margin-left: 72px; }
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 64px); } }

.md-drawer {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  .md-drawer--fixed {
    bottom: 0;
    position: fixed;
    top: 0;
    z-index: 17; }
  .md-drawer--inline {
    display: inline-block;
    height: 100%; }
  .md-drawer--left {
    left: 0; }
  .md-drawer--right {
    right: 0; }
  .md-drawer--active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .md-drawer--mini {
    z-index: 16; }

.md-list--drawer {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-y: auto; }

.md-overlay--drawer.md-overlay--drawer {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }

.md-expansion-panel {
  background: #fff;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: margin;
  transition-property: margin; }
  .md-expansion-panel--expanded:not(:first-child) {
    margin-top: 16px; }
  .md-expansion-panel--expanded:not(:last-child) {
    margin-bottom: 16px; }

.md-panel-column:not(:last-child) {
  padding-right: 16px; }

.md-panel-column--overflown {
  -ms-flex-negative: 1;
      flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word; }

.md-panel-column:not(:last-child) {
  padding-right: 16px; }

.md-panel-header {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  height: 48px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, height;
  transition-property: background, height; }
  .md-panel-header--expanded {
    height: 64px; }
  .md-panel-header--focused {
    background: #eee; }

.md-panel-content {
  padding: 0 24px 16px; }

.md-panel-secondary-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px; }

.md-file-input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0; }

.md-file-input-container {
  position: relative; }
  .md-file-input-container .md-btn {
    display: block; }

.md-ink-container {
  border-radius: inherit;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; }

.md-ink-container--2x {
  height: 200%;
  left: -50%;
  top: -50%;
  width: 200%; }

.md-ink {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: block;
  opacity: 1;
  position: absolute;
  -webkit-transform: scale(0);
          transform: scale(0);
  z-index: -1; }

.md-ink--active {
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-ink--expanded {
  -webkit-transform: scale(1);
          transform: scale(1); }

.md-ink--leaving {
  opacity: 0;
  -webkit-transition-duration: .3s;
          transition-duration: .3s;
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 24px;
  text-align: center;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

svg.md-icon {
  fill: currentColor;
  height: 24px;
  width: 24px; }

.md-icon-separator {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-weight: inherit;
  text-align: left; }
  .md-icon-separator .md-icon {
    -ms-flex-positive: 0;
        flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0; }

.md-icon-text {
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-weight: inherit;
  line-height: inherit; }
  .md-icon-text:first-child {
    padding-right: 16px; }
  .md-icon-text:last-child {
    padding-left: 16px; }

@media screen and (min-width: 1025px) {
  .material-icons.md-icon {
    font-size: 20px; }
  svg.md-icon {
    height: 20px;
    width: 20px; } }

.md-layover-enter {
  -webkit-transform: scale(0);
          transform: scale(0); }
  .md-layover-enter.md-layover-enter-active {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-layover-leave {
  opacity: 1; }
  .md-layover-leave.md-layover-leave-active {
    opacity: 0;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-layover-child {
  position: fixed;
  z-index: 100; }
  .md-layover-child--tl {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
  .md-layover-child--tr {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0; }
  .md-layover-child--bl {
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%; }
  .md-layover-child--br {
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%; }
  .md-layover-child--below {
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0; }
    .md-layover-child--below.md-layover-enter {
      -webkit-transform: scaleY(0);
              transform: scaleY(0); }
      .md-layover-child--below.md-layover-enter.md-layover-enter-active {
        -webkit-transform: scaleY(1);
                transform: scaleY(1); }

.md-layover--simplified {
  position: relative; }

.md-layover-child--simplified {
  position: absolute; }

.md-list {
  background: #fff;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .md-list .md-avatar,
  .md-list .md-icon {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .md-list .md-divider:not(.md-list-item--divider-vertical) {
    margin-bottom: 8px;
    margin-top: 8px; }
  .md-list .md-list:not(.md-list--menu) {
    background: inherit; }

.md-list-tile {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background;
  transition-property: background; }
  .md-list-tile--active {
    background: rgba(0, 0, 0, 0.12); }

.md-list-item--inset {
  padding-left: 72px; }

.md-list-item--flex {
  display: -ms-flexbox;
  display: flex; }

.md-list-item--button-grow {
  -ms-flex-positive: 1;
      flex-grow: 1; }

.md-list-item--divider-vertical {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: calc(100% - 4px);
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  width: 2px; }

.md-list-item--children-inline {
  -ms-flex-item-align: center;
      align-self: center;
  padding-right: 2px; }

.md-tile-content {
  -ms-flex-positive: 1;
      flex-grow: 1;
  overflow: hidden; }
  .md-tile-content--left-icon {
    padding-left: 32px; }
  .md-tile-content--left-avatar {
    padding-left: 16px; }
  .md-tile-content--right-padding {
    padding-right: 16px; }

.md-tile-addon {
  line-height: 1.42857; }
  .md-tile-addon--icon {
    height: 24px; }
  .md-tile-addon--avatar {
    height: 40px; }

.md-text--theme-primary .md-icon {
  color: inherit; }

.md-tile-text--primary, .md-tile-text--secondary {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.md-tile-text--three-lines {
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 2; }

.md-list-tile--three-lines .md-tile-addon {
  -ms-flex-item-align: start;
      align-self: flex-start; }

.md-list--nested-1 .md-list-tile {
  padding-left: 72px; }

.md-list--nested-2 .md-list-tile {
  padding-left: 108px; }

.md-list-control {
  -ms-flex-positive: 1;
      flex-grow: 1; }
  .md-list-control .md-selection-control-label {
    width: 100%; }
    .md-list-control .md-selection-control-label > span {
      width: 100%; }

.md-tile-content--left-button {
  padding-left: 24px; }

.md-list-control--right {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.md-list-tile--control-left {
  padding-left: 0; }

.md-list-tile--control-right {
  padding-right: 0; }

@media screen and (min-width: 320px) {
  .md-list {
    padding-bottom: 8px;
    padding-top: 8px; }
  .md-list-tile {
    height: 48px; }
  .md-tile-text--primary {
    font-size: 16px; }
  .md-tile-text--secondary {
    font-size: 14px; }
  .md-list-tile--avatar {
    height: 56px; }
  .md-list-tile--two-lines {
    height: 72px; }
  .md-list-tile--three-lines {
    height: 88px; }
    .md-list-tile--three-lines .md-tile-text--secondary {
      height: 40px; }
    .md-list-tile--three-lines .md-tile-addon {
      margin-top: 14px; } }

@media screen and (min-width: 1025px) {
  .md-list {
    padding-bottom: 4px;
    padding-top: 4px; }
  .md-list-tile {
    height: 40px; }
  .md-tile-text--primary, .md-tile-text--secondary {
    font-size: 13px; }
  .md-list-tile--avatar {
    height: 48px; }
  .md-list-tile--two-lines {
    height: 60px; }
  .md-list-tile--three-lines {
    height: 76px; }
    .md-list-tile--three-lines .md-tile-text--secondary {
      height: 37.14286px; }
    .md-list-tile--three-lines .md-tile-addon {
      margin-top: 12px; }
  .md-tile-addon--icon {
    height: 20px; }
  .md-tile-content--left-icon {
    padding-left: 36px; } }

.md-list--inline {
  display: -ms-flexbox;
  display: flex;
  padding: 0; }

.md-media {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }

.md-media--16-9 {
  padding-bottom: 56.25%; }

.md-media--4-3 {
  padding-bottom: 75%; }

.md-media--1-1 {
  padding-bottom: 100%; }

.md-media-overlay {
  background: rgba(0, 0, 0, 0.54);
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1; }
  .md-media-overlay .md-btn,
  .md-media-overlay .md-text {
    color: #fff; }
  .md-media-overlay .md-text--secondary {
    color: rgba(255, 255, 255, 0.7); }

.md-list--menu {
  min-width: 112px; }
  .md-list--menu-restricted {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .md-list--menu-contained {
    width: 100%; }
  .md-list--menu-below {
    left: 0;
    top: 100%;
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0; }
  .md-list--menu-tr {
    right: 0;
    top: 0;
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0; }
  .md-list--menu-tl {
    top: 0;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
  .md-list--menu-br {
    right: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%; }
  .md-list--menu-bl {
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%; }

@media screen and (min-width: 1025px) {
  .md-list--menu-cascading {
    padding-bottom: 16px;
    padding-top: 16px; }
    .md-list--menu-cascading .md-list-tile {
      padding-left: 24px;
      padding-right: 24px; }
      .md-list--menu-cascading .md-list-tile:not(.md-list-tile--two-lines):not(.md-list-tile--three-lines) {
        height: 32px; }
    .md-list--menu-cascading .md-tile-text--primary {
      font-size: 15px; }
    .md-list--menu-cascading .md-collapser {
      -webkit-transform: rotate3d(0, 0, 1, -90deg);
              transform: rotate3d(0, 0, 1, -90deg); }
    .md-list--menu-cascading .md-collapser--flipped {
      -webkit-transform: rotate3d(0, 0, 1, 90deg);
              transform: rotate3d(0, 0, 1, 90deg); } }

@media screen and (min-width: 320px) {
  .md-list--menu-restricted {
    max-height: 272px; } }

@media screen and (min-width: 1025px) {
  .md-list--menu-restricted {
    max-height: 264px; } }

@media screen and (min-width: 320px) {
  .md-navigation-drawer-content {
    min-height: calc(100vh - 56px); } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-navigation-drawer-content {
    min-height: calc(100vh - 48px); } }

@media screen and (min-width: 768px) {
  .md-navigation-drawer-content {
    min-height: calc(100vh - 64px); }
  .md-title.md-title--persistent-offset {
    margin-left: 216px; } }

@media screen and (min-width: 1025px) {
  .md-title.md-title--persistent-offset {
    margin-left: 226px; } }

.md-toolbar.md-toolbar--over-drawer {
  z-index: 19; }

.md-title--drawer-active.md-title--drawer-active {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: margin-left;
  transition-property: margin-left; }

.md-navigation-drawer-content {
  display: block; }
  .md-navigation-drawer-content:focus {
    outline-style: none; }
  .md-navigation-drawer-content--inactive {
    margin-left: 0; }
  .md-navigation-drawer-content--active {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: margin-left;
    transition-property: margin-left; }
  .md-navigation-drawer-content--prominent-offset {
    min-height: calc(100vh - 128px); }

.md-title.md-title--permanent-offset {
  margin-left: 276px; }

.md-cross-fade-enter {
  opacity: .01;
  -webkit-transform: translate3d(0, 16px, 0);
          transform: translate3d(0, 16px, 0); }
  .md-cross-fade-enter.md-cross-fade-enter-active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform; }

.md-overlay {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  z-index: 16; }
  .md-overlay--active {
    opacity: 1; }

.md-paper--0 {
  -webkit-box-shadow: none;
          box-shadow: none; }

@media screen and (min-width: 1025px) {
  .md-paper--0-hover {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-box-shadow;
    transition-property: -webkit-box-shadow;
    transition-property: box-shadow;
    transition-property: box-shadow, -webkit-box-shadow; }
    .md-paper--0-hover:hover {
      -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
              box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); } }

.md-paper--1 {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.md-paper--2 {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4); }

.md-paper--3 {
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4); }

.md-paper--4 {
  -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
          box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4); }

.md-paper--5 {
  -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4); }

.md-picker-container {
  position: relative; }

.md-picker-content-container {
  background: #fff; }

.md-picker--inline {
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 12; }

.md-picker--inline-icon {
  left: 40px; }

.md-picker-control {
  padding: 0; }

.md-picker-text {
  color: rgba(255, 255, 255, 0.7); }
  .md-picker-text.md-picker-text > * {
    color: inherit; }
  .md-picker-text > * {
    font-weight: inherit;
    margin: 0;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: color, font;
    transition-property: color, font; }

.md-picker-text--active {
  color: #fff;
  font-weight: 500; }

.md-dialog--picker {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 16px);
  max-width: calc(100% - 16px);
  overflow: auto;
  width: auto; }

.md-dialog-content--picker {
  padding: 0; }

.md-picker-header {
  background: #28993F;
  padding: 24px; }

@media (orientation: portrait) {
  .md-picker {
    width: 330px; }
    .md-picker .md-picker-header {
      height: 110px; }
    .md-picker .md-time-periods {
      display: inline-block;
      padding-left: 1em;
      padding-right: 24px; }
    .md-picker .md-picker-content {
      height: 340px; }
    .md-picker .md-display-3 {
      font-size: 5em;
      line-height: 1; }
    .md-picker .md-calendar-dows {
      padding-bottom: 12px; }
    .md-picker.md-picker--date .md-display-1 {
      display: inline-block; }
    .md-picker .md-calendar-date--btn {
      height: 44px; }
      .md-picker .md-calendar-date--btn::after {
        height: 36px;
        width: 36px; }
    .md-picker .md-clock-face {
      height: 282px;
      width: 282px; }
    .md-picker .md-clock-hand {
      width: 121px; }
      .md-picker .md-clock-hand--inner {
        width: 85px; } }

@media (orientation: landscape) {
  .md-picker {
    display: -ms-flexbox;
    display: flex;
    width: 560px; }
    .md-picker .md-picker-header {
      width: 190px; }
    .md-picker .md-picker-content {
      height: 280px;
      width: 370px; }
    .md-picker .md-calendar-date--btn {
      height: 35px; }
      .md-picker .md-calendar-date--btn::after {
        height: 32px;
        width: 32px; }
    .md-picker.md-picker--time .md-picker-header {
      padding-top: 93.33333px; }
    .md-picker .md-clock-face {
      height: 244px;
      width: 244px; }
    .md-picker .md-clock-hand {
      width: 102px; }
      .md-picker .md-clock-hand--inner {
        width: 66px; }
    .md-picker .md-time-periods {
      margin-left: auto;
      margin-right: 2.5em;
      width: 35px; }
    .md-picker .md-display-3 {
      font-size: 3.25em; } }

@media (max-width: 320px) and (orientation: portrait) {
  .md-picker {
    width: 304px; } }

@media (max-height: 320px) and (orientation: landscape) {
  .md-picker .md-picker-content {
    height: 256px;
    width: calc(100vw - 206px); } }

.md-picker--date .md-picker-control {
  display: block; }

.md-picker-content--calendar {
  padding-left: 12px;
  padding-right: 12px; }

.md-calendar-controls {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex; }
  .md-calendar-controls .md-title {
    -ms-flex-positive: 1;
        flex-grow: 1;
    font-size: 16px;
    margin-bottom: 0;
    text-align: center; }

.md-calendar-date {
  margin: 0;
  width: calc(100% / 7); }

.md-picker-content--year {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.md-years {
  list-style: none;
  margin: 0;
  padding: 0; }

.md-year {
  font-size: 16px;
  padding: 12px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: color, font-size;
  transition-property: color, font-size; }
  .md-year:focus:not(.md-year--active) {
    font-size: 20px; }
  .md-year--active {
    font-size: 24px;
    font-weight: 500; }

.md-calendar-date--btn::after {
  background: #28993F;
  border-radius: 50%;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0);
          transform: translateX(-50%) translateY(-50%) scale(0);
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  z-index: 0; }

.md-calendar-date--btn-active {
  font-weight: 700; }
  .md-calendar-date--btn-active::after {
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
            transform: translateX(-50%) translateY(-50%) scale(1); }

.md-calendar-date--date {
  position: relative;
  z-index: 1; }

.md-calendar-dow {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.42857; }

.md-picker-content--clock {
  padding: 24px;
  padding-bottom: 12px; }

.md-time-periods .md-picker-control {
  display: block; }

.md-time-period {
  font-size: 1.14286em;
  font-weight: 500;
  margin: 0;
  padding: 0; }

.md-clock-face {
  background: #f5f5f5;
  border-radius: 50%;
  position: relative; }

.md-clock-hand {
  height: 2px;
  position: absolute;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: width;
  transition-property: width;
  z-index: 1; }
  .md-clock-hand--active {
    -webkit-transition-property: width, -webkit-transform;
    transition-property: width, -webkit-transform;
    transition-property: transform, width;
    transition-property: transform, width, -webkit-transform; }
  .md-clock-hand::before, .md-clock-hand::after {
    background: #28993F;
    border-radius: 50%;
    content: '';
    position: absolute; }
  .md-clock-hand::before {
    height: 6px;
    left: -3px;
    top: -2px;
    width: 6px; }
  .md-clock-hand::after {
    height: 36px;
    right: -16px;
    top: -18px;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: background, border, -webkit-transform;
    transition-property: background, border, -webkit-transform;
    transition-property: background, border, transform;
    transition-property: background, border, transform, -webkit-transform;
    width: 36px; }
  .md-clock-hand--minute-hover::after {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); }

.md-clock-time {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  width: 36px;
  z-index: 8; }
  .md-clock-time:focus {
    outline-style: none; }

.md-progress {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em; }

.md-progress--circular-determinate {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.md-progress--circular-indeterminate {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-circular-progress;
          animation-name: md-circular-progress;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear; }

.md-circular-progress-path {
  fill: none;
  stroke: #28993F;
  stroke-dasharray: 187px; }

.md-circular-progress-path--animated {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-circular-progress-dash;
          animation-name: md-circular-progress-dash;
  -webkit-animation-timing-function: ease-ine-out;
          animation-timing-function: ease-ine-out;
  stroke-dashoffset: 0; }

@-webkit-keyframes md-circular-progress {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  75% {
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@keyframes md-circular-progress {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  75% {
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); } }

@-webkit-keyframes md-circular-progress-dash {
  0% {
    stroke-dashoffset: 187px; }
  50% {
    stroke-dashoffset: 46.75px; }
  100% {
    stroke-dashoffset: 187px; } }

@keyframes md-circular-progress-dash {
  0% {
    stroke-dashoffset: 187px; }
  50% {
    stroke-dashoffset: 46.75px; }
  100% {
    stroke-dashoffset: 187px; } }

.md-progress--linear {
  background: #28993F;
  height: 3px;
  overflow: hidden;
  position: relative;
  width: 100%; }

.md-progress--linear-active {
  background: #28993F; }

.md-progress--linear-determinate {
  height: 100%;
  position: absolute;
  z-index: 1; }

.md-progress--linear-indeterminate::before, .md-progress--linear-indeterminate::after {
  background: inherit;
  bottom: 0;
  content: '';
  position: absolute;
  top: 0;
  will-change: left, right;
  z-index: 1; }

.md-progress--linear-indeterminate::before {
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-linear-indeterminate;
          animation-name: md-linear-indeterminate;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1); }

.md-progress--linear-indeterminate::after {
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
  -webkit-animation-duration: 2.4s;
          animation-duration: 2.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: md-linear-indeterminate-short;
          animation-name: md-linear-indeterminate-short;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-progress--linear-query::before, .md-progress--linear-query::after {
  animation-direction: reverse; }

@-webkit-keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%; }
  60% {
    left: 100%;
    right: -90%; }
  100% {
    left: 100%;
    right: -90%; } }

@-webkit-keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

@keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%; }
  60% {
    left: 107%;
    right: -8%; }
  100% {
    left: 107%;
    right: -8%; } }

.md-select-field {
  cursor: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .md-select-field .md-divider {
    width: 100%; }
  .md-select-field__toggle {
    position: relative; }

.md-select-field--btn.md-select-field--btn {
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 16px;
  padding-top: 16px; }

.md-select-field--text-field {
  pointer-events: none; }

.md-drop-enter {
  -webkit-transform: translate3d(0, -6px, 0);
          transform: translate3d(0, -6px, 0); }
  .md-drop-enter.md-drop-enter-active {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform; }

@media screen and (min-width: 320px) {
  .md-select-field--btn {
    height: 48px; }
  .md-select-field--text-field {
    height: 18px; } }

@media screen and (min-width: 1025px) {
  .md-select-field--btn {
    height: 40px; }
  .md-select-field--text-field {
    height: 15px; } }

.md-selection-control-container--inline {
  display: inline-block; }

.md-selection-control-input {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0; }

.md-selection-control-label {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex; }

.md-selection-control-group {
  border: 0; }

.md-switch-container {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 48px; }
  .md-switch-container.md-selection-control-container--inline {
    display: -ms-inline-flexbox;
    display: inline-flex; }

.md-switch-track {
  border-radius: 8px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 16px;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  width: 40px; }

.md-switch-track--on {
  background: rgba(40, 153, 63, 0.5); }

.md-switch-track--off {
  background: rgba(0, 0, 0, 0.38); }

.md-switch-track--disabled {
  background: rgba(0, 0, 0, 0.12); }

.md-switch-thumb {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: '';
  display: block;
  height: 24px;
  position: absolute;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: background, transform;
  transition-property: background, transform, -webkit-transform;
  width: 24px; }

.md-switch-thumb--on {
  background: #28993F;
  -webkit-transform: translate3d(20px, -4px, 0);
          transform: translate3d(20px, -4px, 0); }

.md-switch-thumb--off {
  background: #fafafa;
  -webkit-transform: translate3d(-4px, -4px, 0);
          transform: translate3d(-4px, -4px, 0); }

.md-switch-thumb--disabled {
  background: #bdbdbd; }

@media screen and (min-width: 320px) {
  .md-selection-control-label {
    font-size: 16px; } }

@media screen and (min-width: 1025px) {
  .md-selection-control-label {
    font-size: 13px; }
  .md-selection-control-container {
    height: 40px; } }

.md-slider-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  text-align: left; }

.md-slider-label {
  display: block;
  width: 100%; }

.md-slider-input {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0; }

.md-slider-track {
  background: rgba(0, 0, 0, 0.26);
  content: '';
  display: block;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 2px;
  margin-bottom: 23px;
  margin-top: 23px;
  position: relative; }

.md-slider-track-fill {
  background: #28993F;
  border: 0;
  height: 2px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: width;
  transition-property: width; }

.md-slider-track-fill--dragging {
  -webkit-transition-property: none;
  transition-property: none; }

.md-slider-thumb {
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 14px;
  position: absolute;
  top: -6px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: left, background, -webkit-transform;
  transition-property: left, background, -webkit-transform;
  transition-property: left, transform, background;
  transition-property: left, transform, background, -webkit-transform;
  width: 14px;
  z-index: 5; }
  .md-slider-thumb:focus {
    outline-style: none; }

.md-slider-thumb--on {
  background: #28993F; }

.md-slider-thumb--active {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  -webkit-transform-origin: center;
          transform-origin: center; }

.md-slider-thumb--dragging {
  -webkit-transition-property: background, -webkit-transform;
  transition-property: background, -webkit-transform;
  transition-property: transform, background;
  transition-property: transform, background, -webkit-transform; }

.md-slider-thumb--disabled {
  background: rgba(0, 0, 0, 0.26);
  -webkit-transform: scale(0.75);
          transform: scale(0.75); }

.md-slider-thumb--continuous-off {
  background: #fff;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.26);
  position: relative; }

.md-slider-thumb--mask {
  -webkit-transform: scale(1);
          transform: scale(1); }

.md-slider-thumb--mask-inked {
  background: rgba(40, 153, 63, 0.15);
  -webkit-transform: scale(3.5);
          transform: scale(3.5);
  z-index: 4; }

.md-slider-thumb--mask-disabled {
  background: #fff;
  border-radius: 0;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  z-index: 4; }

.md-slider-thumb--discrete::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: 10px;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: border-top-color, -webkit-transform;
  transition-property: border-top-color, -webkit-transform;
  transition-property: border-top-color, transform;
  transition-property: border-top-color, transform, -webkit-transform;
  width: 0; }

.md-slider-thumb--discrete-active {
  top: 0;
  -webkit-transform: scale(2) translate3d(0, -18px, 0);
          transform: scale(2) translate3d(0, -18px, 0); }

.md-slider-thumb--discrete-on::after {
  border-top-color: #28993F; }

.md-slider-thumb--discrete-off {
  background: #000; }

.md-slider-thumb--discrete-active-off {
  background: #bdbdbd; }
  .md-slider-thumb--discrete-active-off::after {
    border-top-color: #bdbdbd; }

.md-slider-thumb--discrete-mask-inked {
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s; }

.md-slider-thumb--discrete-mask-leaving {
  background: rgba(40, 153, 63, 0.3);
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  z-index: 4; }

.md-slider-discrete-value {
  -ms-flex-align: center;
      align-items: center;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  height: 28px;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  text-align: center;
  -webkit-transform: translate3d(-7px, -43px, 0);
          transform: translate3d(-7px, -43px, 0);
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: left;
  transition-property: left;
  width: 28px;
  z-index: 6; }

.md-slider-discrete-tick {
  background: #000;
  content: '';
  height: 2px;
  position: absolute;
  top: 0px;
  z-index: 4; }

.md-slider-discrete-value--dragging {
  -webkit-transition-property: none;
  transition-property: none; }

.md-slider-editor {
  text-align: right; }

.md-slider-container .md-text-field-container {
  margin-left: 4px;
  padding-right: 0; }

@media screen and (min-width: 320px) {
  .md-slider-ind {
    font-size: 16px;
    margin-top: 14px; } }

@media screen and (min-width: 1025px) {
  .md-slider-ind {
    font-size: 13px;
    margin-top: 16px; } }

.md-slider-container .md-icon {
  margin-bottom: 12px;
  margin-top: 12px; }

.md-slider-ind {
  display: block;
  text-align: center; }

.md-slider-track--ind-left {
  margin-left: 16px; }

.md-slider-track--ind-right {
  margin-right: 16px; }

.md-subheader {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding-left: 16px;
  padding-right: 16px; }

.md-snackbar-container {
  position: absolute; }

.md-snackbar {
  -ms-flex-align: center;
      align-items: center;
  background: #323232;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  height: 48px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: fixed;
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
  z-index: 20; }

.md-snackbar--multiline {
  height: 80px; }

.md-snackbar--toast {
  color: #fff;
  margin-bottom: 0;
  padding-left: 24px;
  padding-right: 24px; }

.md-snackbar-enter-active,
.md-snackbar-leave-active {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  .md-snackbar-enter-active *,
  .md-snackbar-leave-active * {
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: opacity;
    transition-property: opacity; }

.md-snackbar-enter.md-snackbar-enter-active {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.md-snackbar-leave.md-snackbar-leave-active {
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }

.md-snackbar-enter *,
.md-snackbar-leave.md-snackbar-leave-active * {
  opacity: 0; }

.md-snackbar-enter.md-snackbar-enter-active *,
.md-snackbar-leave * {
  opacity: 1; }

.md-btn--snackbar {
  padding-left: 24px;
  padding-right: 24px; }

@media screen and (min-width: 320px) {
  .md-snackbar {
    left: 0;
    right: 0; }
  .md-snackbar-enter,
  .md-snackbar-leave.md-snackbar-leave-active {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  .md-snackbar-enter.md-snackbar-enter-active,
  .md-snackbar-leave {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .md-snackbar--action {
    padding-right: 0; }
  .md-btn--snackbar-floating {
    -webkit-transition: bottom 0.3s, background 0.15s, color 0.15s, -webkit-box-shadow 0.15s;
    transition: bottom 0.3s, background 0.15s, color 0.15s, -webkit-box-shadow 0.15s;
    transition: bottom 0.3s, background 0.15s, box-shadow 0.15s, color 0.15s;
    transition: bottom 0.3s, background 0.15s, box-shadow 0.15s, color 0.15s, -webkit-box-shadow 0.15s;
    will-change: bottom; }
  .md-btn--snackbar-floating-adjust {
    bottom: 64px; }
  .md-btn--snackbar-floating-multiline-adjust {
    bottom: 96px; } }

@media screen and (min-width: 768px) {
  .md-snackbar {
    border-radius: 2px;
    left: 50%;
    max-width: 568px;
    min-width: 288px;
    right: auto;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .md-snackbar-enter,
  .md-snackbar-leave.md-snackbar-leave-active {
    -webkit-transform: translate3d(-50%, 100%, 0);
            transform: translate3d(-50%, 100%, 0); }
  .md-snackbar-enter.md-snackbar-enter-active,
  .md-snackbar-leave {
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .md-snackbar--action {
    padding-right: 24px; }
  .md-btn--snackbar-floating-adjust {
    bottom: 24px; }
  .md-btn--snackbar-floating-multiline-adjust {
    bottom: 24px; } }

.md-tabs {
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  .md-tabs.md-background--primary .md-ink {
    background: rgba(255, 255, 255, 0.12); }
  .md-tabs--centered {
    -ms-flex-pack: center;
        justify-content: center; }
    .md-tabs--centered .md-tab {
      -ms-flex-positive: 0;
          flex-grow: 0; }
  .md-tabs--pagination .md-tab {
    -ms-flex-positive: 0;
        flex-grow: 0; }

.md-tab {
  -ms-flex-align: center;
      align-items: center;
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 48px;
  -ms-flex-pack: end;
      justify-content: flex-end;
  overflow: hidden;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  .md-tab--multiline {
    font-size: 12px;
    padding-bottom: 12px; }
  .md-tab--icon {
    height: 72px;
    padding-bottom: 16px; }
  .md-tab--inactive {
    color: rgba(255, 255, 255, 0.7); }

.md-tab-indicator {
  background: #28993F;
  bottom: 0;
  content: '';
  height: 2px;
  left: 0;
  position: absolute; }

.md-btn--tab-overflow {
  bottom: 2px;
  position: absolute; }
  .md-btn--tab-overflow--icon {
    bottom: 8px; }
  .md-btn--tab-overflow-left {
    left: 6px;
    z-index: 3; }
  .md-btn--tab-overflow-right {
    right: 12px; }

.md-menu--tab {
  margin-right: 100%; }
  .md-menu--tab .md-icon {
    color: inherit; }
  .md-menu--tab .md-tab {
    padding-top: 12px; }

.md-icon--tab {
  color: inherit;
  margin-bottom: 10px; }

.md-tab-toolbar {
  width: 100%; }

.md-tabs-fixed-container {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15; }

.md-tabs-content--offset {
  margin-top: 48px; }
  .md-tabs-content--offset-icon {
    margin-top: 72px; }

.md-tabs-content--offset-toolbar-prominent {
  margin-top: 176px; }
  .md-tabs-content--offset-toolbar-prominent-icon {
    margin-top: 200px; }

@media screen and (min-width: 320px) {
  .md-toolbar ~ .md-tabs {
    margin-top: -1px; }
  .md-tabs {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto; }
  .md-tab {
    max-width: calc(100vw - 56px);
    min-width: 72px; }
  .md-tab-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px; } }

@media screen and (min-width: 320px) and (max-aspect-ratio: 13 / 9) {
  .md-tabs-content--offset-toolbar {
    margin-top: 104px; }
    .md-tabs-content--offset-toolbar-icon {
      margin-top: 128px; } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-tabs-content--offset-toolbar {
    margin-top: 96px; }
    .md-tabs-content--offset-toolbar-icon {
      margin-top: 120px; } }

@media screen and (min-width: 768px) {
  .md-tabs-content--offset-toolbar {
    margin-top: 112px; }
    .md-tabs-content--offset-toolbar-icon {
      margin-top: 136px; } }

@media screen and (min-width: 1025px) {
  .md-toolbar ~ .md-tabs {
    margin-top: 0; }
  .md-tabs {
    overflow: visible; }
  .md-tab {
    max-width: 264px;
    min-width: 160px;
    padding-left: 24px;
    padding-right: 24px; }
  .md-tab-label {
    font-size: 13px;
    line-height: 13px; }
  .md-icon--tab {
    margin-bottom: 12px;
    margin-top: 2px; } }

.md-text-field-container {
  position: relative; }

.md-text-field-container--input {
  line-height: 1.15; }

.md-text-field-container--input-block {
  display: -ms-flexbox;
  display: flex; }
  .md-text-field-container--input-block .md-text-field-message-container {
    -ms-flex-negative: 0;
        flex-shrink: 0; }

.md-text-field-multiline-container,
.md-text-field-container--multiline {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: height;
  transition-property: height; }

.md-text-field-multiline-container {
  position: relative; }

.md-text-field-container--multiline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.md-text-field {
  background: none;
  border: 0;
  line-height: inherit;
  padding: 0;
  width: 100%; }
  .md-text-field:placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field:-moz-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field::-moz-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.54); }
  .md-text-field:focus {
    outline-style: none; }
  .md-text-field[type="search"] {
    -webkit-appearance: textfield; }
  .md-text-field:-webkit-autofill, .md-text-field:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 50px #fff inset;
            box-shadow: 0 0 0 50px #fff inset; }
  .md-text-field--margin {
    margin-top: 13px; }
  .md-text-field[disabled]:placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]:-moz-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]::-moz-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38); }
  .md-text-field[disabled]::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38); }

.md-text-field--multiline {
  padding-bottom: 0;
  padding-top: 0;
  resize: none; }

.md-text-field--multiline-mask {
  overflow: hidden;
  position: absolute;
  visibility: hidden; }

.md-divider--text-field {
  margin-top: 7px;
  overflow: visible; }
  .md-divider--text-field::after {
    background: #28993F;
    content: '';
    display: block;
    height: 2px;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: width;
    transition-property: width;
    width: 0; }

.md-divider--text-field-expanded::after {
  width: 100%; }

.md-divider--text-field-error,
.md-divider--text-field-error::after {
  background: #f44336; }

.md-divider--expand-from-left::after {
  left: 0; }

.md-divider--expand-from-center::after {
  margin: auto; }

.md-divider--expand-from-right::after {
  right: 0; }

.md-floating-label {
  cursor: text;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: font-size, color, -webkit-transform;
  transition-property: font-size, color, -webkit-transform;
  transition-property: transform, font-size, color;
  transition-property: transform, font-size, color, -webkit-transform;
  white-space: nowrap; }

.md-floating-label--floating {
  font-size: 12px; }

.md-floating-label--icon-offset {
  left: 40px; }
  @media screen and (min-width: 1025px) {
    .md-floating-label--icon-offset {
      left: 36px; } }

.md-text-field-message-container {
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.md-text-field-message-container--count-only {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.md-text-field-message-container--left-icon-offset {
  padding-left: 40px; }

.md-text-field-message-container--right-icon-offset {
  padding-right: 40px; }

.md-text-field-message {
  color: inherit;
  -ms-flex-positive: 1;
      flex-grow: 1;
  line-height: 1.42857;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  white-space: normal; }

.md-text-field-message--counter {
  display: block;
  -ms-flex-positive: 0;
      flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-left: 16px; }

.md-text-field-message--inactive {
  opacity: 0; }

.md-text-field-message--active {
  opacity: 1; }

.md-text-field-divider-container {
  display: inline-block; }

.md-text-field-divider-container--grow {
  display: block;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.md-text-field-icon + .md-text-field-divider-container,
.md-text-field-divider-container + .md-text-field-icon {
  margin-left: 16px; }

.md-text-field-icon-container {
  -ms-flex-align: end;
      align-items: flex-end;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }
  .md-text-field-container--input-block .md-text-field-icon-container {
    -ms-flex-align: center;
        align-items: center; }

.md-text-field-inline-indicator {
  position: absolute;
  right: 0; }

.md-text-field--inline-indicator {
  width: calc(100% - 24px); }

.md-password-btn {
  background: transparent;
  border: 0;
  height: 24px;
  padding: 0;
  width: 24px; }
  .md-password-btn:not(.md-password-btn--focus):focus {
    outline-style: none; }
  .md-password-btn .md-icon {
    color: inherit; }

.md-password-btn--active::before {
  background: rgba(0, 0, 0, 0.54); }

.md-password-btn--invisible::before, .md-password-btn--invisible::after {
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 100%; }

.md-password-btn--invisible::before {
  -webkit-transform: translate3d(-1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
          transform: translate3d(-1px, -50%, 0) rotate3d(0, 0, 1, 45deg); }

.md-password-btn--invisible::after {
  background: #fafafa;
  -webkit-transform: translate3d(1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
          transform: translate3d(1px, -50%, 0) rotate3d(0, 0, 1, 45deg); }

@media screen and (min-width: 320px) {
  .md-text-field-container--input-block {
    padding-bottom: 20px;
    padding-top: 20px; }
  .md-text-field-container--multiline-block {
    margin-bottom: 20px;
    margin-top: 20px; }
  .md-text-field-container--padded-block {
    padding-left: 20px;
    padding-right: 20px; }
  .md-text-field {
    font-size: 16px; }
  .md-text-field--floating-margin {
    margin-top: 37px; }
  .md-divider--text-field {
    margin-bottom: 8px; }
  .md-floating-label--inactive {
    -webkit-transform: translate3d(0, 39px, 0);
            transform: translate3d(0, 39px, 0); }
  .md-floating-label--inactive-sized {
    font-size: 16px; }
  .md-floating-label--floating {
    -webkit-transform: translate3d(0, 16px, 0);
            transform: translate3d(0, 16px, 0); }
  .md-text-field-icon--positioned {
    margin-bottom: 13.5px; }
  .md-text-field-inline-indicator {
    top: 9px; }
    .md-text-field-inline-indicator--floating {
      top: 34px; }
    .md-text-field-inline-indicator--block {
      top: 17px; }
  .md-floating-label--inactive-title {
    -webkit-transform: translate3d(0, 42px, 0);
            transform: translate3d(0, 42px, 0); } }

@media screen and (min-width: 1025px) {
  .md-text-field-container--input-block {
    padding-bottom: 16px;
    padding-top: 16px; }
  .md-text-field-container--multiline-block {
    margin-bottom: 16px;
    margin-top: 16px; }
  .md-text-field-container--padded-block {
    padding-left: 16px;
    padding-right: 16px; }
  .md-text-field {
    font-size: 13px; }
  .md-text-field--floating-margin {
    margin-top: 33px; }
  .md-divider--text-field {
    margin-bottom: 4px; }
  .md-floating-label--inactive {
    -webkit-transform: translate3d(0, 33px, 0);
            transform: translate3d(0, 33px, 0); }
  .md-floating-label--inactive-sized {
    font-size: 13px; }
  .md-floating-label--floating {
    -webkit-transform: translate3d(0, 12px, 0);
            transform: translate3d(0, 12px, 0); }
  .md-text-field-icon--positioned {
    margin-bottom: 9.5px; }
  .md-password-btn.md-password-btn {
    height: 20px;
    width: 20px; }
  .md-text-field-message-container--left-icon-offset {
    padding-left: 36px; }
  .md-text-field-message-container--right-icon-offset {
    padding-right: 36px; }
  .md-text-field-inline-indicator {
    top: 9px; }
    .md-text-field-inline-indicator--floating {
      top: 30px; }
    .md-text-field-inline-indicator--block {
      top: 12px; }
  .md-floating-label--inactive-title {
    -webkit-transform: translate3d(0, 36px, 0);
            transform: translate3d(0, 36px, 0); } }

.md-text-field--title {
  font-size: 34px; }

.md-floating-label--title {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s; }

.md-floating-label--inactive-title {
  font-size: 34px; }

@media screen and (min-width: 320px) {
  .md-toolbar-relative {
    margin-top: 56px; }
    .md-toolbar-relative--padding {
      padding-top: 56px; }
  .md-toolbar {
    height: 56px; }
  .md-btn--toolbar {
    margin-bottom: 4px;
    margin-top: 4px; }
  .md-toolbar .md-btn--text {
    margin-bottom: 10px;
    margin-top: 10px; }
  .md-toolbar--action-left {
    margin-left: 4px; }
  .md-toolbar--action-right {
    margin-right: 4px; }
  .md-title--toolbar {
    line-height: 56px;
    margin-left: 20px; }
    .md-title--toolbar-offset {
      margin-left: 72px; }
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 6px;
    margin-top: 6px; } }

@media screen and (min-width: 320px) and (min-aspect-ratio: 13 / 9) {
  .md-toolbar-relative {
    margin-top: 48px; }
    .md-toolbar-relative--padding {
      padding-top: 48px; }
  .md-toolbar {
    height: 48px; }
  .md-toolbar--action-left {
    margin-left: 4px; }
  .md-toolbar--action-right {
    margin-right: 4px; }
  .md-title--toolbar {
    line-height: 48px;
    margin-left: 20px; }
    .md-title--toolbar-offset {
      margin-left: 72px; } }

@media screen and (min-width: 768px) {
  .md-toolbar-relative {
    margin-top: 64px; }
    .md-toolbar-relative--padding {
      padding-top: 64px; }
  .md-toolbar {
    height: 64px; }
  .md-btn--toolbar {
    margin-bottom: 8px;
    margin-top: 8px; }
  .md-toolbar .md-btn--text {
    margin-bottom: 14px;
    margin-top: 14px; }
  .md-toolbar--action-left {
    margin-left: 12px; }
  .md-toolbar--action-right {
    margin-right: 12px; }
  .md-title--toolbar {
    line-height: 64px;
    margin-left: 20px; }
    .md-title--toolbar-offset {
      margin-left: 80px; }
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 10px;
    margin-top: 10px; } }

@media screen and (min-width: 768px) and (min-aspect-ratio: 13 / 9) {
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 12px;
    margin-top: 12px; } }

@media screen and (min-width: 1025px) {
  .md-toolbar--action-left {
    margin-left: 14px; }
  .md-toolbar--action-right {
    margin-right: 14px; }
  .md-title--toolbar {
    margin-left: 26px; }
    .md-title--toolbar-offset {
      margin-left: 80px; }
  .md-btn--toolbar {
    margin-bottom: 12px;
    margin-top: 12px; }
  .md-toolbar .md-btn--text {
    margin-bottom: 16px;
    margin-top: 16px; } }

.md-toolbar {
  display: -ms-flexbox;
  display: flex; }
  .md-toolbar--fixed {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 15; }
  .md-toolbar--inset {
    margin: 8px;
    max-width: calc(100vw - 16px); }
  .md-toolbar--text-white {
    color: #fff; }
    .md-toolbar--text-white .md-btn, .md-toolbar--text-white .md-icon, .md-toolbar--text-white .md-title--toolbar, .md-toolbar--text-white .md-text-field, .md-toolbar--text-white .md-select-field {
      color: inherit; }
    .md-toolbar--text-white :placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white :-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white ::-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white :-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .md-toolbar--text-white ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
  .md-toolbar--themed {
    background: #f5f5f5; }
    .md-toolbar--themed .md-title--toolbar {
      color: rgba(0, 0, 0, 0.87); }
  .md-toolbar--prominent {
    height: 128px; }

.md-title--toolbar {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }
  .md-title--toolbar-prominent {
    position: absolute;
    -webkit-transform: translate3d(0, 64px, 0);
            transform: translate3d(0, 64px, 0); }

.md-toolbar-relative--prominent {
  margin-top: 128px; }
  .md-toolbar-relative--prominent-padding {
    padding-top: 128px; }

.md-toolbar .md-text-field-container {
  padding-bottom: 0;
  padding-top: 0; }

.md-toolbar .md-text-field--toolbar {
  font-size: 20px; }

.md-toolbar .md-autocomplete-container,
.md-toolbar .md-autocomplete {
  height: 100%; }

@media screen and (min-width: 320px) {
  .md-tooltip {
    font-size: 14px;
    padding: 9px 16px; }
    .md-tooltip--top {
      top: -24px; }
    .md-tooltip--right {
      right: -24px; }
    .md-tooltip--bottom {
      bottom: -24px; }
    .md-tooltip--left {
      left: -24px; } }

@media screen and (min-width: 1025px) {
  .md-tooltip {
    font-size: 10px;
    padding: 6px 8px; }
    .md-tooltip--top {
      top: -14px; }
    .md-tooltip--right {
      right: -14px; }
    .md-tooltip--bottom {
      bottom: -14px; }
    .md-tooltip--left {
      left: -14px; } }

.md-tooltip-container {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  z-index: 1; }

.md-tooltip {
  background: #616161;
  color: #fff;
  display: block;
  font-weight: 500;
  opacity: 0.9;
  outline-style: none;
  position: absolute;
  white-space: nowrap; }
  .md-tooltip--active {
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform; }
  .md-tooltip--enter, .md-tooltip--leave-active {
    opacity: 0; }
  .md-tooltip--enter-active {
    opacity: 0.9;
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  .md-tooltip--leave-active {
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 1, 1); }
  .md-tooltip--horizontal {
    left: 50%;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); }
  .md-tooltip--vertical {
    top: 50%;
    -webkit-transform: translate3d(0, -50%, 0);
            transform: translate3d(0, -50%, 0); }
  .md-tooltip--top-active {
    -webkit-transform: translate3d(-50%, -100%, 0);
            transform: translate3d(-50%, -100%, 0); }
  .md-tooltip--right-active {
    -webkit-transform: translate3d(100%, -50%, 0);
            transform: translate3d(100%, -50%, 0); }
  .md-tooltip--bottom-active {
    -webkit-transform: translate3d(-50%, 100%, 0);
            transform: translate3d(-50%, 100%, 0); }
  .md-tooltip--left-active {
    -webkit-transform: translate3d(-100%, -50%, 0);
            transform: translate3d(-100%, -50%, 0); }

.md-text {
  color: rgba(0, 0, 0, 0.87); }
  .md-text--secondary {
    color: rgba(0, 0, 0, 0.54); }
  .md-text--disabled {
    color: rgba(0, 0, 0, 0.38); }
  .md-text--theme-primary {
    color: #28993F; }
  .md-text--theme-secondary {
    color: #28993F; }
  .md-text--error {
    color: #f44336; }
  .md-text--inherit.md-text--inherit {
    color: inherit; }

.md-ink--primary .md-ink {
  background: rgba(40, 153, 63, 0.26); }

.md-ink--secondary .md-ink {
  background: rgba(40, 153, 63, 0.26); }

.md-background {
  background: #fafafa; }
  .md-background--card {
    background: #fff; }
  .md-background--primary {
    background: #28993F;
    color: #fff; }
  .md-background--secondary {
    background: #28993F;
    color: #fff; }

@media screen and (min-width: 1025px) {
  .md-background--primary-hover:hover {
    background: rgba(40, 153, 63, 0.9); }
  .md-background--secondary-hover:hover {
    background: rgba(40, 153, 63, 0.9); } }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
      order: -1; }

.order-last {
  -ms-flex-order: 13;
      order: 13; }

.order-0 {
  -ms-flex-order: 0;
      order: 0; }

.order-1 {
  -ms-flex-order: 1;
      order: 1; }

.order-2 {
  -ms-flex-order: 2;
      order: 2; }

.order-3 {
  -ms-flex-order: 3;
      order: 3; }

.order-4 {
  -ms-flex-order: 4;
      order: 4; }

.order-5 {
  -ms-flex-order: 5;
      order: 5; }

.order-6 {
  -ms-flex-order: 6;
      order: 6; }

.order-7 {
  -ms-flex-order: 7;
      order: 7; }

.order-8 {
  -ms-flex-order: 8;
      order: 8; }

.order-9 {
  -ms-flex-order: 9;
      order: 9; }

.order-10 {
  -ms-flex-order: 10;
      order: 10; }

.order-11 {
  -ms-flex-order: 11;
      order: 11; }

.order-12 {
  -ms-flex-order: 12;
      order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-align: center;
          align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
              box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0;
            flex: 1 0;
        -ms-flex-direction: column;
            flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
      .card-group > .card {
        -ms-flex: 1 0;
            flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start; }

.media-body {
  -ms-flex: 1 1;
      flex: 1 1; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-horizontal {
  -ms-flex-direction: row;
      flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
        flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

:root {
  --black: #1c1e21;
  --blueberry: #28993F;
  --body-copy: #696f76;
  --brown-grey: #8e8e8e;
  --gunmetal-grey: #FDC47B;
  --jade: #1e9a93;
  --light-grey: #c4c4c4;
  --sand-grey: #f3f2f1;
  --tealish: #20a49d; }

header {
  width: 100%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f6f6f6), to(#f6f6f6));
  background-image: linear-gradient(to top, #f6f6f6, #f6f6f6); }
  header img {
    font-size: 10px; }
  header .logo {
    height: 70px;
    margin: 10px; }
    @media (max-width: 575.98px) {
      header .logo {
        height: 45px;
        margin: 20px 10px 20px 5px; } }
  header .heading {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-item-align: center;
        align-self: center; }
    header .heading .heading-text {
      font-weight: 300;
      line-height: 38px;
      color: #000000;
      font-size: 28px;
      font-size: 1.75rem; }
      @media (max-width: 575.98px) {
        header .heading .heading-text {
          font-size: 16px;
          font-size: 1rem; } }
  header .cta {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
        align-items: flex-end;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-item-align: center;
        align-self: center;
    color: #ffffff;
    padding-right: 0; }
    header .cta .header-cta-wrapper {
      background-color: #181818;
      padding: 16px 30px; }
      @media (max-width: 767.98px) {
        header .cta .header-cta-wrapper {
          background-color: unset; } }
      header .cta .header-cta-wrapper h4 {
        margin-bottom: 0; }
      header .cta .header-cta-wrapper span {
        font-size: 14px;
        font-size: 0.875rem; }
        @media (max-width: 575.98px) {
          header .cta .header-cta-wrapper span {
            font-size: 10px;
            font-size: 0.625rem; } }
      header .cta .header-cta-wrapper .button-wrapper {
        -ms-flex-item-align: center;
            align-self: center; }
        header .cta .header-cta-wrapper .button-wrapper button.primary {
          width: 200px;
          height: 55px;
          background: #28993F;
          border-radius: 42px;
          border: none;
          font-weight: 600;
          font-size: 19px;
          text-align: center;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          outline: none;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
          width: 160px;
          border: 1px solid #28993F;
          color: #ffffff; }
          @supports (-ms-ime-align: auto) {
            header .cta .header-cta-wrapper .button-wrapper button.primary {
              -ms-flex-pack: center;
                  justify-content: center;
              /* Edge only */ } }
          header .cta .header-cta-wrapper .button-wrapper button.primary:hover {
            text-decoration: none; }
          @media (max-width: 575.98px) {
            header .cta .header-cta-wrapper .button-wrapper button.primary span {
              padding-left: 10px;
              padding-right: 10px; } }
          @media (max-width: 575.98px) {
            header .cta .header-cta-wrapper .button-wrapper button.primary {
              width: auto;
              padding-left: 20px;
              padding-right: 20px; } }
        header .cta .header-cta-wrapper .button-wrapper button.secondary {
          width: 200px;
          height: 55px;
          background: #FDC47B;
          border-radius: 42px;
          border: none;
          font-weight: 600;
          font-size: 19px;
          text-align: center;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          outline: none;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
          width: 160px;
          border: 1px solid #FDC47B;
          color: #000000; }
          @supports (-ms-ime-align: auto) {
            header .cta .header-cta-wrapper .button-wrapper button.secondary {
              -ms-flex-pack: center;
                  justify-content: center;
              /* Edge only */ } }
          header .cta .header-cta-wrapper .button-wrapper button.secondary:hover {
            text-decoration: none; }
          @media (max-width: 575.98px) {
            header .cta .header-cta-wrapper .button-wrapper button.secondary span {
              padding-left: 10px;
              padding-right: 10px; } }
          @media (max-width: 575.98px) {
            header .cta .header-cta-wrapper .button-wrapper button.secondary {
              width: auto;
              padding-left: 20px;
              padding-right: 20px; } }
        @media (max-width: 575.98px) {
          header .cta .header-cta-wrapper .button-wrapper button.primary, header .cta .header-cta-wrapper .button-wrapper button.secondary {
            line-height: 37px;
            height: 40px;
            font-size: 15px;
            width: 120px; } }

footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  padding: 30px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f6f6f6), to(#f6f6f6));
  background-image: linear-gradient(to top, #f6f6f6, #f6f6f6);
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  footer div {
    padding: 10px; }
  footer a, footer a:hover, footer a:visited, footer a:active {
    color: #28993F;
    word-break: break-all; }
  @media (max-width: 991.98px) {
    footer {
      -ms-flex-direction: column;
          flex-direction: column; } }

.bouncing-loader, .bouncing-loader-text-wrapper {
  -ms-flex-direction: row !important;
      flex-direction: row !important;
  -ms-flex-pack: center;
      justify-content: center;
  display: -ms-flexbox;
  display: flex; }

.bouncing-loader {
  height: 95px;
  -webkit-transform: translateY(50px);
          transform: translateY(50px); }

.bouncing-loader__item-1 {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: #28993F;
  border-radius: 50%;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
          animation: bouncing-loader 0.6s infinite alternate; }
  @media (max-width: 575.98px) {
    .bouncing-loader__item-1 {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }

.bouncing-loader__item-2 {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: #28993F;
  border-radius: 50%;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
          animation: bouncing-loader 0.6s infinite alternate;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
  @media (max-width: 575.98px) {
    .bouncing-loader__item-2 {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }

.bouncing-loader__item-3 {
  width: 1rem;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: #28993F;
  border-radius: 50%;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
          animation: bouncing-loader 0.6s infinite alternate;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
  @media (max-width: 575.98px) {
    .bouncing-loader__item-3 {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }

@-webkit-keyframes bouncing-loader {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0.1;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); } }

@keyframes bouncing-loader {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    opacity: 0.1;
    -webkit-transform: translateY(-1rem);
            transform: translateY(-1rem); } }

.toast-container {
  top: 40% !important;
  left: 50%;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media (max-width: 767.98px) {
    .toast-container {
      left: unset;
      width: 85%; } }
  .toast-container > div {
    -webkit-box-shadow: 0px 0px 24px 3px rgba(149, 149, 149, 0.5);
            box-shadow: 0px 0px 24px 3px rgba(149, 149, 149, 0.5);
    background-size: 48px;
    background-position: center 40px !important;
    background-repeat: no-repeat;
    border-radius: 3px !important;
    background-color: #ffffff;
    min-height: 188px;
    height: auto;
    margin: 0; }
    .toast-container > div.Toastify__toast--success {
      background-image: url(/static/media/success.8c00317f.svg); }
    .toast-container > div.Toastify__toast--error {
      background-image: url(/static/media/error.f6208b81.svg); }
    .toast-container > div.Toastify__toast--warning {
      background-image: url(/static/media/warning.4dd68741.svg); }
    .toast-container > div .Toastify__close-button {
      display: none;
      width: 0; }

.toast-body {
  color: #3f3f3f;
  font-size: 24px;
  margin: 100px 0 30px !important; }

.react-joyride__tooltip h4 {
  color: #000000;
  text-align: left;
  font-size: 28px !important;
  font-weight: 600;
  padding: 0 10px; }

.react-joyride__tooltip .tooltip-content {
  text-align: left;
  color: #000000;
  font-weight: 300; }

.react-joyride__tooltip button[data-test-id="button-primary"] {
  width: 200px;
  height: 55px;
  background: #FDC47B;
  border-radius: 42px;
  border: none;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  width: 95px;
  height: 40px;
  outline: none;
  font-size: 19px !important;
  border-radius: 20px !important;
  color: #000000 !important; }
  @supports (-ms-ime-align: auto) {
    .react-joyride__tooltip button[data-test-id="button-primary"] {
      -ms-flex-pack: center;
          justify-content: center;
      /* Edge only */ } }
  .react-joyride__tooltip button[data-test-id="button-primary"]:hover {
    text-decoration: none; }
  @media (max-width: 575.98px) {
    .react-joyride__tooltip button[data-test-id="button-primary"] span {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 575.98px) {
    .react-joyride__tooltip button[data-test-id="button-primary"] {
      width: auto;
      padding-left: 20px;
      padding-right: 20px; } }

.tooltip-action {
  text-align: left;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  font-size: 1rem; }

button.react-joyride__beacon {
  outline: none !important; }

@media (max-width: 767.98px) {
  .__floater {
    visibility: hidden !important; } }

.__floater.__floater__open {
  -webkit-transition: none !important;
  transition: none !important; }

.mobile-tooltip {
  display: none; }
  @media (max-width: 767.98px) {
    .mobile-tooltip {
      z-index: 2000;
      display: block;
      position: fixed;
      bottom: 0;
      width: 100%;
      cursor: pointer;
      background-color: #f6f6f6;
      -webkit-box-shadow: 0px -5px 13px -2px rgba(155, 155, 155, 0.5);
              box-shadow: 0px -5px 13px -2px rgba(155, 155, 155, 0.5); } }
  .mobile-tooltip .tooltip-content {
    padding: 10px 10px 20px 17px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column; }
  .mobile-tooltip .tooltip-step {
    color: #28993F;
    font-size: 18px;
    font-size: 1.125rem; }
  .mobile-tooltip .tooltip-close {
    position: absolute;
    top: 0;
    right: 10px;
    padding: 15px; }

.disclaimer {
  position: absolute;
  z-index: 100;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  background: #f6f6f6;
  width: 100vw;
  padding: 25px;
  border-bottom: 1px solid #dddddd; }
  .disclaimer .disclaimer-text {
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    white-space: normal;
    margin-right: 20px; }
    @media (max-width: 575.98px) {
      .disclaimer .disclaimer-text {
        font-size: 90% !important; } }
  .disclaimer .disclaimer-link, .disclaimer .disclaimer-link:hover, .disclaimer .disclaimer-link:visited {
    font-weight: 900;
    font-size: 18px;
    color: #28993F;
    text-decoration: underline; }
    .disclaimer .disclaimer-link:hover, .disclaimer .disclaimer-link:hover:hover, .disclaimer .disclaimer-link:visited:hover {
      text-decoration: none; }
    @media (max-width: 575.98px) {
      .disclaimer .disclaimer-link, .disclaimer .disclaimer-link:hover, .disclaimer .disclaimer-link:visited {
        font-size: 90% !important; } }
  .disclaimer .button {
    width: 200px;
    height: 55px;
    background: #28993F;
    border-radius: 42px;
    border: none;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
    @supports (-ms-ime-align: auto) {
      .disclaimer .button {
        -ms-flex-pack: center;
            justify-content: center;
        /* Edge only */ } }
    .disclaimer .button:hover {
      text-decoration: none; }
    @media (max-width: 575.98px) {
      .disclaimer .button span {
        padding-left: 10px;
        padding-right: 10px; } }
    @media (max-width: 575.98px) {
      .disclaimer .button {
        width: auto;
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 575.98px) {
      .disclaimer .button {
        line-height: 37px;
        height: 40px;
        font-size: 15px;
        width: 140px; } }

.login-page .roles-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: center;
      align-content: center;
  margin: 40px;
  height: 700px; }
  @media (max-width: 991.98px) {
    .login-page .roles-wrapper {
      margin: 20px;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-wrap: unset;
          flex-wrap: unset;
      height: unset;
      -ms-flex-align: center;
          align-items: center; } }
  .login-page .roles-wrapper .role-wrapper {
    width: 50%;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
        align-items: stretch;
    height: 350px;
    max-width: 600px; }
    @media (max-width: 991.98px) {
      .login-page .roles-wrapper .role-wrapper {
        width: auto;
        height: auto;
        -ms-flex: unset;
            flex: unset; } }
    .login-page .roles-wrapper .role-wrapper .role-wrapper-inner {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      background: #f6f6f6;
      margin: 5px;
      padding: 30px; }
      @media (max-width: 767.98px) {
        .login-page .roles-wrapper .role-wrapper .role-wrapper-inner {
          padding: 10px; } }
      .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-loader {
        height: 95px; }
      .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-icon {
        width: 92px;
        height: 87px;
        border: 1px solid #dadada;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        margin: 0px 20px 20px 0px;
        padding: 10px 20px 20px 17px;
        border-radius: 46px; }
        .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-icon img {
          width: 56px;
          height: 63px; }
      .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-name {
        font-size: 28px;
        font-size: 1.75rem;
        color: #000000;
        font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 300;
        margin-bottom: 20px; }
        @media (max-width: 575.98px) {
          .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-name {
            font-size: 18px;
            font-size: 1.125rem; } }
      .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-description {
        padding-bottom: 20px; }
      .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-cta {
        margin: 20px 0; }
        .login-page .roles-wrapper .role-wrapper .role-wrapper-inner .role-cta .button {
          outline: none; }
    @media (min-width: 992px) {
      .login-page .roles-wrapper .role-wrapper:first-child {
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
        width: 50%;
        height: 700px; }
        .login-page .roles-wrapper .role-wrapper:first-child .role-wrapper-inner {
          -ms-flex-direction: column;
              flex-direction: column;
          width: 100%; }
          .login-page .roles-wrapper .role-wrapper:first-child .role-wrapper-inner .role-icon {
            width: 184px;
            height: 174px;
            padding: 20px 20px 20px 35px;
            border-radius: 100px; }
            .login-page .roles-wrapper .role-wrapper:first-child .role-wrapper-inner .role-icon img {
              width: 104px;
              height: 122px; }
          .login-page .roles-wrapper .role-wrapper:first-child .role-wrapper-inner .role-description {
            max-width: 500px; } }

.login-page .cta-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 13px -2px rgba(155, 155, 155, 0.5);
          box-shadow: 0px 5px 13px -2px rgba(155, 155, 155, 0.5);
  padding: 15px; }
  .login-page .cta-wrapper .button {
    width: 200px;
    height: 55px;
    background: #28993F;
    border-radius: 42px;
    border: none;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    width: 350px;
    margin: auto;
    font-size: 18px;
    padding: 26px;
    line-height: 2px;
    border-radius: 45px; }
    @supports (-ms-ime-align: auto) {
      .login-page .cta-wrapper .button {
        -ms-flex-pack: center;
            justify-content: center;
        /* Edge only */ } }
    .login-page .cta-wrapper .button:hover {
      text-decoration: none; }
    @media (max-width: 575.98px) {
      .login-page .cta-wrapper .button span {
        padding-left: 10px;
        padding-right: 10px; } }
    @media (max-width: 575.98px) {
      .login-page .cta-wrapper .button {
        width: auto;
        padding-left: 20px;
        padding-right: 20px; } }

.cta-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: center;
      justify-content: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 5px 13px -2px rgba(155, 155, 155, 0.5);
          box-shadow: 0px 5px 13px -2px rgba(155, 155, 155, 0.5);
  padding: 15px; }
  .cta-wrapper .button {
    width: 200px;
    height: 55px;
    background: #28993F;
    border-radius: 42px;
    border: none;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    width: 350px;
    margin: auto;
    font-size: 18px;
    padding: 26px;
    line-height: 2px;
    border-radius: 45px; }
    @supports (-ms-ime-align: auto) {
      .cta-wrapper .button {
        -ms-flex-pack: center;
            justify-content: center;
        /* Edge only */ } }
    .cta-wrapper .button:hover {
      text-decoration: none; }
    @media (max-width: 575.98px) {
      .cta-wrapper .button span {
        padding-left: 10px;
        padding-right: 10px; } }
    @media (max-width: 575.98px) {
      .cta-wrapper .button {
        width: auto;
        padding-left: 20px;
        padding-right: 20px; } }

@media (max-width: 991.98px) {
  .list-all {
    width: 90%; } }

.md-data-table--responsive {
  max-width: 1092px;
  margin: 0 auto; }
  .md-data-table--responsive thead tr.md-table-row, .md-data-table--responsive tbody tr.md-table-row {
    border-bottom: 1px solid #c9c9c9; }
  .md-data-table--responsive tbody tr.md-table-row {
    cursor: pointer; }
  .md-data-table--responsive .md-table-column {
    font-size: 18px;
    font-weight: 700;
    width: 33.3%;
    height: 81px; }
    @media (max-width: 575.98px) {
      .md-data-table--responsive .md-table-column {
        font-size: 14px; } }
    .md-data-table--responsive .md-table-column.md-text {
      color: #3f3f3f; }
    .md-data-table--responsive .md-table-column.md-text--secondary {
      color: #979797; }
    @media (max-width: 575.98px) {
      .md-data-table--responsive .md-table-column:first-child {
        padding-right: 10px; } }
  .md-data-table--responsive .users-container {
    background: #eeeeee; }

.react-autosuggest__container {
  padding-top: 24px;
  margin: 0 auto;
  text-align: center;
  max-width: 1092px;
  width: 100%; }
  @media (max-width: 1092px) {
    .react-autosuggest__container {
      padding: 24px 20px 0;
      margin: 0; } }

.react-autosuggest__input {
  max-width: 1092px;
  width: 100%;
  height: 24px;
  padding: 20px 20px 20px 60px;
  font-size: 28px;
  border: 1px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  color: #3f3f3f;
  background-color: transparent;
  background-image: url(/static/media/search.f2164c66.svg);
  background-size: 20px;
  background-position: 0px;
  background-repeat: no-repeat;
  margin-bottom: 1px; }
  .react-autosuggest__input:focus {
    border-bottom: 3px solid #28993F; }
  .react-autosuggest__input::-webkit-input-placeholder {
    font-size: 18px;
    color: #979797; }
  .react-autosuggest__input::-moz-placeholder {
    font-size: 18px;
    color: #979797; }
  .react-autosuggest__input:-ms-input-placeholder {
    font-size: 18px;
    color: #979797; }
  .react-autosuggest__input::-ms-input-placeholder {
    font-size: 18px;
    color: #979797; }
  .react-autosuggest__input::placeholder {
    font-size: 18px;
    color: #979797; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container--open {
  width: 100%;
  position: relative;
  top: -1px;
  left: 1px;
  border: 1px solid #e4e4e4;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0;
  border-radius: 6px; }

.react-autosuggest__suggestion--highlighted {
  background-color: #efefef; }

.react-autosuggest__section-container {
  border-top: 1px solid #efefef; }

.react-autosuggest__section-container--first {
  border-top: 0; }

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #3f3f3f; }

.form-page .button {
  margin: 0 10px; }

.form-page .secondary {
  width: 200px;
  height: 55px;
  background: #ffffff;
  border-radius: 42px;
  border: none;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  color: #000000;
  border: 1px solid #28993F; }
  @supports (-ms-ime-align: auto) {
    .form-page .secondary {
      -ms-flex-pack: center;
          justify-content: center;
      /* Edge only */ } }
  .form-page .secondary:hover {
    text-decoration: none; }
  @media (max-width: 575.98px) {
    .form-page .secondary span {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 575.98px) {
    .form-page .secondary {
      width: auto;
      padding-left: 20px;
      padding-right: 20px; } }

.form-page .bouncing-loader {
  margin-bottom: 50px; }

.form-page .form-wrapper {
  max-width: 630px;
  width: 40%;
  margin: 30px auto 0; }
  .form-page .form-wrapper__qr-code-btn-container {
    display: -ms-flexbox;
    display: flex; }
  .form-page .form-wrapper .md-dialog-footer--inline {
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
  .form-page .form-wrapper .md-grid > div {
    width: 100%; }
  .form-page .form-wrapper .md-grid .input-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .form-page .form-wrapper .md-grid #video-area {
    border: 1px solid gray;
    width: 100%; }
  .form-page .form-wrapper .md-grid input {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px; }
  .form-page .form-wrapper .md-grid .md-divider {
    background-color: #979797; }
  .form-page .form-wrapper .md-grid .md-floating-label {
    color: #3f3f3f;
    font-size: 18px;
    font-weight: 400;
    padding-left: 10px; }
  .form-page .form-wrapper .md-grid .md-layover {
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #979797;
    margin-bottom: 20px; }
    .form-page .form-wrapper .md-grid .md-layover .md-select-field--btn {
      color: #3f3f3f;
      font-size: 18px;
      font-weight: 700;
      padding-left: 10px; }
    .form-page .form-wrapper .md-grid .md-layover .md-icon-separator {
      padding-right: 0; }
  .form-page .form-wrapper .md-grid .md-tile-content .md-text {
    color: #3f3f3f;
    font-size: 18px;
    font-weight: 700; }
  .form-page .form-wrapper .md-grid .md-list--menu-below {
    top: 80%; }
  .form-page .form-wrapper .p-center {
    text-align: center;
    margin-bottom: 40px; }
  .form-page .form-wrapper .image-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in;
    transition: opacity 0.5s ease-in;
    pointer-events: none;
    height: 0; }
    .form-page .form-wrapper .image-wrapper img {
      max-height: 400px;
      min-height: 340px; }
      @media (max-width: 500px) {
        .form-page .form-wrapper .image-wrapper img {
          min-height: unset;
          max-height: unset;
          width: 80%; } }
    .form-page .form-wrapper .image-wrapper.fade-in {
      opacity: 1;
      height: auto;
      margin-bottom: 40px; }
      @media (max-width: 500px) {
        .form-page .form-wrapper .image-wrapper.fade-in {
          padding: 0px; } }

@media (max-width: 900px) {
  .form-page .form-wrapper {
    width: 70%; } }

@media (max-width: 500px) {
  .form-page .form-wrapper {
    width: 85%; } }

.partners {
  padding: 40px 100px 40px 100px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center; }
  @media (max-width: 575.98px) {
    .partners {
      -ms-flex-direction: column;
          flex-direction: column;
      padding: 20px; } }
  .partners img {
    margin: 20px;
    max-height: 40px;
    max-width: 180px; }
  .partners.dark {
    background-color: #f9f9f9; }

.details-page .secondary {
  width: 200px;
  height: 55px;
  background: #ffffff;
  border-radius: 42px;
  border: none;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  border: #28993F 1px solid;
  color: #28993F;
  margin-right: 20px; }
  @supports (-ms-ime-align: auto) {
    .details-page .secondary {
      -ms-flex-pack: center;
          justify-content: center;
      /* Edge only */ } }
  .details-page .secondary:hover {
    text-decoration: none; }
  @media (max-width: 575.98px) {
    .details-page .secondary span {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 575.98px) {
    .details-page .secondary {
      width: auto;
      padding-left: 20px;
      padding-right: 20px; } }

.details-page .loader-wrapper {
  height: 130px;
  width: 100%;
  margin: 30px auto; }

.details-page .details-wrapper {
  max-width: 1092px;
  width: 90%;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto; }
  .details-page .details-wrapper .md-block-centered {
    width: 100%; }
  .details-page .details-wrapper .route-cta-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    margin: 20px 0 30px; }
    @media (max-width: 767.98px) {
      .details-page .details-wrapper .route-cta-wrapper {
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: baseline;
            align-items: baseline;
        margin: 20px; } }
    @media (max-width: 768px) {
      .details-page .details-wrapper .route-cta-wrapper {
        margin: 20px; } }
    .details-page .details-wrapper .route-cta-wrapper .ca-title {
      display: none; }
      @media (max-width: 991.98px) {
        .details-page .details-wrapper .route-cta-wrapper .ca-title {
          display: block;
          font-size: 22px;
          font-weight: 700;
          color: #3f3f3f;
          margin: 25px 0 20px;
          margin-right: 20px; } }
    .details-page .details-wrapper .route-cta-wrapper .details-page-button-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row; }
      @media (max-width: 991.98px) {
        .details-page .details-wrapper .route-cta-wrapper .details-page-button-container {
          -ms-flex-direction: column;
              flex-direction: column; } }
      .details-page .details-wrapper .route-cta-wrapper .details-page-button-container .details-page-button {
        width: 200px;
        height: 55px;
        background: #28993F;
        border-radius: 42px;
        border: none;
        font-weight: 600;
        font-size: 19px;
        text-align: center;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        outline: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
        width: auto;
        font-size: 18px;
        font-weight: 700;
        padding: 26px;
        line-height: 2px;
        border-radius: 45px;
        text-transform: unset;
        margin: 0 5px 0 5px; }
        @supports (-ms-ime-align: auto) {
          .details-page .details-wrapper .route-cta-wrapper .details-page-button-container .details-page-button {
            -ms-flex-pack: center;
                justify-content: center;
            /* Edge only */ } }
        .details-page .details-wrapper .route-cta-wrapper .details-page-button-container .details-page-button:hover {
          text-decoration: none; }
        @media (max-width: 575.98px) {
          .details-page .details-wrapper .route-cta-wrapper .details-page-button-container .details-page-button span {
            padding-left: 10px;
            padding-right: 10px; } }
        @media (max-width: 575.98px) {
          .details-page .details-wrapper .route-cta-wrapper .details-page-button-container .details-page-button {
            width: auto;
            padding-left: 20px;
            padding-right: 20px; } }
        @media (max-width: 991.98px) {
          .details-page .details-wrapper .route-cta-wrapper .details-page-button-container .details-page-button {
            white-space: normal;
            line-height: unset;
            padding: 12px 26px;
            margin-bottom: 10px;
            height: 100%; } }

.detail-section-wrapper {
  border-top: 1px solid #c9c9c9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 100px; }
  .detail-section-wrapper .details-section {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-align: center;
        align-items: center;
    width: 34%;
    border-bottom: 1px solid #c9c9c9;
    padding: 10px;
    margin: 0; }
    @media (max-width: 767.98px) {
      .detail-section-wrapper .details-section {
        width: 100%; } }
    @media (min-width: 769px) {
      .detail-section-wrapper .details-section:not(:nth-child(3n)) {
        border-right: 1px solid #c9c9c9;
        width: 33%; } }
    .detail-section-wrapper .details-section > span {
      color: #3f3f3f;
      margin: 0 auto; }
      @media (max-width: 767.98px) {
        .detail-section-wrapper .details-section > span {
          margin: 0; } }
    .detail-section-wrapper .details-section .label {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0px; }
    .detail-section-wrapper .details-section .value {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px; }
    .detail-section-wrapper .details-section .caption {
      font-size: 18px;
      font-weight: 700; }
    .detail-section-wrapper .details-section .value2 {
      font-size: 18px;
      font-weight: 400;
      margin-left: 10px; }

.documents-wrapper {
  max-width: 1092px;
  width: 100%;
  margin: 0 auto; }
  @media (max-width: 991.98px) {
    .documents-wrapper {
      width: 90%; } }
  .documents-wrapper .md-table-row {
    cursor: default; }
    .documents-wrapper .md-table-row:last-child {
      border-bottom: none; }
    @media (max-width: 575.98px) {
      .documents-wrapper .md-table-row .md-table-column--adjusted {
        padding-right: 20px; } }
    @media (max-width: 575.98px) {
      .documents-wrapper .md-table-row .md-table-column {
        width: 90%; } }
    .documents-wrapper .md-table-row .md-table-column a {
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      color: #3f3f3f;
      padding: 2px 0 5px 55px; }
  .documents-wrapper .icon {
    background-repeat: no-repeat;
    background-size: 28px; }
    .documents-wrapper .icon.pdf {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAYAAAHn1vR7AAAABGdBTUEAALGPC/xhBQAAA+BJREFUSA29VktLVVEUXuden4Mi1FtKoiL4mIhQZqIOhJpUv8EIGjYKsgfUsEFGzZsJTWrUqJlRA19XE0FQUAm9ZKZeId96fd2+b92zT+dxjxZFG/bZ6/GttfZeZ521jyX26OvrSxvas1rURCIRqaiokLm5OY9SGYvP/v7+7XQ6XShuRznUpFKp9MjIiCiTn5+vcCp0EI7ZY7O6WHB2H86eGWFVVZWUlpZKPB5/oG6Mgiv3xGlZlji+BwcH2w4PD/sMEMpOpeH6NeJdNwqu7i275UpTqTlwa/Ly8mRvb09FET6bmpqkvLxcN1FfX+9gdbcbGxsyPz+vwvHxcUcZSmTd0MDAwEMqMHvCLHWzRgnmOeh7mDdw/ktYT+NFxpGXtwaDg5Q1Nzcv0lD3aqeeRhzv8RqUgJGufMDZ3v7+/k2Q3crDKAVgHpmGhgYpLCyU4eFhqaurEzpgqsrKymR3d1fGxsbUAfEakUYcBQUFkpOTEUWjUU0lDbe2tsSfNX3P/irJuPE+uVVInrS1tXX7k/MDijNeeCi36lSWGwKPH8F3uGUuOumiMyS3wBlQ2ALolrWeyCNDOQTjDI/DDIzcySSSsg+DDzAeM0p3ROiOkIyo0enKsB5BCEOcBc9RRIkDczEEZ8SjiHQZ2O+BD8gguLa0tMjQ0JDU1tbKwsKCbG5uUpx0zlRdXa0ltb29rV86Claro6amRoqLi2V6epoGOpzs5ebmysTEhNYdHaB3qNHMzIysr68bvNeIXGNjo6ysrMjs7Ky0trbK0dGRB2wY50yVlZWSSCSM/Lg1GUFGeK7R3zBg9pwcqNc/ek9mH+63b2Rha9Yq94PRiG4jKa8gZxmlML3p9Bv4eJRgCYpozXs2FwhKCw38BYLcZfbter4CyJv29vZbLuiJpJ2yWCAY6iB/aWnpHcrzGr0gyGMk/CkMeDkwmDOwmQtgTjsCm8DmvsLmi18eCLa8vJxAgLMArtngLgTuwklZXL3ofY8oR2rPw+kdkKfIuwewCcwH8OMpyEAwgM7BMIlUMWDoQHF/g/J2KCCLwnz9vdB1ZNH/regT0nmVHQOOYvzCPoM4qc1kDcqusri4qA2DX2gsFtN+sLOz48aPgqnA1AIJBOIFVlJSIlNTU4JfF+GPyOTkpN5VbCrsPGw0vK84ePEVFRXphWfuMlVkHvSvl0LgnRkQ3p0cHBw4Ds1FSD3l/BEzgyfhtczJX4ywwWA8ZuB0dLC6uqptEZ+CY88mjCrTq9st99/CjkHGP9Mo/7dAXDtQ0u51J5a+3+443nSQX4k/Dv2PdIECwfvoRHG8tE/4j8JIkn5/ApSf8G9LZAJBAAAAAElFTkSuQmCC); }
    .documents-wrapper .icon.doc {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAcCAYAAAH96hT1AAAABGdBTUEAALGPC/xhBQAAAuhJREFUSA2lVTuLU0EUvje5ZLNCFgUfq4tYi5DCxEAexcJWam+3IGynlbDxAdtaSNBau4Vt1MY/oGiRB4YEIY1FEAziI0mhayBgzMPvu94Z5s7cu5uwAzdnzvm+883MmXNvbAujUqkMZ7PZslUul2cMcNiq40cI+1AG3FGv11eDEcJE8Oy6VO/HXaZQKNAWBQC/5NCpVqvL2NktAcCWbDq1Wi0/mUzKArBte5Pn2IPMNRGk5ZqqL+cE3DX0hclw11BTY7HY2dFo9N3NwGKfhc54PN4Xc8OqCjzLfQbw7BpML0CclSjB38ZzHUtdgV2JRCLvcd4XHs/ifjKZzA8mcE/bLCFBBC7BDEG+AHuXMQ6S/88syz2EcFhrMQ+zkTAgLO5bodFoHAsiptPpoYjz0D/hHBeBQ+wv97CChOS3mK8LX7N96bNcfGRAmwDrRdBwDkmo/Y6GG66DnvsL4hskfRCougKwaT6fj7oY5QXpIEueLCFa/YZ3m76caDTayWazLxlc6IKkssj2yWqOJOOgExzSWEk9oCTLE2tqqivJUH4HgO2qjw6E1hmU5Fwut9FsNpd0ZiqV+iNikoy9TRCUXSYIqnU/QGrgoLmv6wQRL/bWdDp9Bp/XzG38Ftg8Frs8icruy23DsXHrjyF6B48FAvtrA2LP8brenEdUcLyWO+W02+2lbrf7CtW+ShCiO6juQxD4waS4HFj8MpwVGfAm2MwX5HzS406v1+tA8DQA8TUsYqEidj1F7HU8Hn/AJJRqDSK3MU3QVwe4HTz3oMMcORwEz8Dr4+hcIHSgNb4C3AolBACy5gJDOc5hTqFFxxo2+E1NMsQ9QmAXqYnzzA1xlCmCmj9C/YJepUBN1hwXatZcZ/NSIP4U/0PndSzMZ7fol0musXMGvbYyWovYIsMQb7VaJwaDwUevi+bSwq67iUTiYjKZ5J+SHIa4R1iVjCNMjC/rEbSMVAdH2kQJnqC/Q//9jKzDA33q/gOJ5VdARJpx3gAAAABJRU5ErkJggg==); }
    .documents-wrapper .icon.excel {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAcCAYAAAHn1vR7AAAABGdBTUEAALGPC/xhBQAAA4xJREFUSA29VU1IVFEUPvOjmZIg1uAwIi4U0RZiMSKOC6FVtW8nBC1bRZkF7qJFhO3bCW2qTat2RS1GJUgDc6epw6jlDP6FjDQ1U993fe92353xMTNEB967557znZ97z7n3BsShZDL52+W9IzWkbDb7uywqQPzMzEwOoNNiQsLU0BZaUZMAiEJNhOOb1gIwAQg+YrxIYSKRoHOypPkA3IUg+HU8//sHMKx9z83NJQqFQtJVI+yY4mH5DO6vuAqOzMGce3gqmdCJiCDhDQ0N2mpwcFDzvpYaZTNlw83Ozt6jAt+0beDOqdd7gMljKO7gu4r1xzE2B4PBD9iXF65BfX19FPl+o2G4zM6+hkxhYeTaqDGfz3+FLszSeBY5MDAguVxO0C7S29srCwsL0tbWJk1NTbK0tORxwq2bdyWNjY2yvLws3d3dShQKhSQajcrR0ZEL4ajxSsi8Ta0fr9ZoAParMN7Xu2o4YOO8w3zUlBl81uCPWUb0iwpdRvUT4dxeglG7yRJPlkAdLspQj58weAvjTy7GjAhdEScl5OrUyLAewQkT4lhsv0Xbpu8hOM81jeLzEHpPOjs7PTJnMspRbwSaVnioeBW4RL69vd2d6lEbFYtFwWIlnU5r5eLiosRiMaFDk/SMvbeysiKtra3C9EhdXV1qpEOTatoI7aCaLdfpaesKGN2wiFTxEdFGfgFwEd3AZjwFhm30A993P7ytQ1XOoj0PdM/aACgDuMCnEOSWU0b28yXgno+MjFy38X5zZ5/PlQTDVXNqe3v7FQ7MZTpAVpNoqocw4OPAYJqQzAVMmrXAYZBcGjZfbHlJsEwmk0KACIAHDngcgcexKjbVGzwf9ynH1sbg9CbYM5ybBGwK3wT8eBpR1QyKEIxvY7wGI/VymsbV8AiQh5/PGF8ODw9PYSy42xhA1nehfFSJQ56geDwum5ubsr6+XokJyzAB/3y0VM0e2FY89JFIRFKplOzt7UlfX586z3w6TCKO7w7P+u7urqyurgqbySL6VyXh63d84A3ExsaG7OzsSH9/v/A92trakrW1NX03EFpXVycdHR1yeHioHj3UulwgXufaf5B7bMRRLDPt6ekhUK2Mj19LS4sHxiR4o/GS4mqGhoaED6dNpv+qamY74hzOVFLldI5e1+z/dqOdkXPXZXFL8Kz9E3Jbv6ZbuNYMSm4Q1GAMjfHEWWGtfm27LP3+AS/d3vTR4/PQAAAAAElFTkSuQmCC); }
    .documents-wrapper .icon.img {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAVCAYAAAEiBb7KAAAABGdBTUEAALGPC/xhBQAAAzVJREFUSA2tlU1oU0EQx/NeYhIEbQ5qUitUq6DQg6UmDUmDih5UBLEg3sQPqFArghSvFRG86MniwQq14knQVlBBBaHFNqEQQz0UBLFBD5qDELQKSfPlb57Z15e2T0Ltg83szsx//rOzsxttcnIyXqlUIo7qp8tC1/X+WCymeb1ety6GaDR6XWQ+nz+mTUxMVGQhnyY/iUTitMvleivzhc/qprSiY+SMoEySVoOmac9h9WrpdNqbyWQCymiVNexWg8yNbBYroekn9AGDU4yShEi2vh3DHpmrhEZk2xi+d3Z2fkK/WYyywZPGZNGPRNIwbl2kX1gqrgWN/Swej3eJFfoxM1l7978WIXC73e8UkcsKQPmI9U421Sab4lz7yuWylGmI8TAYDH5ByobHVGEzKO4B6EfafgCkH7oZHwwgdRy29V7ecEQxLm+20UKUMCpkY69Rk2aIqjaJUgpUU5waT8sCUB+X4DhjF+A2CuaoC0iMFtJ7BtABqFFiLjlHSQOGkiLEWaPXelk3A35K5Y1LYTLOzMy4s9lsnkNuxNkvYABOZJlgDo/H4+YcCyqgwYhxk4D8fr+3o6MjEw6H33ND24WdJuhirM3lcvOpVGqjAspxSMu3yBVQSjtZDdTOPlM6eW+pByTBxA9QQubC+A3wS1nU+1GDMy6n02m+WPUCA4FAj+rVH4Cm6wWu0K+NTBvUMU5zZPtXGMiEJZPJNbzAowQ+WlXmKV8Pse9z4mPo9i1pOBO9gglkj4WM/4Hm6gHfYD2kXioJWRchgPMc9leyPPWvPGjyy+xojt78LC2G7zXWL/j/GVU4VVK1NiWZ6RDcRtFLi6VpkovIQXTDNPy5SCTyQDmjG8L/bKFQuEnwBkjM/zTlo+QSQjJbB2CUIAeR41xBH1dQmkq+kampqd3FYvGNkLCWl/IKZPPcwGCpVBpAX2a88vl8J1pbW38JyPqZhATaRqDXgHfgMMhBH4LQfDwUSK438w1V4if43ML3atUeBS/vxV2ehDnkR8p8OBQKzSq8cS1kAbDMuERJ7ijj/0oILxBjgPGb8ZNkmtQ9HF+Na2GXIMR7scl7vF4IjcfNznmV9bN/AJpKrFZwwHjlAAAAAElFTkSuQmCC); }

.explorer-content {
  max-width: 1092px;
  width: 100%; }

.panel {
  position: relative; }

.md-switch-container {
  max-width: 150px; }

.loaderWrapper {
  margin-top: 20px; }

@media (max-width: 1092px) {
  .explorer-content {
    padding: 0 20px; } }

@media (max-width: 500px) {
  .explorer-content {
    margin-top: 0;
    padding: 0 10px; } }

.filepond--drip {
  background: #ffffff !important;
  border: 1px solid #bbbbbb;
  border-radius: 0 !important; }

.filepond--drop-label {
  color: #000000 !important; }
  .filepond--drop-label label {
    font-size: 16px !important; }

.filepond--panel-root {
  background: #f6f6f6 !important;
  border-radius: 0 !important; }

[data-filepond-item-state='processing-complete'] .filepond--item-panel {
  background-color: #28993F !important; }

.filepond--panel-top {
  border-radius: 0 !important; }

.filepond--panel-bottom {
  border-radius: 0 !important; }

.status-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: stretch;
      justify-content: stretch; }
  @media (max-width: 1092px) {
    .status-wrapper {
      margin: 0 20px; } }
  @media (max-width: 767.98px) {
    .status-wrapper {
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center; } }
  .status-wrapper .status {
    min-width: 198px;
    max-width: 212px;
    width: auto;
    height: 180px;
    background-color: #E2EEE4;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
    text-align: center;
    position: relative;
    margin: 12px 6px;
    padding: 0 20px; }
    @media (max-width: 767.98px) {
      .status-wrapper .status {
        width: 300px;
        max-width: unset; } }
    .status-wrapper .status:not(:first-child):before {
      content: "";
      position: absolute;
      bottom: 55px;
      left: -20px;
      height: 28px;
      width: 28px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAZ1gAAGdYBGNHK7QAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMS4xYyqcSwAAAUtJREFUSEu9ljEKwjAYhXsVJ+fODt2dHLo66g0cHJ0cnUWcdPYCgmtBL6CI4AEEQTeX+F5MUWu0/ZuaDx606Z/3aJqkCYqglAqhAbSCdtDViNds47PQlJcHJjG0hYrC2th0Lw461aCEDiVh35qx+w0KI+jEXo7QIzK2dlgA3VhdEfSyh+IBh7GKN8tCz8/hRaPLN8sjMTEP0MDZ+G+esxc3kqlflm0axkUt4nA+qnjWUf3lUF8LCBnIXULEaD1R9XGk1Zi2JMEDBnJrEvEaKAxeMZD74RvJfqO6i95XNeftj8BUOcE7BnITfoOmNjOJ6GHhag384xterEOah8M31EPqNGmEs1RPGvGyoHnJdaiXhXjhO/A4FeDC39ZGcON38yZo8Pd7Imj0+wMmeODviJHCAqiKN6XH77AUFPo7Jr6CTn4OwllgUtFRPwjuce5ncUGsYrEAAAAASUVORK5CYII=);
      background-repeat: no-repeat; }
    .status-wrapper .status:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 55px;
      right: -20px;
      height: 28px;
      width: 28px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAZ1gAAGdYBGNHK7QAAABh0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMS4xYyqcSwAAAUtJREFUSEu9ljEKwjAYhXsVJ+fODt2dHLo66g0cHJ0cnUWcdPYCgmtBL6CI4AEEQTeX+F5MUWu0/ZuaDx606Z/3aJqkCYqglAqhAbSCdtDViNds47PQlJcHJjG0hYrC2th0Lw461aCEDiVh35qx+w0KI+jEXo7QIzK2dlgA3VhdEfSyh+IBh7GKN8tCz8/hRaPLN8sjMTEP0MDZ+G+esxc3kqlflm0axkUt4nA+qnjWUf3lUF8LCBnIXULEaD1R9XGk1Zi2JMEDBnJrEvEaKAxeMZD74RvJfqO6i95XNeftj8BUOcE7BnITfoOmNjOJ6GHhag384xterEOah8M31EPqNGmEs1RPGvGyoHnJdaiXhXjhO/A4FeDC39ZGcON38yZo8Pd7Imj0+wMmeODviJHCAqiKN6XH77AUFPo7Jr6CTn4OwllgUtFRPwjuce5ncUGsYrEAAAAASUVORK5CYII=);
      background-repeat: no-repeat; }
    .status-wrapper .status .value {
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      padding: 0 10px; }
    .status-wrapper .status div {
      color: #28993F;
      font-size: 18px;
      font-weight: 700;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly; }
      .status-wrapper .status div .day {
        font-size: 36px;
        line-height: 36px; }
      .status-wrapper .status div .time {
        font-weight: 400; }

.md-tabs {
  margin-bottom: 30px;
  padding: 0 !important; }
  @media (max-width: 767.98px) {
    .md-tabs {
      padding: 16px !important;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      margin-bottom: 0; } }

.md-tab-label {
  font-size: 18px;
  font-weight: 700;
  color: #000000; }

.md-tab-panel#item-details-panel-1 {
  overflow-y: auto; }

.md-tab {
  margin: 0 5px;
  border-radius: 30px;
  background-color: #f6f6f6;
  height: 55px;
  text-transform: unset;
  color: #000000;
  width: 200px; }
  @media (max-width: 767.98px) {
    .md-tab {
      margin: 5px 0; } }

.md-tab--active .md-ink-container {
  background: #28993F; }

.md-tab--active .md-tab-label {
  color: #ffffff;
  z-index: 2; }

.md-tab-indicator {
  background: none; }

.tabs-wrapper {
  padding-bottom: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }

.intro-page {
  min-height: 1000px;
  background-image: url(/static/media/farm.795693f9.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: 1050px; }
  @media (max-width: 1600px) {
    .intro-page {
      background-size: 60vw; } }
  @media (max-width: 1280px) {
    .intro-page {
      background-size: 60vw; } }
  @media (max-width: 800px) {
    .intro-page {
      background-image: url(/static/media/farm-mobile.96290b57.png);
      background-size: contain; } }
  .intro-page .header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    margin: 60px 100px 0px 100px; }
    @media (max-width: 1280px) {
      .intro-page .header {
        margin: 30px 50px 0px 30px; } }
    .intro-page .header .logo {
      width: 232px;
      height: 70px;
      margin-bottom: 65px; }
      @media (max-width: 1280px) {
        .intro-page .header .logo {
          margin-bottom: 30px; } }
      @media (max-width: 800px) {
        .intro-page .header .logo {
          margin-bottom: 85vw; } }
    .intro-page .header .beta-badge {
      position: absolute;
      width: 144px;
      height: 144px;
      right: 40px;
      top: 40px; }
      @media (max-width: 1600px) {
        .intro-page .header .beta-badge {
          width: 8vw;
          height: 8vw;
          right: 2vw;
          top: 2vw; } }
      @media (max-width: 800px) {
        .intro-page .header .beta-badge {
          width: 25vw;
          height: 25vw;
          right: 13vw;
          top: 70vw; } }
    .intro-page .header .hero-container {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: row;
          flex-direction: row;
      margin-bottom: 50px; }
      .intro-page .header .hero-container .left {
        -ms-flex-align: center !important;
            align-items: center !important;
        width: 40vw; }
        @media (max-width: 800px) {
          .intro-page .header .hero-container .left {
            width: 100vw; } }
        .intro-page .header .hero-container .left .head-1 {
          color: #000000;
          font-size: 45px;
          font-size: 2.8125rem;
          font-weight: bold; }
          @media (max-width: 1600px) {
            .intro-page .header .hero-container .left .head-1 {
              font-size: 32px;
              font-size: 2rem; } }
          @media (max-width: 1280px) {
            .intro-page .header .hero-container .left .head-1 {
              font-size: 24px;
              font-size: 1.5rem; } }
        .intro-page .header .hero-container .left .sep {
          display: inline-block;
          width: 6vw;
          margin: 2vw 0vw 0vw 0vw;
          border-top: 2px solid #28993F; }
          @media (max-width: 575.98px) {
            .intro-page .header .hero-container .left .sep {
              min-width: 50px;
              margin-top: 15px; } }
        .intro-page .header .hero-container .left .sub-1 {
          color: #000000;
          font-size: 15px;
          font-weight: 300; }
          @media (max-width: 1600px) {
            .intro-page .header .hero-container .left .sub-1 {
              font-size: 14px; } }
          @media (max-width: 1280px) {
            .intro-page .header .hero-container .left .sub-1 {
              font-size: 13px; } }
        .intro-page .header .hero-container .left .intro-button {
          width: 200px;
          height: 55px;
          background: #28993F;
          border-radius: 42px;
          border: none;
          font-weight: 600;
          font-size: 19px;
          text-align: center;
          color: #ffffff;
          text-decoration: none;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          outline: none;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
              align-items: center;
          -ms-flex-pack: space-evenly;
              justify-content: space-evenly;
          margin-top: 50px;
          margin-bottom: 20px; }
          @supports (-ms-ime-align: auto) {
            .intro-page .header .hero-container .left .intro-button {
              -ms-flex-pack: center;
                  justify-content: center;
              /* Edge only */ } }
          .intro-page .header .hero-container .left .intro-button:hover {
            text-decoration: none; }
          @media (max-width: 575.98px) {
            .intro-page .header .hero-container .left .intro-button span {
              padding-left: 10px;
              padding-right: 10px; } }
          @media (max-width: 575.98px) {
            .intro-page .header .hero-container .left .intro-button {
              width: auto;
              padding-left: 20px;
              padding-right: 20px; } }
          @media (max-width: 991.98px) {
            .intro-page .header .hero-container .left .intro-button {
              margin-top: 30px; } }
      .intro-page .header .hero-container .right {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
            justify-content: flex-end;
        -ms-flex-align: start;
            align-items: flex-start;
        width: 60vw; }
        .intro-page .header .hero-container .right img {
          margin-top: 110px;
          margin-right: 225px;
          height: 420px; }
          @media (max-width: 1600px) {
            .intro-page .header .hero-container .right img {
              margin-top: 5vw;
              margin-right: 12vw;
              height: 25vw; } }
          @media (max-width: 1280px) {
            .intro-page .header .hero-container .right img {
              margin-top: 7vw;
              margin-right: 14vw;
              height: 25vw; } }
        @media (max-width: 800px) {
          .intro-page .header .hero-container .right {
            display: none; } }

.content {
  -ms-flex: 1 1;
      flex: 1 1;
  background-color: #ffffff;
  padding-top: 30px; }
  .content a:not(.button) {
    color: #28993F;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem; }
    @media (max-width: 575.98px) {
      .content a:not(.button) {
        text-decoration: underline; } }
    .content a:not(.button):hover {
      text-decoration: underline; }
  .content .demo-button {
    width: 200px;
    height: 55px;
    background: #28993F;
    border-radius: 42px;
    border: none;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: space-evenly;
        justify-content: space-evenly; }
    @supports (-ms-ime-align: auto) {
      .content .demo-button {
        -ms-flex-pack: center;
            justify-content: center;
        /* Edge only */ } }
    .content .demo-button:hover {
      text-decoration: none; }
    @media (max-width: 575.98px) {
      .content .demo-button span {
        padding-left: 10px;
        padding-right: 10px; } }
    @media (max-width: 575.98px) {
      .content .demo-button {
        width: auto;
        padding-left: 20px;
        padding-right: 20px; } }
  .content .row {
    max-width: 1280px;
    margin: auto;
    padding: 20px 20px 60px 20px; }
    @media (max-width: 575.98px) {
      .content .row {
        padding: 15px; } }
    .content .row h2 {
      color: #323232; }
    .content .row .col,
    .content .row .col-6,
    .content .row .col-12 {
      padding: 30px 30px 0px 30px; }
      @media (max-width: 575.98px) {
        .content .row .col,
        .content .row .col-6,
        .content .row .col-12 {
          padding: 15px 15px 0px 15px; } }
  .content .alternate {
    background-color: #f6f6f6; }
  @media (max-width: 575.98px) {
    .content .img-fluid {
      margin-bottom: 20px; } }
  .content .inter {
    padding-bottom: 30px; }
    @media (max-width: 575.98px) {
      .content .inter {
        padding-bottom: 15px; } }
    .content .inter .row-header {
      max-width: 1280px;
      margin: auto; }
      .content .inter .row-header .col,
      .content .inter .row-header .col-6,
      .content .inter .row-header .col-12 {
        padding: 20px 20px 0px 20px; }
        @media (max-width: 575.98px) {
          .content .inter .row-header .col,
          .content .inter .row-header .col-6,
          .content .inter .row-header .col-12 {
            padding: 10px 10px 0px 10px; } }
  .content .demo {
    background-image: url(/static/media/demo.3c28dee8.svg);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: top; }

.tour-page {
  background-image: url(/static/media/farm-end.45624d5c.svg);
  background-repeat: no-repeat;
  background-position-x: unset;
  background-size: 100vw; }
  @media (max-width: 1600px) {
    .tour-page {
      background-size: 115vw; } }
  @media (max-width: 1280px) {
    .tour-page {
      background-size: 119vw; } }
  @media (max-width: 800px) {
    .tour-page {
      background-image: url(/static/media/farm-end-mobile.86f0a0e5.png);
      background-size: contain; } }
  .tour-page .header {
    min-height: 25vw; }
    @media (max-width: 1600px) {
      .tour-page .header {
        min-height: 27vw; } }
    @media (max-width: 1280px) {
      .tour-page .header {
        min-height: 29vw; } }
    @media (max-width: 800px) {
      .tour-page .header {
        min-height: 110vw; } }
    .tour-page .header .logo {
      -webkit-filter: drop-shadow(5px 5px 5px #000);
              filter: drop-shadow(5px 5px 5px #000); }
      @media (max-width: 800px) {
        .tour-page .header .logo {
          margin-bottom: 60px; } }
    .tour-page .header .hero-container .left {
      width: 100vw; }
      .tour-page .header .hero-container .left .head-1 {
        color: #fff;
        color: var(--white); }
      .tour-page .header .hero-container .left .head-2 {
        color: #fff;
        color: var(--white); }
      .tour-page .header .hero-container .left .sub-1 {
        color: #fff;
        color: var(--white);
        margin-bottom: 50px; }
  .tour-page .tour--list {
    margin: 40px;
    padding-left: 0;
    list-style: none; }
    @media (max-width: 767.98px) {
      .tour-page .tour--list {
        margin: 20px; } }
    .tour-page .tour--list li {
      position: relative;
      padding-left: 30px;
      padding-bottom: 10px;
      font-weight: 300; }
    .tour-page .tour--list li::before {
      content: '';
      width: 20px;
      height: 18px;
      position: absolute;
      background-image: url(/static/media/hex.c9217cbd.svg);
      background-size: cover;
      background-position: center;
      left: 0;
      top: 4px; }
  .tour-page .content {
    padding-top: 0px; }

nav {
  line-height: 1; }
  nav ul {
    margin: 0;
    padding: 13px 30px 13px 40px;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    text-align: right; }
    @media (max-width: 575.98px) {
      nav ul {
        display: none; } }
    @media (max-width: 767.98px) {
      nav ul {
        padding: 13px 15px 13px 0; } }
    nav ul li {
      display: inline-block;
      margin: 0px 0px 0px 30px; }
      @media (max-width: 767.98px) {
        nav ul li {
          margin: 0px 0px 0px 18px; } }
      nav ul li a, nav ul li a:active, nav ul li a:hover, nav ul li a:visited {
        font-size: 12px;
        font-size: 0.75rem;
        height: 17px;
        color: #fff;
        color: var(--white);
        font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600;
        text-decoration: none; }

h1 {
  font-size: 32px;
  font-size: 2rem;
  color: #000000;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  line-height: 2; }
  @media (max-width: 575.98px) {
    h1 {
      font-size: 24px;
      font-size: 1.5rem; } }

h2 {
  font-size: 32px;
  font-size: 2rem;
  color: #28993F;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  line-height: 2.5; }
  @media (max-width: 575.98px) {
    h2 {
      font-size: 28px;
      font-size: 1.75rem;
      margin: 10px 0 0;
      line-height: 1.5; } }

h4 {
  font-size: 22px;
  font-size: 1.375rem;
  color: #ffffff;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: bold;
  line-height: 1.75; }
  @media (max-width: 575.98px) {
    h4 {
      font-size: 14px;
      font-size: 0.875rem; } }

h5 {
  font-size: 20px;
  font-size: 1.25rem;
  color: #28993F;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1.5; }
  @media (max-width: 575.98px) {
    h5 {
      font-size: 16px;
      font-size: 1rem; } }

p {
  font-size: 16px;
  font-size: 1rem;
  color: #696f76;
  color: var(--body-copy);
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 2; }
  @media (max-width: 575.98px) {
    p {
      line-height: 1.5; } }

.text-responsive-align {
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: right !important; }
  @media (max-width: 575.98px) {
    .text-responsive-align {
      text-align: left !important; } }

.text-responsive-align-alt {
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: left !important; }
  @media (max-width: 575.98px) {
    .text-responsive-align-alt {
      text-align: left !important; } }

.justify-content-responsive {
  -ms-flex-pack: center !important;
      justify-content: center !important;
  text-align: center; }
  @media (max-width: 575.98px) {
    .justify-content-responsive {
      -ms-flex-pack: left !important;
          justify-content: left !important;
      text-align: left; } }

.bold {
  font-weight: 700; }

html {
  background: none !important;
  font-size: unset !important;
  height: 100%;
  width: 100%; }
  @media not all and (min-resolution: 0.001dpcm) {
    html {
      /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
      Need this otherwise layout screws up, but you will lose the sticky footer.
     */
      height: auto; } }

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  height: 100%;
  width: 100%; }

#root, #root > div, #root > div > div {
  height: 100%;
  width: 100%; }

.content-stretch {
  -ms-flex: 1 1;
      flex: 1 1;
  width: 100%;
  -ms-flex-align: start;
      align-items: flex-start;
  padding-bottom: 50px; }

#root > div > div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

h1, h2, h3, h4, h5, h6, div, span, p, label, input, select, section, footer, header, nav, ul, li, button {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.hidden {
  display: none !important; }

button, a {
  outline: none !important; }

.button {
  width: 200px;
  height: 55px;
  background: #28993F;
  border-radius: 42px;
  border: none;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  outline: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly; }
  @supports (-ms-ime-align: auto) {
    .button {
      -ms-flex-pack: center;
          justify-content: center;
      /* Edge only */ } }
  .button:hover {
    text-decoration: none; }
  @media (max-width: 575.98px) {
    .button span {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 575.98px) {
    .button {
      width: auto;
      padding-left: 20px;
      padding-right: 20px; } }

@media (max-width: 575.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 767.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important; } }

