.tour-page {
  background-image: url('../images/farm-end.svg');
  background-repeat: no-repeat;
  background-position-x: unset;
  background-size: 100vw;

  @media (max-width: 1600px) {
    background-size: 115vw;
  }

  @media (max-width: 1280px) {
    background-size: 119vw;
  }

  @media (max-width: 800px) {
    background-image: url('../images/farm-end-mobile.png');
    background-size: contain;
  }

  .header {
    min-height: 25vw;

    @media (max-width: 1600px) {
      min-height: 27vw;
    }

    @media (max-width: 1280px) {
      min-height: 29vw;
    }

    @media (max-width: 800px) {
        min-height: 110vw;
    }
  
    .logo {
      filter: drop-shadow(5px 5px 5px #000);
      @media (max-width: 800px) {
          margin-bottom: 60px;
      }
    }

    .hero-container {
      .left {
        width: 100vw;

        .head-1 {
          color: var(--white);
        }

        .head-2 {
          color: var(--white);
        }

        .sub-1 {
          color: var(--white);
          margin-bottom: 50px;
        }
      }
    }
  }

  .tour--list {
    margin: 40px;
    padding-left: 0;
    list-style: none;

    @include media-breakpoint-down(sm) {
      margin: 20px;
    }


    li {
      position: relative;
      padding-left: 30px;
      padding-bottom: 10px;
      font-weight: 300;
    }

    li::before {
      content: '';
      width: 20px;
      height: 18px;
      position: absolute;
      background-image: url('../images/hex.svg');
      background-size: cover;
      background-position: center;
      left: 0;
      top: 4px;
    }
  }

  .content {
    padding-top: 0px;
  }
}
