@import 'globals';

@import './header';
@import './footer';
@import './loader';
@import './notification';
@import './tooltip';
@import './disclaimer';

@import './loginPage';

@import './listPage';
@import './autosuggest';

@import './formPage';
@import './partners';

@import './detailsPage';
@import './details';
@import './documents';
@import './explorer';
@import './fileUpload';
@import './status';
@import './tabs';

@import './intro';
@import './tour';
@import './navigation';
@import './text';

html {
  background: none !important;
  font-size: unset !important;
  height: 100%;
  width: 100%;

  @media not all and (min-resolution:.001dpcm) { 
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
      Need this otherwise layout screws up, but you will lose the sticky footer.
     */
     height: auto;
    }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  height: 100%;
  width: 100%;
}

#root, #root > div, #root > div > div {
  height: 100%;
  width: 100%;
}

.content-stretch {
  flex: 1;
  width: 100%;
  align-items: flex-start;
  padding-bottom: 50px;
}

#root > div > div {
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6, div, span, p, label, input, select, section, footer, header, nav, ul, li, button {
  font-family: $font-family-sans-serif;
}

.hidden {
  display: none !important;
}

button, a {
  outline: none !important;
}

.button {
  @include button;
}

.hidden-xs-down {
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.hidden-sm-down {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.hidden-md-down {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.hidden-sm-up {
  @include media-breakpoint-up(sm) {
    display: none !important;
  }
}
