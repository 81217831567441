@import '~react-md/src/scss/react-md';

// Any variable overrides. The following just changes the default theme to use teal and purple.
$md-primary-color: #28993F;
$md-secondary-color: #28993F;
$md-colors-warn-md: false;

@include react-md-everything;
@import '~bootstrap/scss/mixins/breakpoints';
@import '~bootstrap/scss/bootstrap';
@import './variables';
@import './mixins';
@import 'components';

@import url('https://use.fontawesome.com/releases/v5.0.10/css/all.css');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:200,300,400,700');
